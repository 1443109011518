import React from 'react';
import { useParams } from 'react-router';
import { useGetOrganizationQuery } from 'api/organizations';
import '../../organisations.styles.scss';
import OrganisationHeadComponent from '../../organisation-head.component';
import ProducerOverview from '../producer/overview.component';
import ControlAndOverview from '../shared/control-and-overview.component';

const ProcuerTab = () => {
  const { id } = useParams();

  const { isSuccess: isOrganisationFetched, data: organisation } = useGetOrganizationQuery(id);

  return (
    <>
      {isOrganisationFetched && (
        <OrganisationHeadComponent organisation={organisation} showContract={false} />
      )}
      <ControlAndOverview>
        <ProducerOverview />
      </ControlAndOverview>
    </>
  );
};

export default ProcuerTab;
