import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import styles from 'components/pdf/styles/styles';

export const ListItem = ({ children, prefix, bulletPoint }) => (
  <View style={styles.listItemContainer}>
    {prefix ? (
      <Text style={styles.listPrefix}>{prefix}</Text>
    ) : (
      <View style={styles.listPrefixContainer} wrap={false}>
        <View style={bulletPoint ? styles.bulletPoint : styles.squarePoint} />
      </View>
    )}
    <Text>{children}</Text>
  </View>
);

export const Section = ({ children, title }) => (
  <View style={styles.section}>
    <Text style={styles.heading1}>{title}</Text>
    {children}
  </View>
);

export const SubSection = ({ children, title, style }) => (
  <View style={[styles.subSection, style]}>
    <Text style={styles.heading2}>{title}</Text>
    {children}
  </View>
);

export const InputField = ({ title, style }) => (
  <View style={style}>
    <View style={styles.horizontalLine} />
    <Text style={{ fontSize: 8 }}>{title}</Text>
  </View>
);

export const Break = ({ count }) => '\n'.repeat(count);
export const HorizontalLine = () => <View style={styles.hr} />;

ListItem.defaultProps = {
  prefix: null,
  bulletPoint: false,
};

ListItem.propTypes = {
  children: PropTypes.node.isRequired,
  prefix: PropTypes.string,
  bulletPoint: PropTypes.bool,
};

Section.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
};

SubSection.defaultProps = {
  style: null,
};

SubSection.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  style: PropTypes.shape(),
};

InputField.propTypes = {
  title: PropTypes.string.isRequired,
  style: PropTypes.shape(),
};

InputField.defaultProps = {
  style: null,
};

Break.defaultProps = {
  count: 1,
};

Break.propTypes = {
  count: PropTypes.number,
};
