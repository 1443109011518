import React, { useCallback, useEffect, useState } from 'react';
import { t } from 'i18next';
import { debounce } from 'throttle-debounce';
import Pagination from 'components/base-components/Pagination/Pagination';
import { Link, useSearchParams } from 'react-router-dom';
import Button from 'components/base-components/Button';
import Input from 'components/base-components/Input';
import { useGetInvoiceGroupsQuery } from 'api/invoices';
import PropTypes from 'prop-types';
import Icon from 'components/base-components/Icon';
import InvoiceFilterModal from 'components/invoices/filter/invoice-filter-modal';
import useNavigationLinks from 'hooks/useNavigationLinks';
import InvoiceGroupsTable from 'components/invoices/shared/groups-table.component';

const InvoiceGroupListComponent = ({ currentTab }) => {
  const [searchInput, setSearchInput] = useState('');
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams({
    q: '',
    page: 1,
    statuses: [],
  });

  const navigationLinks = useNavigationLinks();

  const currentPage = parseInt(searchParams.get('page'));
  const searchQuery = searchParams.get('q');
  const statuses = currentTab ? [currentTab] : searchParams.getAll('statuses');

  useEffect(() => {
    setSearchInput(searchQuery);
  }, []);

  const invoiceParams = {
    q: searchQuery,
    'statuses[]': statuses,
    page: currentPage,
  };

  const setSearchQueryParams = useCallback(params => {
    Object.keys(params).forEach(key => {
      searchParams.set(key, params[key]);
    });
    setSearchParams(searchParams);
  }, []);

  const setSearchQueryInParams = debounce(500, value => {
    setSearchQueryParams({ q: value, tab: currentTab, page: 1 });
  });

  const onSearch = value => {
    setSearchInput(value);
    setSearchQueryInParams(value);
  };

  const { data: invoiceCollection = {}, isFetching } = useGetInvoiceGroupsQuery(invoiceParams);
  const invoices = invoiceCollection?.collection || [];

  return (
    <>
      <div className="invoice__header--wrapper">
        <div className="invoice-list__search-tab">
          <Input
            className="invoice-list__search"
            placeholder={t('invoice.search_invoice')}
            preIcon={<Icon size="small" name="search" />}
            size="tiny"
            value={searchInput}
            onChange={onSearch}
          />
          <Button
            onClick={() => setIsFilterVisible(true)}
            label={
              <span className="invoice-list__filter-button">
                <Icon fill="none" name="filter" />
                <span>{t('invoice.filter')}</span>
              </span>
            }
            size="small"
          />
        </div>
        <div className="invoice-list__create-button">
          <Link to={navigationLinks.invoiceCreatePage()}>
            <Button type="success" size="small" label={t('invoice.create_new_invoice')} />
          </Link>
        </div>
      </div>

      <InvoiceGroupsTable invoices={invoices} isFetching={isFetching} currentTab={currentTab} />
      <div className="invoice-list__pagination">
        <Pagination
          onPaginationClick={newPage => {
            setSearchQueryParams({ page: newPage, tab: currentTab });
          }}
          totalPages={invoiceCollection && parseInt(invoiceCollection.pagination?.totalPages, 10)}
          currentPage={currentPage}
          firstText={t('pagination.first')}
          lastText={t('pagination.last')}
          nextText={t('pagination.next')}
          previousText={t('pagination.prev')}
        />
      </div>
      <InvoiceFilterModal
        setFilterQueryParams={() => {}}
        isFilterVisible={isFilterVisible}
        setIsFilterVisible={setIsFilterVisible}
      />
    </>
  );
};

InvoiceGroupListComponent.defaultProps = {
  currentTab: null,
  enableBulkAction: false,
};

InvoiceGroupListComponent.propTypes = {
  currentTab: PropTypes.string,
  enableBulkAction: PropTypes.bool,
};

export default InvoiceGroupListComponent;
