import CheckBox from 'components/base-components/CheckBox';
import { t } from 'i18next';
import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import classNames from 'classnames';
import { FieldArray } from 'react-final-form-arrays';
import EmptyLegalForm from './shared/empty-legal-form.component';

const OperationsManager = ({ selectedNumberOfPartners, contractUsers, validationError }) => {

  if (!selectedNumberOfPartners) {
    return (
      <EmptyLegalForm
        title={t('legal_form.operations_manager.title')}
        description={t('legal_form.operations_manager.description')}
      />
    );
  }
  return (
    <div className="legal-form__operation-manage">
      <div className="legal-form__contract--title">{t('legal_form.operations_manager.title')}</div>
      <div className="legal-form__contract--description">
        {t('legal_form.operations_manager.description')}
      </div>
      <FieldArray name="legalFormPartners">
        {() => (
          <div className="gird col-12 col-bleed legal-form__operation-manage--checkbox">
            {[...Array(selectedNumberOfPartners).keys()].map(partnerCount => (
              <div
                className={classNames('col-4 col-bleed', {
                  'legal-form__radio-button--second-row': partnerCount > 2,
                })}
              >
                <Field
                  name={`legalFormPartners[${partnerCount}].operationManager`}
                  type="checkbox"
                  initialValue={
                    contractUsers?.length > 0
                      ? contractUsers[partnerCount]?.operationManager
                      : false
                  }
                >
                  {({ input }) => (
                    <CheckBox
                      isChecked={input.checked}
                      size="tiny"
                      label={t('legal_form.input.header', {
                        partner: partnerCount + 1,
                      })}
                      onChange={e => input.onChange(e.target.checked)}
                    />
                  )}
                </Field>
              </div>
            ))}
            {validationError?.operationManager && (
              <div className="legal-form__required-field">
                {t('legal_form.operations_manager.error_text')}
              </div>
            )}
          </div>
        )}
      </FieldArray>
    </div>
  );
};

OperationsManager.defaultProps = {
  selectedNumberOfPartners: null,
  contractUsers: [],
  validationError: {},
};

OperationsManager.propTypes = {
  selectedNumberOfPartners: PropTypes.number,
  contractUsers: PropTypes.arrayOf(PropTypes.shape()),
  validationError: PropTypes.shape(),
};

export default OperationsManager;
