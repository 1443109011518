import { useGetOrganizationQuery } from 'api/organizations';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

export const organizationAddressToString = addressData => {
  const firstAddress = addressData && addressData.line1 ? `${addressData.line1}, ` : '';
  const secondAddress = addressData && addressData.line2 ? `${addressData.line2}, ` : '';
  const city = addressData && addressData.city ? `${addressData.city}, ` : '';
  const zipCode = addressData && addressData.zipCode ? `${addressData.zipCode}, ` : '';
  const country = addressData && addressData.country ? `${addressData.country.name}` : '';
  const fullAddress = firstAddress + secondAddress + city + zipCode + country;

  if (fullAddress.endsWith(', ')) {
    const updatedFullAddress = fullAddress.slice(0, fullAddress[fullAddress.length - 2]);

    return updatedFullAddress;
  }
  return fullAddress;
};

const OrganizationAddress = ({ organizationId }) => {
  const { t } = useTranslation();
  const { data: organization = {}, error, isFetching } = useGetOrganizationQuery(organizationId);
  if (isFetching) return null;
  if (error) return null;

  const addressData = organization.mainAddress;

  return (
    <span className="organization-address">
      {organizationAddressToString(addressData) || t('common.not_applicable')}
    </span>
  );
};

OrganizationAddress.propTypes = {
  organizationId: PropTypes.node.isRequired,
  setGeneralError: PropTypes.func.isRequired,
};

export default OrganizationAddress;
