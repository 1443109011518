import PropTypes from 'prop-types';
import React from 'react';
import { t } from 'i18next';
import { Field, useForm, useFormState } from 'react-final-form';
import CheckBox from 'components/base-components/CheckBox';
import { Trans } from 'react-i18next';
import Card from 'components/base-components/Card';
import SelectBox from 'components/base-components/SelectBox';
import {
  useGetFirstMilkBuyersQuery,
  useGetMilProcessingSourcesQuery,
  useGetOrganicMilProcessingOrganizationsQuery,
} from 'api/producers/productions';
import RadioboxWithIcon from '../../shared/radiobox-with-icon.component';
import TitleComponent from '../../plant-cultivate/shared/title.component';
import SharedMilkFields from './shared-milk-fields.component';

const MilkProductionForm = ({ label, icon, isVisible, initialValue, name }) => {
  const { values, submitErrors } = useFormState();
  const form = useForm();
  const { data: milkProcessingSources = [] } = useGetMilProcessingSourcesQuery();
  const { data: milkProducerOrganizations = [] } = useGetOrganicMilProcessingOrganizationsQuery({
    skip: values.isMilkOrganisationMember === undefined,
  });
  const { data: firstMilkByuers } = useGetFirstMilkBuyersQuery({
    skip: values?.milkProcessingSource !== 'first_milk_buyer_outside_milk_organization',
  });

  if (!isVisible) return;

  const milkProcessingSourceOptions = milkProcessingSources.map(source => {
    return {
      label: t(`milk_production.source.${source}`),
      value: source,
    };
  });

  const milkProducerOrganizationOptions = milkProducerOrganizations.map(organization => {
    return {
      label: t(`milk_production.milk_producer_organizations.${organization}`),
      value: organization,
    };
  });

  const firstMilkBuyersOptions = firstMilkByuers?.map(buyer => {
    return {
      label: buyer,
      value: buyer,
    };
  });

  const numberOfAnimals = {
    label: t(`milk_production.labels.number_of_animals.${name}`),
    name: `dairy_farms.${name}.number_of_animals`,
    initialValue: initialValue.productionDetails?.numberOfAnimals,
  };

  const quantity = {
    label: t(`milk_production.labels.quantity.${name}`),
    name: `dairy_farms.${name}.milk_produced_kg`,
    initialValue: initialValue.productionDetails?.milkProducedKg,
  };

  const options = [
    {
      label: t('shared.action.yes'),
      name: true,
    },
    {
      label: t('shared.action.no'),
      name: false,
    },
  ];

  const onSelectionChange = (e, input, source) => {
    input.onChange(e.value);
    source === 'producer'
      ? form.change('milkProcessingSource', null)
      : form.change('milkProducerOrganization', null);
    if (e.value !== 'first_milk_buyer_outside_milk_organization') {
      form.change('milkFirstBuyer', null);
    }
  };

  return (
    <>
      <Card className="product-cultivation__card">
        <TitleComponent title={label} icon={icon} />
        <SharedMilkFields numberOfAnimals={numberOfAnimals} quantity={quantity} />
        {name === 'milk_cow' && (
          <>
            <div className="product-cultivation__paragraph">
              <div className="product-cultivation__title">
                {t('milk_production.titles.cow_milk_note')}
              </div>
              <div className="product-cultivation__description">
                <Trans
                  i18nKey="milk_production.descriptions.cow_milk_note"
                  components={{ br: <br /> }}
                />
                <span className="product-cultivation__milk-info product-cultivation__milk-info--cow">
                  {t('milk_production.descriptions.further_cow_milk_information')}
                </span>
              </div>
            </div>
            <Card className="product-cultivation__inside-card">
              <div className="product-cultivation__milk-member">
                <div>{t('milk_production.member_or_not')}</div>
                {options.map(({ name, label }) => (
                  <div className="product-cultivation__milk-member--options">
                    <RadioboxWithIcon
                      label={label}
                      fieldName="isMilkOrganisationMember"
                      value={name}
                      icon={null}
                      initialValue={
                        initialValue?.productionDetails?.organicMilkProducerOrganization
                          ? true
                          : initialValue?.productionDetails?.milkProcessingSource && false
                      }
                    />
                  </div>
                ))}
              </div>
              {values.isMilkOrganisationMember && (
                <Field
                  name="milkProducerOrganization"
                  initialValue={initialValue?.productionDetails?.organicMilkProducerOrganization}
                >
                  {({ input }) => (
                    <SelectBox
                      {...input}
                      size="tiny"
                      className="product-cultivation__milk-select"
                      placeholderText={t('milk_production.select_options')}
                      isClearable={false}
                      label={t('milk_production.milk_main_part')}
                      required={true}
                      value={milkProducerOrganizationOptions?.find(
                        option => option.value === input.value,
                      )}
                      options={milkProducerOrganizationOptions}
                      selectedValue={input.label}
                      menuPlacement="top"
                      onChange={e => onSelectionChange(e, input, 'producer')}
                      errorMsg={submitErrors?.productionDataErrors?.organicMilkProducerOrganization}
                    />
                  )}
                </Field>
              )}
              {values.isMilkOrganisationMember === false && (
                <Field
                  name="milkProcessingSource"
                  initialValue={initialValue?.productionDetails?.milkProcessingSource}
                >
                  {({ input }) => (
                    <SelectBox
                      {...input}
                      size="tiny"
                      width="full"
                      className="product-cultivation__milk-select"
                      placeholderText={t('milk_production.select_options')}
                      onChange={e => onSelectionChange(e, input, 'processing')}
                      label={t('milk_production.milk_main_part')}
                      isClearable={false}
                      value={milkProcessingSourceOptions?.find(
                        option => option.value === input.value,
                      )}
                      options={milkProcessingSourceOptions}
                      selectedValue={input.label}
                      required={true}
                      menuPlacement="top"
                      errorMsg={submitErrors?.productionDataErrors?.organicMilkProducerOrganization}
                    />
                  )}
                </Field>
              )}
              {values.isMilkOrganisationMember === false &&
                values.milkProcessingSource === 'first_milk_buyer_outside_milk_organization' && (
                  <Field
                    name="milkFirstBuyer"
                    initialValue={initialValue?.productionDetails?.firstMilkBuyer}
                  >
                    {({ input }) => (
                      <SelectBox
                        {...input}
                        size="tiny"
                        width="full"
                        className="product-cultivation__milk-select"
                        placeholderText={t('milk_production.select_options')}
                        onChange={e => input.onChange(e.value)}
                        isClearable={false}
                        label={t('milk_production.first_milk_buyer')}
                        value={firstMilkBuyersOptions?.find(option => option.value === input.value)}
                        options={firstMilkBuyersOptions}
                        selectedValue={input.label}
                        menuPlacement="top"
                        required={true}
                        errorMsg={submitErrors?.productionDataErrors?.firstMilkBuyer}
                      />
                    )}
                  </Field>
                )}
            </Card>
          </>
        )}
      </Card>

      {name === 'dairy_goat' && (
        <Card className="product-cultivation__card">
          <div className="product-cultivation__other-goat--wrapper">
            <div className="product-cultivation__title">
              {t('milk_production.titles.other_goats')}
            </div>
            <Field
              name="dairy_farms.other_goat_species"
              type="checkbox"
              initialValue={initialValue.productionDetails?.otherGoatSpecies?.length > 0}
            >
              {({ input }) => (
                <CheckBox
                  className="product-cultivation__dwarf-goats"
                  isChecked={input.checked}
                  size="tiny"
                  label={t('milk_production.labels.dwarf_goats')}
                  onChange={e => input.onChange(e.target.checked)}
                />
              )}
            </Field>
          </div>
        </Card>
      )}
    </>
  );
};

MilkProductionForm.defaultProps = {
  icon: null,
  isVisible: false,
  initialValue: {},
};

MilkProductionForm.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  icon: PropTypes.node,
  isVisible: PropTypes.bool,
  initialValue: PropTypes.shape(),
};

export default MilkProductionForm;
