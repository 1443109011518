const useNavigationLinks = () => {
  const rootPage = () => '/';
  const usersPage = () => '/users';
  const organisationsPage = () => '/organisations';
  const tasksListPage = () => '/tasks';
  const messagesListPage = () => '/messages/';
  const invoiceJobListPage = () => `/invoices`;
  const invoiceListPage = (id, date = '', types = [], industry = [], status = []) =>
    `/invoices/list?tab=${status}&jobIds=${id}&date=${date}&types=${types}&industries=${industry}`;
  const dashboardUserEditPage = userId => `/users/${userId}/edit?source=dashboard`;
  const forgotPasswordPage = () => '/reset-password/request';
  const organisationsCreatePage = () => '/organisations/new';
  const organisationDetailsPage = id => `/organisations/${id}`;
  const organisationUserEditPage = (organisationId, userId) =>
    `/organisations/${organisationId}/users/${userId}/edit`;
  const invoiceShowPage = ({ organizationId, invoiceId }) =>
    `/organisations/${organizationId}/invoices/${invoiceId}`;
  const organisationUserPage = id => `/organisations/${id}/view?section=users&tab=active&q=&page=1`;
  const organisationInvoicePage = id =>
    `/organisations/${id}/view?section=invoice&tab=active&q=&page=1`;
  const taskEditPage = id => `/tasks/edit/${id}`;
  const taskCreatePage = () => '/tasks/create';
  const messageCreatePage = id => '/messages/create';
  const usersInvitationPageFromOrganisation = id => `/organisations/${id}/invite/`;
  const organisationPerformControlPage = id => `/organisations/${id}/perform-control`;
  const usersInvitationPage = () => '/users/invite';
  const taskDetailsPage = id => `/tasks/${id}`;
  const organisationContractPage = id => `/organisations/${id}/view?section=contract`;
  const organisationsEditPage = id => `/organisations/${id}/edit`;
  const organisationViewPage = (id, tab) => `/organisations/${id}/view?section=${tab}`;
  const organisationReactivePage = id => `/organisations/${id}/view?section=manage`;
  const userDetailsPage = id => `/users/${id}`;
  const userEditPage = userId => `/users/${userId}/edit`;
  const invoiceRecordPayment = invoiceId => `/invoices/${invoiceId}/record-payment`;
  const invoiceCreatePage = () => `/invoices/create`;
  const invoiceEditPage = id => `/invoices/${id}/edit`;
  const OrganisationInvoiceCreatePage = id => `/organisations/${id}/invoices/create`;
  const OrganisationInvoiceEditPage = ({ organizationId, invoiceId }) =>
    `/organisations/${organizationId}/invoices/${invoiceId}/edit`;
  const recognitionListPage = () => `/recognition`;
  const recognitionDetailsPage = (date, currentTab) => `/recognition/${date}?tab=${currentTab}`;

  return {
    rootPage,
    usersPage,
    organisationsPage,
    tasksListPage,
    messagesListPage,
    invoiceJobListPage,
    invoiceListPage,
    recognitionListPage,
    recognitionDetailsPage,
    forgotPasswordPage,
    organisationsCreatePage,
    organisationUserPage,
    organisationInvoicePage,
    organisationContractPage,
    usersInvitationPageFromOrganisation,
    usersInvitationPage,
    taskCreatePage,
    taskDetailsPage,
    messageCreatePage,
    taskEditPage,
    organisationPerformControlPage,
    dashboardUserEditPage,
    organisationUserEditPage,
    organisationDetailsPage,
    organisationViewPage,
    organisationsEditPage,
    invoiceShowPage,
    organisationReactivePage,
    userDetailsPage,
    userEditPage,
    invoiceCreatePage,
    invoiceEditPage,
    OrganisationInvoiceCreatePage,
    OrganisationInvoiceEditPage,
    invoiceRecordPayment,
  };
};

export default useNavigationLinks;
