import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router';
import { t } from 'i18next';
import { useGetCurrentUserQuery } from 'api/users';
import { useGetActiveControlBodyDeclarationQuery } from 'api/control-body-declarations';
import Button from 'components/base-components/Button';
import useNavigationLinks from 'hooks/useNavigationLinks';
import { SAVED, SUBMITTED, TEMPORARY, DRAFT } from 'utils/control-body-survey';
import {
  hasPermissionToFinshSurvey,
  hasPermissionToApproveAdditonalTask,
} from 'utils/control-body-survey';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

const LastControlFlowNavigation = ({ survey, disabledByParent }) => {
  const { id } = useParams();
  const navigationLinks = useNavigationLinks();

  const {
    data: controlBodyDeclaration = {},
    isFetching: isFetchingControlBodyDeclaration,
    isSuccess: isControlBodyDeclarationFetched,
  } = useGetActiveControlBodyDeclarationQuery(id);

  const {
    data: currentUser = {},
    isFetching: isFetchingCurrentUser,
    isSuccess: isCurrentUserFetched,
  } = useGetCurrentUserQuery();

  let label;
  let disabled = true;

  if (!survey || isFetchingControlBodyDeclaration || isFetchingCurrentUser) {
    return null;
  }

  const hasPermissionToFinish = hasPermissionToFinshSurvey(
    currentUser.assignments,
    controlBodyDeclaration?.controlBodyOrganizationId,
  );

  const hasPermissionToManageSurvey =
    isCurrentUserFetched &&
    isControlBodyDeclarationFetched &&
    hasPermissionToApproveAdditonalTask(
      currentUser.assignments,
      controlBodyDeclaration.controlBodyOrganizationId,
    );

  if ([SAVED, DRAFT].includes(survey.status)) {
    if (hasPermissionToManageSurvey) {
      label =
        survey.status === DRAFT
          ? t('control_flow.button.perform_control')
          : t('control_flow.button.resume_control');
    } else {
      label = t('control_flow.button.confirm_report');
    }

    disabled = !hasPermissionToManageSurvey;
  } else if (survey.status === SUBMITTED) {
    label = hasPermissionToFinish
      ? t('control_flow.button.confirm_report')
      : t('control_flow.button.perform_control');
    disabled = !hasPermissionToFinish;
  } else if (survey.status === TEMPORARY) {
    label = hasPermissionToFinish
      ? t('control_flow.button.review_report')
      : t('control_flow.button.perform_control');
    disabled = !hasPermissionToFinish || !survey.possibleToSubmitNegativeReport;
  }

  return (
    <Link to={navigationLinks.organisationPerformControlPage(id)}>
      <Button
        label={label}
        className={classNames({
          'control-flow__wrapper-control-action':
            survey?.status === SAVED ||
            survey?.status === TEMPORARY ||
            survey?.status === SUBMITTED,
        })}
        type="success"
        size="small"
        disabled={disabledByParent || disabled}
      />
    </Link>
  );
};

LastControlFlowNavigation.defaultProps = {
  disabledByParent: false,
};

LastControlFlowNavigation.propTypes = {
  survey: PropTypes.shape().isRequired,
  disabledByParent: PropTypes.bool,
  survey: PropTypes.shape({
    status: PropTypes.string.isRequired,
    possibleToSubmitNegativeReport: PropTypes.bool.isRequired,
  }).isRequired,
};

export default LastControlFlowNavigation;
