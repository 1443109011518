import Card from 'components/base-components/Card';
import { t } from 'i18next';
import React from 'react';

const ContactOverview = () => {
  return (
    <Card>
      <div className="col-12 organisation-overview__content-header">
        {t('organisation_view.overview.attributes.contact.header')}
      </div>
      <div className="col-4">
        <div className="organisation-overview__address-header">
          {t('organisation_view.overview.attributes.contact.company_phone')}
        </div>
        <div className="organisation-overview__address-data">{t('common.not_applicable')}</div>
      </div>
      <div className="col-4">
        <div className="organisation-overview__address-header">
          {t('organisation_view.overview.attributes.contact.company_mobile')}
        </div>
        <div className="organisation-overview__address-data">{t('common.not_applicable')}</div>
      </div>
      <div className="col-4">
        <div className="organisation-overview__address-header">
          {t('organisation_view.overview.attributes.contact.visitor_phone')}
        </div>
        <div className="organisation-overview__address-data">{t('common.not_applicable')}</div>
      </div>
      <div className="col-4">
        <div className="organisation-overview__address-header">
          {t('organisation_view.overview.attributes.contact.company_website')}
        </div>
        <div className="organisation-overview__address-data">{t('common.not_applicable')}</div>
      </div>
      <div className="col-4">
        <div className="organisation-overview__address-header">
          {t('organisation_view.overview.attributes.contact.company_email')}
        </div>
        <div className="organisation-overview__address-data">{t('common.not_applicable')}</div>
      </div>
      <div className="col-4">
        <div className="organisation-overview__address-header">
          {t('organisation_view.overview.attributes.contact.visitor_email')}
        </div>
        <div className="organisation-overview__address-data">{t('common.not_applicable')}</div>
      </div>
    </Card>
  );
};

export default ContactOverview;
