import { useGetOrganizationQuery } from 'api/organizations';
import useCustomNavigate from 'hooks/useCustomNavigate';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ORGANISATION_TYPE from 'utils/organisation-type';
import { ORGANISATIONS_STATUS } from 'utils/statuses';
import Card from 'components/base-components/Card';
import { BlobProvider } from '@react-pdf/renderer';
import BioCuisineContractPDF from 'components/pdf/contract/contract.component';
import { t } from 'i18next';
import bytesToMegabytes from 'utils/bytes-to-megabytes';
import Link from 'components/base-components/Link';
import Spinner from 'components/base-components/Spinner';
import Icon from 'components/base-components/Icon';
import ContractVerification from '../shared/shared-verification.component';

const PDF_FILENAME = 'bio-cuisine-contract.pdf';

const ContractComponent = () => {
  const { id } = useParams();

  const { data: organisationData, isSuccess: isOrganisationFetched } = useGetOrganizationQuery(id);
  const navigate = useCustomNavigate();

  const isDeactivated = organisationData?.status === ORGANISATIONS_STATUS.inactive;

  useEffect(() => {
    if (organisationData?.type === ORGANISATION_TYPE.sub_organisation) {
      navigate.openRootPage();
    }
  }, [organisationData?.type]);

  return (
    <div className="grid">
      <div className="col-12 col-bleed-x">
        <Card className="organisation-contract__step-one">
          <div className="organisation-contract__first-step">{t('contract.first_step')}</div>
          <div className="organisation-contract__eligibility">{t('contract.first_rule')}</div>
          <div className="organisation-contract__eligibility">{t('contract.download_request')}</div>

          {isOrganisationFetched && (
            <BlobProvider document={<BioCuisineContractPDF organisation={organisationData} />}>
              {({ blob, url, loading, error }) => {
                const fileSize = blob ? blob.size : 0;
                return (
                  <div className="organisation-contract__download">
                    <div className="organisation-contract__download-file">
                      <div>{PDF_FILENAME}</div>
                      <div className="organisation-contract__download-file--seperator" />
                      <div>{bytesToMegabytes(fileSize)} MB</div>
                    </div>
                    <div className="choose-file__contract-footer">
                      <Link
                        download={PDF_FILENAME}
                        type="button"
                        size="small"
                        href={url}
                        modifier="default"
                        disabled={isDeactivated}
                      >
                        {loading ? (
                          <Spinner bgColor="none" color="success" size="tiny" />
                        ) : (
                          <>
                            <Icon
                              className="organisation-contract__download-file--icon"
                              name="downloadFile"
                              color="tertiary"
                              size="medium"
                            />
                            <span className="choose-file__contract-delete--text">
                              {t('contract.download_button')}
                            </span>
                          </>
                        )}
                      </Link>
                    </div>
                  </div>
                );
              }}
            </BlobProvider>
          )}
        </Card>
      </div>

      <div className="col-12 col-bleed">
        <ContractVerification title={t('contract.second_step')} />
      </div>
    </div>
  );
};

export default ContractComponent;
