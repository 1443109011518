import { t } from 'i18next';
import PropTypes from 'prop-types';
import React from 'react';
import Card from 'components/base-components/Card';
import { useFormState } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import ContractPartnerList from './shared/contract-partner-list.component';
import EmptyLegalForm from './shared/empty-legal-form.component';

const ContractDetails = ({ contractUserIds, validationError }) => {
  const { values } = useFormState();

  const selectedNumberOfPartners = values?.numberOfPartners;

  if (!selectedNumberOfPartners) {
    return (
      <Card className="legal-form__details--card legal-form__details--card-empty">
        <EmptyLegalForm
          title={t('legal_form.contract_details.title')}
          description={t('legal_form.contract_details.description')}
        />
      </Card>
    );
  }

  return (
    <Card className="legal-form__details--card">
      <div className="legal-form__details--title">{t('legal_form.contract_details.title')}</div>
      <div className="legal-form__details--description">
        {t('legal_form.contract_details.description')}
      </div>
      <FieldArray name="legalFormPartners">
        {({ fields }) => (
          <div>
            {selectedNumberOfPartners &&
              [...Array(selectedNumberOfPartners).keys()].map(partnerCount => {
                const existingDetails = contractUserIds
                  ? {
                      userId: contractUserIds[partnerCount]?.id || null,
                    }
                  : {};
                return (
                  <ContractPartnerList
                    partner={partnerCount}
                    name={`legalFormPartners[${partnerCount}]`}
                    {...existingDetails}
                    validationError={validationError}
                  />
                );
              })}
          </div>
        )}
      </FieldArray>
    </Card>
  );
};

ContractDetails.defaultProps = {
  contractUserIds: null,
  validationError: {},
};

ContractDetails.propTypes = {
  contractUserIds: PropTypes.arrayOf(PropTypes.number),
  validationError: PropTypes.shape(),
};

export default ContractDetails;
