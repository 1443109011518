import React from 'react';
import { useGetCompanyQuery, useGetCompanyUnitsQuery } from 'api/producers';
import Card from 'components/base-components/Card';
import DefaultSpinner from 'components/shared-components/default-spinner';
import EmptyContentPlaceholder from 'components/shared-components/placeholders/empty-content-placeholder-component';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { dateMonthYear } from 'utils/date-time-formatter';
import Address from './address.component';
import AttributeAlignment from './attribute-alignment.component';
import './styles.scss';

const MarketData = ({ marketData }) => {
  const { t } = useTranslation();

  return (
    <div className="super-office-details__market-data">
      <AttributeAlignment
        label={t('organisation.super_office.attributes.market_data.attributes.initial_member')}
        value={marketData.initialMembershipOrganization}
      />
      <AttributeAlignment
        label={t('organisation.super_office.attributes.market_data.attributes.first_milk_buyer')}
        value={marketData.firstMilkbuyer}
      />
      <AttributeAlignment
        label={t('organisation.super_office.attributes.market_data.attributes.plus_health_program')}
        value={marketData.plusHealthProgram}
      />
      <AttributeAlignment
        label={t(
          'organisation.super_office.attributes.market_data.attributes.compulsory_membership_milk_buyer',
        )}
        value={marketData.compulsoryMembershipMilkYear}
      />
      <AttributeAlignment
        label={t(
          'organisation.super_office.attributes.market_data.attributes.organic_pig_producer',
        )}
        value={marketData.organicPigProducerOrganization}
      />
    </div>
  );
};

MarketData.propTypes = {
  marketData: PropTypes.shape().isRequired,
};

const SuperOfficeDetails = ({ companyIdentifier }) => {
  const { t } = useTranslation();

  const { data: organisationDataLakeAttribute = {}, isFetching } = useGetCompanyQuery(
    { companyIdentifier },
    { skip: !companyIdentifier },
  );

  const burNRs = [];
  const tvdNRs = [];
  const { data: companyUnits = [] } = useGetCompanyUnitsQuery(
    { companyIdentifier },
    { skip: !companyIdentifier },
  );

  companyUnits.forEach(companyUnit => {
    burNRs.push(companyUnit.burnr);
    tvdNRs.push(companyUnit.tvdnr);
  });

  if (isFetching) {
    return <DefaultSpinner className="default-spinner--center" />;
  }

  return (
    <div className="super-office-details grid">
      <div className="col-12">
        <Card title={'General Information'}>
          <div className="super-office-details__basic">
            <AttributeAlignment
              label={t('organisation_type.uid')}
              value={organisationDataLakeAttribute.uidnr}
            />
            <AttributeAlignment
              label={t('organisation.super_office.attributes.contact_id')}
              value={organisationDataLakeAttribute.contactIdentifier}
            />
            <AttributeAlignment
              label={t('organisation.super_office.attributes.number_at_certification_body')}
              value={organisationDataLakeAttribute.numberAtCertificationBody}
            />
            <AttributeAlignment
              label={t('organisation.super_office.attributes.bur_no', { count: 1 })}
              value={burNRs[0]}
            />
            <AttributeAlignment
              label={t('organisation.super_office.attributes.bur_no', { count: 2 })}
              value={burNRs[1]}
            />
            <AttributeAlignment
              label={t('organisation.super_office.attributes.customer_since')}
              value={dateMonthYear(organisationDataLakeAttribute.customerSince)}
            />
          </div>
        </Card>
      </div>
      <div className="col-12">
        <div className="super-office-details__addresses">
          <Address
            title={t('organisation.form.attributes.postal_address')}
            companyIdentifier={companyIdentifier}
            addressId={organisationDataLakeAttribute.mailAddressIdentifier}
          />
          <Address
            title={t('organisation.form.attributes.visiting_address')}
            companyIdentifier={companyIdentifier}
            addressId={organisationDataLakeAttribute.streetAddressIdentifier}
          />
        </div>
      </div>
      <div className="col-12">
        <div className="super-office-details__container">
          <Card
            className="super-office-details__contacts super-office-details__container-element"
            title="Contact Information"
          >
            <div className="super-office-details__contacts-phone">
              <AttributeAlignment
                label={t('organisation.super_office.attributes.phone_1')}
                value={organisationDataLakeAttribute.phone1}
              />
              <AttributeAlignment
                label={t('organisation.super_office.attributes.phone_2')}
                value={organisationDataLakeAttribute.phone2}
              />
            </div>
            <div className="super-office-details__contacts-website">
              <AttributeAlignment
                label={t('organisation.super_office.attributes.website_1')}
                value={organisationDataLakeAttribute.website1}
              />
              <AttributeAlignment
                label={t('organisation.super_office.attributes.website_2')}
                value={organisationDataLakeAttribute.website2}
              />
            </div>
          </Card>
          {
            //TODO: needs to implement when the value will be available in snowflake
          }
          <Card
            className="super-office-details__container-element super-office-details__communication-mode"
            title={t('organisation.super_office.attributes.communication_mode.title')}
          >
            <div className="super-office-details__communication-mode-language">
              <AttributeAlignment
                label={t('organisation.super_office.attributes.communication_mode.language')}
                value={organisationDataLakeAttribute.language}
              />
            </div>

            <AttributeAlignment
              label={t(
                'organisation.super_office.attributes.communication_mode.second_spoken_language',
              )}
              value={null}
            />
          </Card>
        </div>
      </div>
      <div className="col-12">
        <div className="super-office-details__container">
          <Card
            className="super-office-details__container-element"
            title={t('organisation.super_office.attributes.market_data.title')}
          >
            {organisationDataLakeAttribute?.marketData && (
              <div className="super-office-details__market-data">
                <MarketData marketData={organisationDataLakeAttribute?.marketData} />
              </div>
            )}
          </Card>

          <Card
            className="super-office-details__container-element"
            title={t('organisation.super_office.attributes.interest')}
          >
            <EmptyContentPlaceholder
              showCard={false}
              text={t('common.coming_soon')}
              iconName="add"
            />
          </Card>
        </div>
      </div>
    </div>
  );
};

SuperOfficeDetails.propTypes = {
  companyIdentifier: PropTypes.number.isRequired,
};

export default SuperOfficeDetails;
