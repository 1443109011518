const PLANT_CULTIVATION = {
  perennial: 'perennial',
  annual: 'annual',
  plant_cultivation: 'plant_cultivation',
  meat_production: 'meat_production',
  egg: 'egg',
  bee: 'bee',
  fish: 'fish',
  milk: 'milk',
  animal: 'animal',
  garden: 'garden',
  greenArea: 'greenArea',
  privatePark: 'privatePark',
  fruit: 'fruit',
  fruits: 'fruits',
  herb: 'herb',
  herbs: 'herbs',
  ornamental: 'ornamental',
  viticulture: 'viticulture',
  other: 'other',
  mushroom: 'mushroom',
  mushroom_farming: 'mushroom_farming',
  vegetable: 'vegetable',
  potato: 'potato',
  potatoes: 'potatoes',
  arable_crop: 'arable_crop',
  arableCrops: 'arableCrops',
  tableGrape: 'table_grape',
};

export default PLANT_CULTIVATION;

export const HIGH_TRUNK_TREE = 'high_trunk_tree';
