import { t } from 'i18next';

const addressView = address => {
  if (!address) return t('common.not_applicable');

  const addressInfo = [
    address.line1,
    address.line2,
    address.city,
    address.zipCode,
    address?.country?.name,
  ];

  const filteredAddress = addressInfo.filter(info => !!info);

  return filteredAddress.join(', ');
};

export default addressView;
