import { Text, View } from '@react-pdf/renderer';
import { Break } from 'components/pdf/shared/shared-components';
import styles from 'components/pdf/styles/styles';
import React from 'react';
import PropTypes from 'prop-types';
import addressView from 'utils/address-view';
import { t } from 'i18next';
import { addressHeader, cityWithZip, nrNumber, roadNumber } from 'utils/producer-contract-data';
import producerContractStyles from '../producer-contract.styles';

const FrontPage = ({ addresses }) => (
  <View>
    <Text style={producerContractStyles.pdfTitle}>{t('producer_contract.title')}</Text>
    <Text style={producerContractStyles.between}>{t('producer_contract.subtitle')}</Text>
    <View style={styles.grid}>
      <View style={{ width: '100%' }}>
        <Text>
          {addressHeader} <Break />
          {roadNumber} <Break />
          {cityWithZip}
        </Text>
      </View>
      <View style={{ width: '100%', textAlign: 'center' }}>
        <Text> {t('producer_contract.header.middle_address')}</Text>
      </View>
      {addresses.map(address => (
        <View style={{ width: '100%' }}>
          <Text>
            {t('producer_contract.header.receiver_address_nr', {
              nrNumber: nrNumber,
            })}
            <Break count={2} />
          </Text>
          <Text>{addressView(address)}</Text>
        </View>
      ))}
      <Break count={3} />
    </View>
    <View style={styles.addressFooter}>
      <Text style={{ maxWidth: 200 }}>{t('producer_contract.header.sender_address_footer')}</Text>
      <Text>{t('producer_contract.header.receiver_address_footer')}</Text>
    </View>

    <Text style={producerContractStyles.pdfSubtitle}>
      {t('producer_contract.header.main_title.first_line')}
      <Break />
      {t('producer_contract.header.main_title.second_line')}
    </Text>
  </View>
);

FrontPage.defaultProps = {
  addresses: [],
};

FrontPage.propTypes = {
  addresses: PropTypes.arrayOf(PropTypes.shape()),
};

export default FrontPage;
