import classNames from 'classnames';
import Card from 'components/base-components/Card';
import {
  useGetBerryTypeOptionsQuery,
  useGetOrchardTypeOptionsQuery,
} from 'api/producers/productions';
import CheckBox from 'components/base-components/CheckBox';
import Icon from 'components/base-components/Icon';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'react-final-form';
import PLANT_CULTIVATION from 'utils/plant-cultivation';
import { Trans } from 'react-i18next';
import CultivationMethodInputWithCheckbox from '../shared/cultivation-method.component';
import TitleComponent from '../shared/title.component';
import FruitFieldComponent from './field.component';

const CultivatedFruitWrapper = ({ isVisible, initialValue, values }) => {
  const { data: berryTypeOptionsData = [] } = useGetBerryTypeOptionsQuery({}, { skip: !isVisible });
  const { data: orchardTypeOptionsData = [] } = useGetOrchardTypeOptionsQuery(
    {},
    { skip: !isVisible },
  );

  if (!isVisible) return;

  const highTrunkTrees = orchardTypeOptionsData.filter(
    options => options !== PLANT_CULTIVATION.other,
  );

  const highTrunkTreesData = highTrunkTrees.map(tree => {
    return {
      name: tree,
      label: t(`plant_cultivation.fruits.high_trunk_trees.${tree}`),
    };
  });
  const orchardsData = orchardTypeOptionsData.map(tree => {
    return {
      name: tree,
      label: t(`plant_cultivation.fruits.high_trunk_trees.${tree}`),
    };
  });

  const berriesData = berryTypeOptionsData.map(berry => {
    return {
      name: berry,
      label: t(`plant_cultivation.fruits.berry.${berry}`),
    };
  });

  const tableGrapeValue = initialValue?.find(
    value =>
      value.fruitType === PLANT_CULTIVATION.tableGrape && value.subType === PLANT_CULTIVATION.fruit,
  )?.productionDetails;

  const otherFruitValue = !!initialValue?.find(
    value =>
      value.fruitType === PLANT_CULTIVATION.other && value.subType === PLANT_CULTIVATION.fruit,
  );

  return (
    <Card className="plant-cultivation__card">
      <TitleComponent title={t('plant_cultivation.fruits.title')} icon="fruit" />
      <FruitFieldComponent
        mainFieldName="high_trunk_tree"
        isVisible={values?.high_trunk_tree}
        data={highTrunkTreesData}
        label={t('plant_cultivation.fruits.high_trunk_trees.title')}
        initialValue={initialValue}
      />
      <FruitFieldComponent
        mainFieldName="orchard"
        isVisible={values?.orchard}
        data={orchardsData}
        label={
          <div className="plant-cultivation__method--fruit-header">
            <div>{t('plant_cultivation.fruits.high_trunk_trees.orchard_title')}</div>
            <div className="plant-cultivation__hints">
              <Trans
                i18nKey="plant_cultivation.fruits.orchard_hints"
                components={{
                  anchor: (
                    <a
                      href="https://www.lexfind.ch/fe/de/tol/25164/versions/141122/de"
                      target="_blank"
                      rel="noreferrer"
                      className="organisation__form-sub-label--color"
                    />
                  ),
                }}
              />
            </div>
          </div>
        }
        initialValue={initialValue}
      />
      <FruitFieldComponent
        mainFieldName="eating_berry"
        isVisible={values?.eating_berry}
        data={berriesData}
        label={t('plant_cultivation.fruits.berry.eating_berries')}
        initialValue={initialValue}
      />
      <FruitFieldComponent
        mainFieldName="industry_berry"
        isVisible={values?.industry_berry}
        data={berriesData}
        label={t('plant_cultivation.fruits.berry.industry_berries')}
        initialValue={initialValue}
      />

      <Card
        className={classNames('plant-cultivation__method--card', {
          'plant-cultivation__method--card__selected':
            !!tableGrapeValue || values?.table_grapes_checkbox,
        })}
      >
        <Field
          name="table_grapes_checkbox"
          type="checkbox"
          initialValue={!!tableGrapeValue ? PLANT_CULTIVATION.tableGrape : null}
        >
          {({ input }) => (
            <CheckBox
              className={classNames('plant-cultivation__method--fruit-main-checkbox', {
                'plant-cultivation__method--fruit-main-checkbox__deselected': !input.checked,
              })}
              isChecked={input.checked}
              size="tiny"
              label={
                <div className="plant-cultivation__method--header-wrapper">
                  {t('plant_cultivation.fruits.table_grapes')}
                  <Icon size="tiny" name={input.checked ? 'upArrow' : 'downArrow'} />
                </div>
              }
              onChange={e => input.onChange(e.target.checked ? PLANT_CULTIVATION.tableGrape : null)}
            />
          )}
        </Field>
        <CultivationMethodInputWithCheckbox
          showCoveredCultivation={true}
          isVisible={!!values.table_grapes_checkbox}
          fieldName="table_grapes"
          initialValue={tableGrapeValue}
          values={values}
          isTableGrape={true}
        />
      </Card>

      <div
        className={classNames('plant-cultivation__method--open', {
          'plant-cultivation__method--open__checked': otherFruitValue || values?.other_fruits,
        })}
      >
        <Field name="other_fruits" type="checkbox" initialValue={otherFruitValue}>
          {({ input }) => (
            <CheckBox
              isChecked={input.checked}
              size="tiny"
              label={t('plant_cultivation.fruits.other_fruit')}
              onChange={e => input.onChange(e.target.checked)}
            />
          )}
        </Field>
      </div>
    </Card>
  );
};

CultivatedFruitWrapper.defaultProps = {
  title: null,
  isVisible: false,
  initialValue: [],
  values: {},
};

CultivatedFruitWrapper.propTypes = {
  title: PropTypes.string,
  isVisible: PropTypes.bool,
  values: PropTypes.shape(),
  initialValue: PropTypes.arrayOf(PropTypes.shape()),
};

export default CultivatedFruitWrapper;
