/* eslint-disable */
import { useNavigate } from 'react-router-dom';

const useCustomNavigate = () => {
  const navigate = useNavigate();

  const openRootPage = () => navigate('/');
  const openLoginPage = () => navigate('/login');
  const openRegisterPage = () => navigate('/register');
  const openCredentialPage = token => navigate(`/set-password?token=${token}`);
  const openOrganisationsPage = () => navigate('/organisations');
  const openUsersPage = () => navigate('/users');
  const openInvitedUsersPage = () => navigate('/users?tab=invited');
  const openOrganisationDetailsPage = id => navigate(`/organisations/${id}`);
  const openOrganisationSuperOfficeTab = id =>
    navigate(`/organisations/${id}/view?section=super_office`);
  const openOrganisationControlBodyPage = id => navigate(`/organisations/${id}?tab=control_body`);
  const openOrganisationUserPage = id =>
    navigate(`/organisations/${id}/view?section=users&tab=active&q=&page=1`);
  const openTasksListPage = () => navigate('/tasks');
  const openMessagesListPage = id => navigate('/messages/');
  const openMessageEditPage = id => navigate(`/messages/edit/${id}`);
  const openTvdCreatePage = id => navigate(`/organisations/${id}/local-units/create`);
  const openTvdEditPage = (id, localUnitId) =>
    navigate(`/organisations/${id}/local-units/edit/${localUnitId}`);
  const openTvdPlaceOrderPage = id => navigate(`/organisations/${id}/local-units/place-order`);
  const openInvoiceJobPage = id => navigate(`/invoices`);
  const openInvoiceListPage = status => navigate(`/invoices/list?tab=${status}`);
  const openInvoiceShowPage = ({ organizationId, invoiceId }) =>
    navigate(`/organisations/${organizationId}/invoices/${invoiceId}`);
  const openInvoiceCreatePage = () => navigate('/invoices/create');
  const openInvoiceEditPage = id => navigate(`/invoices/${id}/edit`);
  const openOrganisationInvoiceCreatePage = id => navigate(`/organisations/${id}/invoices/create`);
  const openOrganisationInvoiceEditPage = ({ organizationId, invoiceId }) =>
    navigate(`/organisations/${organizationId}/invoices/${invoiceId}/edit`);
  const openOrganisationInvoicePage = id =>
    navigate(`/organisations/${id}/view?section=invoice&tab=active&q=&page=1`);

  return {
    openRootPage,
    openLoginPage,
    openRegisterPage,
    openOrganisationsPage,
    openOrganisationDetailsPage,
    openOrganisationUserPage,
    openUsersPage,
    openInvitedUsersPage,
    openTasksListPage,
    openMessagesListPage,
    openMessageEditPage,
    openCredentialPage,
    openOrganisationControlBodyPage,
    openOrganisationSuperOfficeTab,
    openTvdCreatePage,
    openTvdEditPage,
    openTvdPlaceOrderPage,
    openInvoiceJobPage,
    openInvoiceListPage,
    openInvoiceShowPage,
    openInvoiceCreatePage,
    openInvoiceEditPage,
    openOrganisationInvoiceCreatePage,
    openOrganisationInvoiceEditPage,
    openOrganisationInvoicePage,
  };
};

export default useCustomNavigate;
