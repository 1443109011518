import React from 'react';
import PropTypes from 'prop-types';
import '../checklist-form.styles.scss';
import { Field, useFormState } from 'react-final-form';
import Icon from 'components/base-components/Icon';
import ProductName from 'components/control-body/bio-cuisine-assessment/negative-reports/helpers/product-name.component';
import YesNoInput from 'components/base-components/YesNoInput';
import { boolToYesNo } from 'utils/yes-no-helper';
import { t } from 'i18next';
import Textarea from 'components/base-components/Textarea/Textarea';
import { NO, RESERVED_INGREDIENTS, TEMPORARY } from 'utils/control-body-survey';
import { useGetIngredientReservesAdditionalPhotosQuery } from 'api/control-body-survey/ingredient-reserves';
import UploadAdditionalPhotos from './upload-additional-photos.component';

const ReservedIngredientsCheckbox = ({
  title,
  items,
  activeItems,
  error,
  comment,
  isCertifier,
  status,
  activeReservedIngredientsAdditionalTask,
  isAuditor,
  surveyId,
  uploadedReservedIngredientAttachments,
  setUploadedReservedIngredientAttachments,
}) => {
  const activeReservedIngredients = {};
  const formState = useFormState();
  const errorMsg = error && error['activeReserves'];

  let requiredPhotoUploadCount = 0;
  items?.forEach(item => {
    if (formState.values['item_' + item.productId] === NO) {
      requiredPhotoUploadCount++;
    }
  });

  if (!items || !activeItems) return null;

  activeItems.forEach(({ productId, inStock }) => (activeReservedIngredients[productId] = inStock));

  return (
    <div className="organisation-checklist__checkbox-wrapper">
      <div className="organisation-checklist__checkbox-title organisation-checklist__checkbox-title--reserved">
        <div> {title} </div>
        {items.length === 0 && (
          <div>
            <span className="organisation-checklist__deviation-legend--status-disabled">
              {t('common.no_data_available')}
            </span>
          </div>
        )}
      </div>
      {items.length > 0 &&
        items.map(item => (
          <div key={item.label}>
            <ul className="organisation-checklist__unordered-list">
              <li>
                <Field
                  name={`item_${item.productId}`}
                  initialValue={boolToYesNo(activeReservedIngredients[item.productId])}
                >
                  {({ input, meta }) => (
                    <YesNoInput
                      {...input}
                      error={meta.submitError}
                      label={<ProductName productId={item.productId} />}
                      disabled={isCertifier || status === TEMPORARY}
                    />
                  )}
                </Field>
              </li>
            </ul>
            <hr className="organisation-checklist__checkbox-horizontal-line" />
          </div>
        ))}

      {requiredPhotoUploadCount !== 0 && (
        <UploadAdditionalPhotos
          isAuditor={isAuditor}
          surveyId={surveyId}
          uploadedLogoAttachments={uploadedReservedIngredientAttachments}
          setUploadedLogoAttachments={setUploadedReservedIngredientAttachments}
          requiredLogoCount={requiredPhotoUploadCount}
          errors={error?.ingredientReserveAdditionalPhotos}
          fetchPhotosDataQuery={useGetIngredientReservesAdditionalPhotosQuery}
          itemType={RESERVED_INGREDIENTS}
        />
      )}

      <Field name="ingredientReservesComment" initialValue={comment || ''}>
        {({ input }) => (
          <Textarea
            className="organisation-checklist__quarter-comment"
            label={
              isCertifier
                ? t('control_body.survey.form.auditor_comments')
                : t('control_body.survey.form.comments')
            }
            placeholder={t('control_body.survey.form.comments_placeholder')}
            disabled={isCertifier || status === TEMPORARY}
            {...input}
          />
        )}
      </Field>

      {isCertifier &&
        activeReservedIngredientsAdditionalTask &&
        Object.keys(activeReservedIngredientsAdditionalTask).length > 0 && (
          <div className="organisation-checklist__auditor-acknowledgement">
            <Field
              name="auditorAcknowledgement"
              initialValue={boolToYesNo(activeReservedIngredientsAdditionalTask?.fulfilled)}
            >
              {({ input, meta }) => (
                <YesNoInput
                  {...input}
                  error={meta.submitError}
                  label={t('control_body.survey.form.second_step_auditor_text')}
                  disabled={status === TEMPORARY}
                />
              )}
            </Field>
          </div>
        )}

      {errorMsg && (
        <div className="organisation-checklist__errors">
          <Icon name="invalid" color="danger" size="small" />
          <span className="organisation-checklist__errors-text">{errorMsg}</span>
        </div>
      )}
    </div>
  );
};

ReservedIngredientsCheckbox.defaultProps = {
  title: null,
  status: null,
  comment: null,
  items: null,
  activeItems: null,
  error: null,
  isCertifier: false,
  activeReservedIngredientsAdditionalTask: {},
};

ReservedIngredientsCheckbox.propTypes = {
  title: PropTypes.node,
  comment: PropTypes.node,
  status: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.string),
  activeItems: PropTypes.arrayOf(PropTypes.string),
  error: PropTypes.shape(),
  activeReservedIngredientsAdditionalTask: PropTypes.shape(),
  isCertifier: PropTypes.bool,
  uploadedReservedIngredientAttachments: PropTypes.array.isRequired,
  setUploadedReservedIngredientAttachments: PropTypes.func.isRequired,
  isAuditor: PropTypes.bool.isRequired,
  surveyId: PropTypes.number.isRequired,
};

export default ReservedIngredientsCheckbox;
