import {
  useGetContractUsersQuery,
  useGetLegalFormOptionsQuery,
  useGetOrganizationQuery,
} from 'api/organizations';
import SelectBox from 'components/base-components/SelectBox';
import { t } from 'i18next';
import React from 'react';
import { Field, useForm, useFormState } from 'react-final-form';
import PropTypes from 'prop-types';
import { useParams } from 'react-router';
import CheckBox from 'components/base-components/CheckBox';
import Card from 'components/base-components/Card';
import CompanyType from './company-type.component';
import ContractDetails from './contract-details.component';
import OperationsManager from './operations-manager.component';
import ContactPersons from './contact-person.component';

const LegalForm = ({ validationError }) => {
  const { id } = useParams();

  const form = useForm();
  const { values } = useFormState();

  const { data: organisation = {} } = useGetOrganizationQuery(id);

  const { data: legalFormOptions = [] } = useGetLegalFormOptionsQuery();

  const { data: contractUsers = [] } = useGetContractUsersQuery(
    {
      id,
    },
    { skip: !id },
  );

  const contractUserIds = contractUsers.map(user => {
    return {
      id: user.userId,
      assignmentId: user.id,
    };
  });

  const numberOfPartnersOptions = [...Array(10).keys()].map(number => {
    return {
      label: number + 1,
      value: number + 1,
    };
  });

  return (
    <div>
      <div className="legal-form__title">{t('legal_form.title')}</div>
      <div className="col-6 col-bleed">
        <div>
          <Field name="legalForm" initialValue={organisation?.legalForm}>
            {({ input, meta }) => (
              <SelectBox
                size="tiny"
                width="full"
                label={t('legal_form.select_box.form_label')}
                placeholderText={t('legal_form.select_box.form_placeholder')}
                isClearable={true}
                options={legalFormOptions}
                value={legalFormOptions.find(option => option.value === input.value)}
                touched={!meta.dirtySinceLastSubmit}
                errorMsg={meta.submitError || (!input.value && validationError?.partner)}
                selectedValue={input.label}
                onChange={e => {
                  input.onChange(e.value);
                  form.change('numberOfPartners', null);
                  form.change('legalFormCheckbox', false);
                }}
              />
            )}
          </Field>
          {values.legalForm === 'commercial_registry_entry' && (
            <Field name="legalFormCheckbox" type="checkbox">
              {({ input }) => (
                <>
                  <CheckBox
                    className="legal-form__permit-checkbox"
                    isChecked={input.checked}
                    size="tiny"
                    label={t('legal_form.select_box.permit_checkbox')}
                    onChange={e => {
                      input.onChange(e.target.checked);
                      if (e.target.checked) {
                        form.change('numberOfPartners', 1);
                      }
                    }}
                  />
                </>
              )}
            </Field>
          )}
        </div>
      </div>
      <div className="col-6 col-bleed-y">
        <Field name="numberOfPartners" initialValue={organisation.numberOfPartners}>
          {({ input, meta }) => (
            <SelectBox
              size="tiny"
              width="full"
              label={t('legal_form.select_box.partner_number')}
              isClearable={true}
              options={numberOfPartnersOptions}
              value={numberOfPartnersOptions.find(option => option.value === input.value)}
              selectedValue={input.label}
              onChange={e => input.onChange(e.value)}
              isDisabled={values.legalFormCheckbox}
              className="invoice__line-input"
              touched={!meta.dirtySinceLastSubmit}
              errorMsg={meta.submitError || (!input.value && validationError?.partner)}
            />
          )}
        </Field>
      </div>
      <ContractDetails contractUserIds={contractUserIds} validationError={validationError} />
      <Card className="legal-form__card">
        <OperationsManager
          selectedNumberOfPartners={values.numberOfPartners}
          contractUsers={contractUsers}
          validationError={validationError}
        />
        <ContactPersons
          selectedNumberOfPartners={values.numberOfPartners}
          contractUsers={contractUsers}
          validationError={validationError}
        />
      </Card>
      <CompanyType initialValue={organisation.producerType} />
    </div>
  );
};

LegalForm.defaultProps = {
  validationError: {},
};

LegalForm.propTypes = {
  validationError: PropTypes.shape(),
};

export default LegalForm;
