const validateFormFields = (values, activeIndex, setNextStepDisabled, validationError = {}) => {
  const {
    registeredName,
    secondSpokenLanguage,
    legalFormPartners,
    otherPartner,
    enableMailingAddress,
    mainAddress,
    mailingAddress,
    agriculturalLandInHectare,
    greenlandInHectare,
    openCultivatedLandInHectare,
    productionStartingYear,
    tvd_sticker_numbers,
  } = values;
  const hasMainAddress = mainAddress?.canton && mainAddress?.city && mainAddress?.line1;
  const hasMailingAddress = mailingAddress?.canton && mailingAddress?.city && mailingAddress?.line1;

  const hasRequiredFields = partner => {
    const { firstName, lastName, email } = partner;
    return firstName && lastName && email;
  };

  let isValid = false;

  switch (activeIndex) {
    case 0:
      isValid = validationError?.secondSpokenLanguage
        ? registeredName && secondSpokenLanguage
        : registeredName;
      break;
    case 1:
      isValid =
        !legalFormPartners ||
        (otherPartner
          ? legalFormPartners.some(hasRequiredFields) && hasRequiredFields(otherPartner)
          : legalFormPartners.some(hasRequiredFields));
      break;
    case 2:
      isValid = enableMailingAddress ? hasMainAddress && hasMailingAddress : hasMainAddress;
      break;
    case 3:
      isValid = true;
      break;
    case 4:
      isValid =
        agriculturalLandInHectare &&
        greenlandInHectare &&
        openCultivatedLandInHectare &&
        productionStartingYear;
      break;
    case 5:
      isValid = true;
      break;
    case 6:
      isValid = tvd_sticker_numbers;
      break;
    case 7:
      isValid = true;
      break;
    default:
      isValid = false;
  }

  if (isValid) {
    setNextStepDisabled(false);
  }
};

export default validateFormFields;
