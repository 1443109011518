import UserName from 'components/users/user-name.component';
import PropTypes from 'prop-types';

const BreadcrumbUserName = ({ match }) => {
  const id = match?.params?.id;

  return <UserName userID={id} />;
};

BreadcrumbUserName.defaultProps = {
  match: null,
};

BreadcrumbUserName.propTypes = {
  match: PropTypes.shape(),
};

export default BreadcrumbUserName;
