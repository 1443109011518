import { Font, StyleSheet } from '@react-pdf/renderer';
import FuturaNormal from 'assets/fonts/FuturaStdBook.otf';
import FuturaBold from 'assets/fonts/FuturaStdBold.otf';
import {
  BASE_LINE_HEIGHT,
  FONT_SIZE_10,
  FONT_SIZE_32,
  FONT_WEIGHT_BOLD,
} from '../../styles/variables';

Font.register({
  family: 'Futura',
  fonts: [{ src: FuturaNormal }, { src: FuturaBold, fontWeight: FONT_WEIGHT_BOLD }],
});

const certificateStyles = StyleSheet.create({
  page: {
    fontFamily: 'Futura',
    lineHeight: BASE_LINE_HEIGHT,
    fontSize: FONT_SIZE_10,
  },
  body: {
    marginHorizontal: 50,
    fontFamily: 'Futura',
  },
  headerLogo: {
    height: 84,
    width: '100%',
    objectFit: 'contain',
    marginTop: 20,
  },
  address: {
    marginTop: 10,
    textAlign: 'center',
    fontWeight: FONT_WEIGHT_BOLD,
  },
  mainContact: {
    textAlign: 'center',
    marginTop: 40,
  },
  title: {
    fontSize: FONT_SIZE_32,
    textAlign: 'center',
    fontFamily: 'Futura',
    marginTop: 20,
  },

  subTitle: {
    textAlign: 'center',
    marginTop: 10,
    marginBottom: 10,
    lineHeight: 2,
    fontWeight: FONT_WEIGHT_BOLD,
  },

  subFooter: {
    marginTop: 20,
    fontWeight: FONT_WEIGHT_BOLD,
  },

  footer: {
    marginTop: 20,
    fontWeight: FONT_WEIGHT_BOLD,
  },
  footerContent: {
    marginTop: 20,
    marginLeft: 30,
  },
  user: {
    fontWeight: FONT_WEIGHT_BOLD,
  },
});

export default certificateStyles;
