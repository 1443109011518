import React from 'react';
import PropTypes from 'prop-types';
import { useGetRecreationalAndGreenAreasQuery } from 'api/producers/productions';
import { t } from 'i18next';
import SharedProductCultivationComponent from '../shared/shared-product-cultivation.component';

const greenAreas = ['publicly_accessible_green_area', 'urban_gardening'];

const GreenAreaProduction = ({ values, productionId }) => {
  const { data: recreationalAndGreenAreas } = useGetRecreationalAndGreenAreasQuery(
    { productionId },
    { skip: !productionId },
  );

  const getInitialValue = name =>
    recreationalAndGreenAreas?.find(({ subType }) => subType === name);

  return (
    <SharedProductCultivationComponent
      data={greenAreas}
      values={values}
      getInitialValue={getInitialValue}
      component="green_areas"
      title={t('plant_cultivation.green_areas.title')}
      icon="greenArea"
    />
  );
};

GreenAreaProduction.defaultProps = {
  values: {},
};

GreenAreaProduction.propTypes = {
  productionId: PropTypes.number.isRequired,
  values: PropTypes.shape(),
};

export default GreenAreaProduction;
