import classNames from 'classnames';
import React from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import { FieldArray } from 'react-final-form-arrays';
import PLANT_CULTIVATION from 'utils/plant-cultivation';
import Card from 'components/base-components/Card';
import production_type from 'utils/production-type';
import EnsureTransparencyComponent from 'components/shared-components/ensure-transparency';
import CheckboxWithIcon from '../../shared/checkbox-with-icon.component';
import CultivatedFruitWrapper from '../cultivated-fruit/wrapper.component';
import ArableCropsComponent from '../arable-crops/arable-crops.component';
import VegetablesFormComponent from '../vegetables/vegetables-form.component';
import MushroomFormComponent from '../mushroom/mushroom-form.component';
import HerbsComponent from '../herbs/index.component';
import TitleComponent from '../shared/title.component';
import CultivationMethodInputWithCheckbox from '../shared/cultivation-method.component';
import EmptyProducerContent from '../../shared/empty-producer-content.component';

const PlantTypesComponent = ({ plantCultivationData, values, onChangeSubTypeSelection }) => {
  const plants = [
    'arable_crop',
    'potato',
    'vegetable',
    'mushroom_farming',
    'fruit',
    'viticulture',
    'herb',
    'ornamental',
    'other',
  ];
  const produceTypes = plants.map(plant => {
    return {
      label: t(`plant_cultivation.plant_types.${plant}`),
      name: plant,
      icon: plant,
    };
  });

  const checkVisibility = plant => values?.plantTypes?.includes(plant);

  const isCardVisible = values?.plantTypes?.some(plant => plant !== null);

  const getInitialValues = subType => {
    if (!plantCultivationData) return null;
    let initialValue;
    if (subType === PLANT_CULTIVATION.fruit || subType === PLANT_CULTIVATION.herb) {
      initialValue = plantCultivationData.filter(plant => plant?.subType === subType);
      return initialValue;
    }
    initialValue = plantCultivationData.find(plant => plant?.subType === subType);

    if (!initialValue) return null;

    return initialValue.productionDetails;
  };

  const onChange = (name, checked) => {
    onChangeSubTypeSelection(production_type.PLANT_CULTIVATION, name, checked);
  };

  return (
    <>
      <Card className="plant-cultivation__method--outside-card">
        <div className="plant-cultivation__produce-type plant-cultivation__header">
          {t('plant_cultivation.bee_hives.produce_types')}
        </div>
        <div className="plant-cultivation__description">
          {t('plant_cultivation.bee_hives.produce_types_description')}
        </div>
        <FieldArray name="plantTypes">
          {() => (
            <div className="plant-cultivation__content">
              {produceTypes.map(({ name, label, icon }, index) => (
                <div
                  className={classNames('col-6 col-bleed plant-cultivation__content--item', {
                    'plant-cultivation__right-input': index % 2 !== 0,
                  })}
                >
                  <CheckboxWithIcon
                    label={label}
                    fieldName={`plantTypes[${index}]`}
                    name={name}
                    icon={icon}
                    isChecked={plantCultivationData?.some(
                      plant =>
                        plant?.subType === name &&
                        Object.keys(plant?.productionDetails)?.some(
                          production => !!plant?.productionDetails?.[production],
                        ),
                    )}
                    onChange={onChange}
                  />
                </div>
              ))}
            </div>
          )}
        </FieldArray>
      </Card>
      <EnsureTransparencyComponent />
      {isCardVisible ? (
        <Card className="plant-cultivation__method--outside-card">
          <div className="plant-cultivation__details-text">
            {t('egg_production.produce_details')}
          </div>
          <ArableCropsComponent
            isVisible={checkVisibility(PLANT_CULTIVATION.arable_crop)}
            plantCultivationData={plantCultivationData}
            initialValue={getInitialValues(PLANT_CULTIVATION.arable_crop)}
          />
          <CultivationMethodInputWithCheckbox
            iconName="potatoes"
            title={t('plant_cultivation.potatoes')}
            showCoveredCultivation={false}
            isVisible={checkVisibility(PLANT_CULTIVATION.potato)}
            fieldName="potatoes"
            initialValue={getInitialValues(PLANT_CULTIVATION.potato)}
            values={values}
            isCheckedAlways={true}
          />
          <VegetablesFormComponent
            isVisible={checkVisibility(PLANT_CULTIVATION.vegetable)}
            vegetableData={getInitialValues(PLANT_CULTIVATION.vegetable)}
            values={values}
          />
          <MushroomFormComponent
            isVisible={checkVisibility(PLANT_CULTIVATION.mushroom_farming)}
            mushroomData={getInitialValues(PLANT_CULTIVATION.mushroom_farming)}
            values={values}
          />
          <CultivatedFruitWrapper
            isVisible={checkVisibility(PLANT_CULTIVATION.fruit)}
            initialValue={getInitialValues(PLANT_CULTIVATION.fruit)}
            values={values}
          />
          {checkVisibility(PLANT_CULTIVATION.herb) && (
            <Card className="plant-cultivation__card">
              <TitleComponent title="Herbs" icon="herb" />
              <HerbsComponent
                isVisible={checkVisibility(PLANT_CULTIVATION.herb)}
                initialValue={getInitialValues(PLANT_CULTIVATION.herb)}
                values={values}
              />
            </Card>
          )}

          <CultivationMethodInputWithCheckbox
            iconName="viticulture"
            title={t('plant_cultivation.viticulture')}
            fieldName="viticulture"
            isVisible={checkVisibility(PLANT_CULTIVATION.viticulture)}
            initialValue={getInitialValues(PLANT_CULTIVATION.viticulture)}
            values={values}
            isViticulture={true}
          />

          <CultivationMethodInputWithCheckbox
            iconName="ornamental"
            title={t('plant_cultivation.plant_types.ornamental')}
            fieldName="ornamental"
            isVisible={checkVisibility(PLANT_CULTIVATION.ornamental)}
            initialValue={getInitialValues(PLANT_CULTIVATION.ornamental)}
            values={values}
          />
          <CultivationMethodInputWithCheckbox
            iconName="noIcon"
            title={t('plant_cultivation.other_culture')}
            fieldName="other"
            isVisible={checkVisibility(PLANT_CULTIVATION.other)}
            initialValue={getInitialValues(PLANT_CULTIVATION.other)}
            values={values}
          />
        </Card>
      ) : (
        <EmptyProducerContent />
      )}
    </>
  );
};
PlantTypesComponent.defaultProps = {
  plantCultivationData: [],
  values: [],
};

PlantTypesComponent.propTypes = {
  plantCultivationData: PropTypes.arrayOf(PropTypes.string),
  values: PropTypes.shape(),
  onChangeSubTypeSelection: PropTypes.func.isRequired,
};

export default PlantTypesComponent;
