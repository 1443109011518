import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const EmphasisTag = props => {
  const { className, type, radius, dark, icon, category, size, number, text, iconColor } = props;
  return (
    <div
      className={classNames(
        'emphasis-tag',
        className,
        { 'emphasis-tag--dark-mode': dark },
        `emphasis-tag--${type}`,
        `emphasis-tag--${size}`,
        `emphasis-tag--${radius}`,
      )}
    >
      <div className="emphasis-tag__content">
        {category && <div className="emphasis-tag__content__category">{category} </div>}
        {icon && (
          <div
            className={classNames(
              'emphasis-tag__content__icon',
              `emphasis-tag__content__icon-${iconColor}`,
            )}
          >
            {icon}
          </div>
        )}
        {text && <div className={classNames('emphasis-tag__content__text')}>{text}</div>}
        {number && <div className={classNames('emphasis-tag__number')}>{number}</div>}
      </div>
    </div>
  );
};

EmphasisTag.defaultProps = {
  type: 'neutral',
  icon: null,
  category: null,
  dark: false,
  size: 'small',
  className: null,
  number: null,
  text: null,
  radius: 'oval',
  iconColor: 'default',
};

EmphasisTag.propTypes = {
  type: PropTypes.oneOf([
    'accent',
    'important',
    'disabled',
    'success',
    'warning',
    'danger',
    'normal',
  ]),
  icon: PropTypes.node,
  category: PropTypes.node,
  dark: PropTypes.bool,
  size: PropTypes.oneOf(['normal', 'small', 'tiny']),
  text: PropTypes.node,
  className: PropTypes.string,
  number: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  radius: PropTypes.oneOf(['circular', 'oval']),
  iconColor: PropTypes.oneOf([
    'tertiary',
    'success',
    'warning',
    'danger',
    'inverse',
    'default',
    'neutral',
  ]),
};

export default EmphasisTag;
