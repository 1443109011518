import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../../base-components/Modal';
import Icon from '../../base-components/Icon';
import Button from '../../base-components/Button';
import './styles.scss';

const WarningModal = props => {
  const { onConfirm, onCancel, title, content, confirmActionText, cancelActionText, isDisabled } =
    props;

  return (
    <Modal className="modal__warning" {...props}>
      <Modal.Title className="modal__warning--title">
        <Icon name="warning" showBGColor={true} size="medium" color="warning" />
        <div className="modal__warning--title-name">{title}</div>
      </Modal.Title>
      <Modal.Content className="modal__warning--content">{content}</Modal.Content>
      <Modal.Footer>
        <Button
          label={confirmActionText}
          type="success"
          onClick={onConfirm}
          size="small"
          disabled={isDisabled}
        />
        <Button label={cancelActionText} onClick={onCancel} size="small" />
      </Modal.Footer>
    </Modal>
  );
};

WarningModal.defaultProps = {
  isDisabled: false,
};

WarningModal.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  confirmActionText: PropTypes.string.isRequired,
  cancelActionText: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
};

export default WarningModal;
