import classNames from 'classnames';
import Card from 'components/base-components/Card';
import CheckBox from 'components/base-components/CheckBox/CheckBox';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import TitleComponent from '../../plant-cultivate/shared/title.component';
import SharedMeatField from './shared-field.component';

const PoultryForm = ({ label, icon, isVisible, initialValue }) => {
  if (!isVisible) return;

  const poultryData = [
    {
      name: 'numberOfMalePullets',
      label: t('meat_production.poultry_data.male_pullets'),
    },
    {
      name: 'numberOfFatteningChicken',
      label: t('meat_production.poultry_data.fattering_chicken'),
    },
    {
      name: 'numberOfTurkeys',
      label: t('meat_production.poultry_data.turkeys'),
    },
    {
      name: 'numberOfGeese',
      label: t('meat_production.poultry_data.geese'),
    },
  ];

  const poultryChecbox = ['ducks', 'quails', 'ostriches', 'guinea_fowls', 'emus', 'wild_fowl'];

  const poultryCheckboxData = poultryChecbox.map(poultry => {
    return {
      name: poultry,
      label: t(`meat_production.poultry_data.${poultry}`),
    };
  });

  return (
    <Card className="product-cultivation__card">
      <TitleComponent title={label} icon={icon} />
      <Card className="product-cultivation__inside-card">
        {poultryData.map(({ name, label }, index) => (
          <SharedMeatField
            fieldName={`poultry.${name}`}
            name={name}
            label={label}
            index={index}
            initialValue={initialValue}
          />
        ))}
      </Card>
      <FieldArray name="poultryCheckbox">
        {() => (
          <Card className="product-cultivation__poultry">
            <div className="product-cultivation__other-text">
              {t('meat_production.poultry_data.other_poultry')}
            </div>
            {poultryCheckboxData.map(({ name, label }, index) => (
              <div
                className={classNames('col-4', {
                  'col-bleed-x': index <= 2,
                  'col-bleed': index > 2,
                })}
              >
                <Field
                  name={`poultryCheckbox[${index}]`}
                  type="checkbox"
                  initialValue={
                    initialValue?.productionDetails?.otherPoultry?.includes(name) ? name : null
                  }
                >
                  {({ input }) => (
                    <CheckBox
                      isChecked={input.checked}
                      size="tiny"
                      label={label}
                      onChange={e => input.onChange(e.target.checked ? name : null)}
                    />
                  )}
                </Field>
              </div>
            ))}
          </Card>
        )}
      </FieldArray>
    </Card>
  );
};

PoultryForm.defaultProps = {
  label: null,
  icon: null,
  isVisible: false,
  initialValue: {},
};

PoultryForm.propTypes = {
  label: PropTypes.string,
  icon: PropTypes.node,
  isVisible: PropTypes.string,
  initialValue: PropTypes.shape(),
};

export default PoultryForm;
