import { Font, StyleSheet } from '@react-pdf/renderer';
import TimesNewRomanRegular from 'assets/fonts/TimesNewRoman-Regular.ttf';
import TimesNewRomanBold from 'assets/fonts/TimesNewRoman-Bold.ttf';
import {
  BASE_LINE_HEIGHT,
  FONT_SIZE_10,
  FONT_WEIGHT_BOLD,
} from '../../styles/variables';

Font.register({
  family: 'Times New Roman',
  fonts: [{ src: TimesNewRomanRegular }, { src: TimesNewRomanBold, fontWeight: FONT_WEIGHT_BOLD }],
});

const coverLetterStyles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    justifyContent: 'space-between',
    fontFamily: 'Times New Roman',
    lineHeight: BASE_LINE_HEIGHT,
    fontSize: FONT_SIZE_10,
    paddingBottom: 20,
  },
  body: {
    flexGrow: 1,
    marginLeft: 50,
    marginRight: 30,
    fontFamily: 'Times New Roman',
  },
  headerLogo: {
    height: 90,
    width: '100%',
    objectFit: 'contain',
    marginBottom: 24,
    marginTop: 32,
  },
  subject: {
    fontWeight: FONT_WEIGHT_BOLD,
    marginBottom: 10
  },
  description: {
    marginTop: 10,
    marginBottom: 10
  },
  link: {
    color: 'blue',
  },
  table: {
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
    borderWidth: 1,
    marginBottom: 10
  },
  tableRow: { 
    margin: '0 auto', 
    flexDirection: 'row',
    borderBottom: 1
  },
  lastTableRow: { 
    margin: '0 auto', 
    flexDirection: 'row',
  },
  tableCol1: { 
    width: '30%',
  },
  tableCol2: { 
    width: '70%',
  },
  cell: {
    marginTop: 5,
    marginBottom: 5,
  },
});

export default coverLetterStyles;
