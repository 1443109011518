import PropTypes from 'prop-types';
import CheckBox from 'components/base-components/CheckBox/CheckBox';
import Input from 'components/base-components/Input';
import React from 'react';
import { Field, useFormState } from 'react-final-form';
import { t } from 'i18next';
import Tooltip from 'components/base-components/Tooltip';
import IconButton from 'components/base-components/IconButton';
import Icon from 'components/base-components/Icon';
import classNames from 'classnames';
import { HIGH_TRUNK_TREE } from 'utils/plant-cultivation';

const FruitCheckboxInput = ({
  name,
  fieldName,
  label,
  isCultivationMethodIncluded,
  initialValue,
  mainFieldName,
  index,
}) => {
  const { values } = useFormState();

  const isOpenFieldDisabled = !values.fruitData?.[mainFieldName]?.[index]?.open_field;
  const isCoveredFieldDisabled = !values.fruitData?.[mainFieldName]?.[index]?.covered_field;

  return (
    <>
      <div
        className={classNames('plant-cultivation__method--open-fruit', {
          'plant-cultivation__method--open-fruit__check--selected':
            (mainFieldName === HIGH_TRUNK_TREE &&
              values?.fruitData?.[mainFieldName]?.[index]?.open_field) ||
            (initialValue?.fruitType && initialValue?.productionDetails?.numberOfTrees),
        })}
      >
        <div>
          <Field
            name={`${fieldName}.fruit_type`}
            type="hidden"
            component="input"
            initialValue={
              (values?.fruitData?.[mainFieldName]?.[index]?.open_field ||
                values?.fruitData?.[mainFieldName]?.[index]?.covered_field) &&
              name
            }
          />
          {mainFieldName !== HIGH_TRUNK_TREE && label}
        </div>
        <div
          className={classNames('plant-cultivation__method--open-fruit__check', {
            'plant-cultivation__method--open-fruit__check--high-trunk':
              mainFieldName === HIGH_TRUNK_TREE,
          })}
        >
          <Field
            name={`${fieldName}.open_field`}
            type="checkbox"
            initialValue={
              initialValue?.productionDetails?.numberOfTrees ||
              initialValue?.productionDetails?.openFieldSurfaceAreaInAres
            }
          >
            {({ input }) => (
              <CheckBox
                isChecked={input.checked}
                size="tiny"
                label={
                  mainFieldName === HIGH_TRUNK_TREE
                    ? label
                    : t('plant_cultivation.shared.open_field')
                }
                onChange={e => input.onChange(e.target.checked)}
              />
            )}
          </Field>
          <Field
            name={
              mainFieldName === HIGH_TRUNK_TREE
                ? `${fieldName}.number_of_trees`
                : `${fieldName}.open_surface_area_in_ares`
            }
            initialValue={
              initialValue?.productionDetails?.numberOfTrees ||
              initialValue?.productionDetails?.openFieldSurfaceAreaInAres
            }
          >
            {({ input }) => (
              <Input
                size="tiny"
                label={
                  mainFieldName === HIGH_TRUNK_TREE
                    ? t('plant_cultivation.fruits.number_of_trees')
                    : t('plant_cultivation.mushroom.surface_area_ares')
                }
                placeholder={t('plant_cultivation.shared.surface_area_input_placeholder')}
                disabled={isOpenFieldDisabled}
                {...input}
              />
            )}
          </Field>
        </div>
        {isCultivationMethodIncluded && mainFieldName !== HIGH_TRUNK_TREE && (
          <div className="plant-cultivation__method--open-fruit__check">
            <Field
              name={`${fieldName}.covered_field`}
              type="checkbox"
              initialValue={
                initialValue?.productionDetails?.numberOfTrees ||
                initialValue?.productionDetails?.coveredFieldSurfaceAreaInAres
              }
            >
              {({ input }) => (
                <CheckBox
                  isChecked={input.checked}
                  size="tiny"
                  label={
                    <div>
                      <span className="plant-cultivation__method--open__tooltip-label">
                        {t('plant_cultivation.shared.covered_cultivation')}
                      </span>
                      <span>
                        <Tooltip
                          content={t('plant_cultivation.shared.covered_cultivation_info')}
                          type="inverse"
                          position="right-top"
                          size="small"
                          showArrow={true}
                        >
                          <IconButton
                            icon={<Icon name="info" size="tiny" />}
                            className="plant-cultivation__checkbox-fruit--covered"
                            color="default"
                            size="tiny"
                            isIconOnly={true}
                          />
                        </Tooltip>
                      </span>
                    </div>
                  }
                  onChange={e => input.onChange(e.target.checked)}
                />
              )}
            </Field>
            <Field
              name={
                mainFieldName === HIGH_TRUNK_TREE
                  ? `${fieldName}.number_of_trees`
                  : `${fieldName}.covered_surface_area_in_ares`
              }
              initialValue={
                initialValue?.productionDetails?.numberOfTrees ||
                initialValue?.productionDetails?.coveredFieldSurfaceAreaInAres
              }
            >
              {({ input }) => (
                <Input
                  size="tiny"
                  label={
                    mainFieldName === HIGH_TRUNK_TREE
                      ? t('plant_cultivation.fruits.number_of_trees')
                      : t('plant_cultivation.mushroom.surface_area_ares')
                  }
                  placeholder={t('plant_cultivation.shared.surface_area_input_placeholder')}
                  disabled={isCoveredFieldDisabled}
                  {...input}
                />
              )}
            </Field>
          </div>
        )}
      </div>
    </>
  );
};

FruitCheckboxInput.defaultProps = {
  name: null,
  fieldName: null,
  mainFieldName: null,
  label: null,
  index: null,
  isCultivationMethodIncluded: true,
  initialValue: {},
};

FruitCheckboxInput.propTypes = {
  name: PropTypes.string,
  fieldName: PropTypes.string,
  mainFieldName: PropTypes.string,
  label: PropTypes.string,
  index: PropTypes.number,
  isCultivationMethodIncluded: PropTypes.bool,
  initialValue: PropTypes.shape(),
};

export default FruitCheckboxInput;
