import Button from 'components/base-components/Button';
import DatePicker from 'components/base-components/DatePicker/DatePicker';
import { dateTimeWithZone } from 'utils/date-time-formatter';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Field, Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import SelectBox from 'components/base-components/SelectBox';
import { useGetOrganizationIndustriesQuery } from 'api/organizations';
import { useCreateInvoiceJobMutation } from 'api/invoices';
import SubmitModal from 'components/shared-components/modal/submit-modal';
import TimePickerComponent from 'components/base-components/TimePicker';
import { CERTIFICATION_STATUS } from 'utils/invoice-utils';

const CreateJob = ({ setIsSidepanelOpen }) => {
  const [createInvoiceJob, status] = useCreateInvoiceJobMutation();
  const { t, i18n } = useTranslation();
  const [isStartsAtOpen, setIsStartsAtOpen] = useState(false);

  const { data: industryOptions = [] } = useGetOrganizationIndustriesQuery();

  const invoiceTypeOptions = [
    { label: t('invoice.invoice_job.types.for_certification_status_u2_vk'), value: CERTIFICATION_STATUS },
  ];

  const onSubmit = (values, form) => {
    return createInvoiceJob({
      payload: {
        scheduled_for: dateTimeWithZone(`${values.startsAt} ${values.time}`),
        department: values.department,
        type: values.invoiceType,
      },
    })
      .unwrap()
      .then(() => {
        form.reset();
        setIsSidepanelOpen(false);
      })
      .catch(({ data: { errors } }) => {
        return {
          startsAt: errors?.scheduledFor,
          time: errors?.scheduledTime,
          department: errors?.department,
          type: errors?.invoiceType,
        };
      });
  };

  return (
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit, submitting, values }) => (
        <form onSubmit={handleSubmit}>
          <Field name="startsAt">
            {({ input, meta }) => (
              <DatePicker
                focused={isStartsAtOpen}
                required={true}
                date={input.value}
                onChange={e => input.onChange(e)}
                onFocusChange={() => setIsStartsAtOpen(!isStartsAtOpen)}
                displayFormat="LL"
                label={t('invoice.invoice_job.date.label')}
                placeholder={t('invoice.invoice_job.date.placeholder')}
                size="tiny"
                hideNavButtons={false}
                minDate={moment().format('YYYY-MM-DD')}
                touched={!meta.dirtySinceLastSubmit}
                error={meta.submitError}
                locale={i18n.language}
              />
            )}
          </Field>
          <Field name="time" initialValue={'00:00'}>
            {({ input, meta }) => (
              <>
                <TimePickerComponent
                  className="invoice__time-picker"
                  onChange={e => input.onChange(e)}
                  getTime={input.value && input.value.split(':')[1]}
                  value={input.value}
                  locale="sv-sv"
                  disableClock={true}
                  handleTime={e => input.onChange(e)}
                  header={t('invoice.release.select_time')}
                />
                <div className="invoice__field-error"> {meta?.submitError} </div>
              </>
            )}
          </Field>
          <div className="invoice__time-picker">
            <Field name="department" initialValue={industryOptions[1]?.value}>
              {({ input, meta }) => (
                <SelectBox
                  size="tiny"
                  width="large"
                  label={t('invoice.invoice_job.department.label')}
                  placeholderText={t('invoice.invoice_job.department.placeholder')}
                  isClearable={false}
                  options={industryOptions}
                  value={industryOptions.find(option => option.value === input.value)}
                  onChange={e => input.onChange(e.value)}
                  required={true}
                  errorMsg={meta.submitError}
                />
              )}
            </Field>
          </div>
          <div className="invoice__time-picker">
            <Field name="invoiceType" initialValue={invoiceTypeOptions[0]?.value}>
              {({ input, meta }) => (
                <SelectBox
                  size="tiny"
                  width="full"
                  label={t('invoice.invoice_job.invoice_type.label')}
                  placeholderText={t('invoice.invoice_job.invoice_type.placeholder')}
                  onChange={e => input.onChange(e.value)}
                  value={invoiceTypeOptions.find(option => option.value === input.value)}
                  selectedValue={input.label}
                  options={invoiceTypeOptions}
                  errorMsg={meta.submitError}
                />
              )}
            </Field>
          </div>
          <div className="invoice__buttons">
            <Button
              label={t('invoice.create_new_job')}
              type="success"
              size="small"
              submitType="submit"
              disabled={submitting}
            />
            <Button
              className="invoice__buttons-cancel"
              label={t('common.cancel')}
              size="small"
              onClick={() => setIsSidepanelOpen(false)}
            />
          </div>
          <SubmitModal
            isLoading={status.isLoading}
            isSuccess={status.isSuccess}
            requestId={status.requestId}
            successTitle={t('invoice.invoice_job.modal.title')}
            successContent={t('invoice.invoice_job.modal.description')}
            errorTitle={t('invoice.invoice_publish_failed_message')}
            errorContent={status.error?.data?.message}
            showError={true}
          />
        </form>
      )}
    />
  );
};

CreateJob.propTypes = {
  setIsSidepanelOpen: PropTypes.func.isRequired,
};

export default CreateJob;
