import { groupBy } from 'utils/groupBy';
import { addressValues } from '../producer/submit-form/address';
import { beeValues } from '../producer/submit-form/bee';
import { eggValues } from '../producer/submit-form/egg';
import { farmlandValues } from '../producer/submit-form/farmland';
import { fishValues } from '../producer/submit-form/fish';
import { gardeningValues } from '../producer/submit-form/gardening';
import { greenAreaValues } from '../producer/submit-form/green-areas';
import { meatValues } from '../producer/submit-form/meat';
import { membershipValues } from '../producer/submit-form/membership';
import { milkValues } from '../producer/submit-form/milk';
import { plantValues } from '../producer/submit-form/plants';
import { privateParkValues } from '../producer/submit-form/private-park';
import { subOrganisationValues } from '../producer/submit-form/sub-organisation';
import { milksTypes } from '../producer/milk/milk-types.component';
import { tvdSticker } from '../producer/submit-form/tvd-sticker';
import { legalFormValues } from '../producer/submit-form/legal-form';

const getFormData = (values, department, organization, industry) => {
  values.draft = false;
  const phoneNumberByType = groupBy(organization?.phoneNumbers || [], 'numberType');

  let formData = new FormData();
  if (industry) {
    formData.append('industry', industry);
  }
  const parentOrganization = values.parentOrganisation;

  farmlandValues(values, formData);
  plantValues(values, formData);
  meatValues(values, formData);
  milkValues(values, formData, milksTypes);
  gardeningValues(values, formData);
  eggValues(values, formData);
  beeValues(values, formData);
  membershipValues(values, formData);
  tvdSticker(values, formData);
  fishValues(values, formData);
  greenAreaValues(values, formData);
  privateParkValues(values, formData);
  legalFormValues(values, formData);

  if (values.companyType) {
    formData.append('producer_type', values.companyType);
  }

  if (organization) {
    (values.removedProductionTypes || []).forEach(productType => {
      formData.append('production_details[remove_details][]', productType);
    });

    Object.keys(values.removedSubTypes || {}).forEach(productType => {
      if (values.removedSubTypes[productType].length > 0) {
        formData.append('production_details[remove_sub_types][][main_type]', productType);
      }

      values.removedSubTypes[productType].forEach(subType => {
        formData.append('production_details[remove_sub_types][][sub_types][]', subType);
      });
    });
  }

  if (values?.otherFarms) {
    for (const key in values.otherFarms) {
      if (values.otherFarms[key]) {
        formData.append('production_details[other_farms][animal_types][]', key);
      }
    }
  }

  let params = {
    additional_name: values.additionalName,
    name: values.registeredName,
    industry: (values.industry && values.industry.value) || null,
    language: values.language,
    second_spoken_language: values?.secondSpokenLanguage?.value,
    parent_id: parentOrganization?.value,
    control_body_organization_id: values.control_body_organization_id,
  };

  subOrganisationValues(values, formData, department);

  addressValues(values, formData, phoneNumberByType);

  formData.append('type', values.organisationType);

  formData.append('draft', values.draft);

  if (!organization) {
    params['uid'] = values.uid || null;
  }

  const logo = values.logo;

  if (logo) {
    params = { ...params, logo };
  }

  formData.append('is_invoice_recipient', !!values.receiveInvoice);
  Object.keys(params).forEach(key => {
    if (params[key]) {
      formData.append(key, params[key]);
    }
  });

  return formData;
};

export default getFormData;
