import routes from 'utils/routes';
import { ORGANISATION } from 'utils/tag-types';
import { apiSlice } from '../../index';

const ProductionsApi = apiSlice.injectEndpoints({
  endpoints: build => ({
    getProductions: build.query({
      query: params => routes.productions.collection(params),
      providesTags: [ORGANISATION],
    }),
    getProduction: build.query({
      query: params => routes.productions.entity(params),
      providesTags: [ORGANISATION],
    }),
    getMeatFirms: build.query({
      query: params => routes.productions.meatCollection(params),
      providesTags: [ORGANISATION],
    }),
    getDairyFarms: build.query({
      query: params => routes.productions.dairyFarmsCollection(params),
      providesTags: [ORGANISATION],
    }),
    getMilProcessingSources: build.query({
      query: params => routes.productions.milkProcessingSource(params),
    }),
    getOrganicMilProcessingOrganizations: build.query({
      query: params => routes.productions.organicMilkOrganizations(params),
    }),
    getFirstMilkBuyers: build.query({
      query: params => routes.productions.firstMilkBuyers(params),
    }),
    getGardeningOptions: build.query({
      query: () => routes.productions.gardeningItemOptions(),
      providesTags: [],
    }),
    getGardeningItem: build.query({
      query: params => routes.productions.gardeningItem(params),
      providesTags: [ORGANISATION],
    }),
    getBeeHiveProduction: build.query({
      query: params => routes.productions.beeHiveProductions(params),
      providesTags: [ORGANISATION],
    }),
    getFishFarmProduction: build.query({
      query: params => routes.productions.fishFarmProduction(params),
      providesTags: [ORGANISATION],
    }),
    getOtherFarmItem: build.query({
      query: params => routes.productions.otherFarmProduction(params),
      providesTags: [ORGANISATION],
    }),
    getOtherFarmOptions: build.query({
      query: params => routes.productions.otherFarmOptions(params),
      providesTags: [ORGANISATION],
    }),
    getRecreationalAndGreenAreas: build.query({
      query: params => routes.productions.recreationalAndGreenAreas(params),
      providesTags: [ORGANISATION],
    }),
    getPrivateAndMunicipalAreas: build.query({
      query: params => routes.productions.privateAndMunicipalAreas(params),
      providesTags: [ORGANISATION],
    }),
    getArableCropOptions: build.query({
      query: () => routes.productions.arableCropsOptions(),
      providesTags: [ORGANISATION],
    }),
    getEggProductions: build.query({
      query: params => routes.productions.eggProductions(params),
      providesTags: [ORGANISATION],
    }),
    getProcessingTypeOptions: build.query({
      query: () => routes.productions.processingTypeOptions(),
      providesTags: [ORGANISATION],
    }),
    getProductTypeOptions: build.query({
      query: () => routes.productions.productTypeOptions(),
      providesTags: [ORGANISATION],
    }),
    getOrchardTypeOptions: build.query({
      query: () => routes.productions.orchardTypeOptions(),
      providesTags: [ORGANISATION],
    }),
    getBerryTypeOptions: build.query({
      query: () => routes.productions.berryTypeOptions(),
      providesTags: [ORGANISATION],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetProductionsQuery,
  useGetProductionQuery,
  useGetMeatFirmsQuery,
  useGetDairyFarmsQuery,
  useGetMilProcessingSourcesQuery,
  useGetOrganicMilProcessingOrganizationsQuery,
  useGetFirstMilkBuyersQuery,
  useGetGardeningOptionsQuery,
  useGetGardeningItemQuery,
  useGetBeeHiveProductionQuery,
  useGetFishFarmProductionQuery,
  useGetOtherFarmItemQuery,
  useGetOtherFarmOptionsQuery,
  useGetRecreationalAndGreenAreasQuery,
  useGetPrivateAndMunicipalAreasQuery,
  useGetArableCropOptionsQuery,
  useGetEggProductionsQuery,
  useGetProcessingTypeOptionsQuery,
  useGetProductTypeOptionsQuery,
  useGetOrchardTypeOptionsQuery,
  useGetBerryTypeOptionsQuery,
} = ProductionsApi;
