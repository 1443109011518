import { useGetUserAssignmentsQuery } from 'api/users';
import Icon from 'components/base-components/Icon';
import React, { Fragment } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import Spinner from 'components/base-components/Spinner';
import {
  Table,
  TableBody,
  TableData,
  TableHead,
  TableHeader,
  TableRow,
} from 'components/base-components/Table';
import OrganizationAddress from 'components/organisations/organisation-address';
import OrganizationName from 'components/organisations/organization-name';
import EmptyPlaceholder from 'components/users/empty-placeholder';
import Pagination from 'components/base-components/Pagination';
import { dateMonthYearFormatter } from 'utils/date-time-formatter';
import UserEmail from '../user-email.component';
import '../users.styles.scss';

const AssignmentListComponent = () => {
  const [searchParams, setSearchParams] = useSearchParams({ page: 1 });
  const { id } = useParams();
  const currentPage = searchParams.get('page');
  const currentTab = searchParams.get('tab');
  const { isFetching, data, isError } = useGetUserAssignmentsQuery({ id, page: currentPage });
  const assignments = data ? data.assignments : [];
  const { t } = useTranslation();

  return (
    <>
      {isFetching || isError ? (
        <Spinner className="user-details__spinner" bgColor="none" color="success" size="small" />
      ) : assignments.length > 0 ? (
        <Fragment>
          <Table>
            <TableHead>
              <TableRow>
                <TableHeader>{t('user_details.assignment.table_head.organisation')}</TableHeader>
                <TableHeader>{t('user_details.assignment.table_head.valid_from')}</TableHeader>
                <TableHeader>{t('user_details.assignment.table_head.valid_until')}</TableHeader>
                <TableHeader>{t('user_details.assignment.table_head.title')}</TableHeader>
                <TableHeader>{t('user_details.assignment.table_head.address')}</TableHeader>
                <TableHeader>{t('user_details.assignment.table_head.email')}</TableHeader>
                {/* TODO: when verification can be done by phone number as well*/}
                {/* <TableHeader>{t('user_details.assignment.table_head.phone')}</TableHeader> */}
                <TableHeader>{t('user_details.assignment.table_head.role')}</TableHeader>
              </TableRow>
            </TableHead>
            <TableBody>
              {assignments.map(assignment => (
                <TableRow>
                  <TableData>
                    <OrganizationName organizationId={assignment.organizationId} />
                  </TableData>
                  <TableData>
                    {assignment.validFrom
                      ? dateMonthYearFormatter(assignment.validFrom)
                      : t('common.not_applicable')}
                  </TableData>
                  <TableData>
                    {assignment.validUntil
                      ? dateMonthYearFormatter(assignment.validUntil)
                      : t('common.not_applicable')}
                  </TableData>
                  <TableData>
                    {assignment.title
                      ? t(`organisation.titles.${assignment.title}`)
                      : t('common.not_applicable')}
                  </TableData>
                  <TableData>
                    <OrganizationAddress organizationId={assignment.organizationId} />
                  </TableData>
                  <TableData>
                    <UserEmail userID={assignment.userId} />
                  </TableData>
                  {/* TODO: when verification can be done by phone number as well*/}
                  {/* <TableData>
                  <UserPhone userID={assignment.userId} />
                </TableData> */}
                  <TableData>
                    {t(`role.${assignment?.role}`) || t('common.not_applicable')}
                  </TableData>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <div className="user-details__assignment-list">
            <Pagination
              onPaginationClick={newPage => {
                setSearchParams({ tab: currentTab, page: newPage });
              }}
              totalPages={data && parseInt(data.pagination.totalPages, 10)}
              currentPage={currentPage}
              nextText={t('pagination.next')}
              previousText={t('pagination.prev')}
              firstText={t('pagination.first')}
              lastText={t('pagination.last')}
            />
          </div>
        </Fragment>
      ) : (
        <EmptyPlaceholder
          emptyCardText={t('user_details.assignment.empty_placeholder')}
          emptyIcon={<Icon name="organization" />}
        />
      )}
    </>
  );
};

export default AssignmentListComponent;
