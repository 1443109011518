import React from 'react';
import PropTypes from 'prop-types';
import { useGetPrivateAndMunicipalAreasQuery } from 'api/producers/productions';
import { t } from 'i18next';
import SharedProductCultivationComponent from '../shared/shared-product-cultivation.component';

const privateAreas = ['private_area', 'municipal_area'];

const PrivateParkProduction = ({ values, productionId }) => {
  const { data: privateAndMunicipalAreas } = useGetPrivateAndMunicipalAreasQuery(
    { productionId },
    { skip: !productionId },
  );

  const getInitialValue = name => privateAndMunicipalAreas?.find(({ subType }) => subType === name);

  return (
    <SharedProductCultivationComponent
      data={privateAreas}
      values={values}
      getInitialValue={getInitialValue}
      component="private_park"
      title={t('plant_cultivation.private_park.title')}
      icon="privatePark"
    />
  );
};

PrivateParkProduction.defaultProps = {
  values: {},
};

PrivateParkProduction.propTypes = {
  values: PropTypes.shape(),
  productionId: PropTypes.number.isRequired,
};

export default PrivateParkProduction;
