import { t } from 'i18next';
import React from 'react';
import { useGetOrganizationQuery } from 'api/organizations';
import PropTypes from 'prop-types';
import { Field, useFormState } from 'react-final-form';
import classNames from 'classnames';
import RadioWithoutCheck from 'components/base-components/RadioWithoutCheck';
import { useParams } from 'react-router-dom';
import EmptyLegalForm from './shared/empty-legal-form.component';
import ContractPartnerList from './shared/contract-partner-list.component';

const ContactPersons = ({ selectedNumberOfPartners, contractUsers, validationError }) => {
  const { id } = useParams();
  const { data: organization } = useGetOrganizationQuery(id, {
    skip: !id,
  });

  const { values } = useFormState();
  if (!selectedNumberOfPartners) {
    return (
      <EmptyLegalForm
        title={t('legal_form.contract_person.title')}
        description={t('legal_form.contract_person.description')}
      />
    );
  }

  if (values?.mainContact !== selectedNumberOfPartners) {
    delete values?.otherPartner;
  }

  const getInitialValue = partner => {
    const isSelected = contractUsers.some(user => user.userId === organization?.mainContactId);

    if (isSelected && contractUsers[partner]?.userId === organization?.mainContactId) {
      return partner;
    } else if (!isSelected && organization?.mainContactId) {
      return selectedNumberOfPartners;
    }
  };

  return (
    <>
      <div className="legal-form__contract--title">{t('legal_form.contract_person.title')}</div>
      <div className="legal-form__contract--description">
        {t('legal_form.contract_person.description')}
      </div>
      <div className="gird col-12 col-bleed legal-form__radio-button">
        {[...Array(selectedNumberOfPartners + 1).keys()].map(partner => (
          <>
            <Field
              name={
                partner === selectedNumberOfPartners
                  ? 'otherPartner.userId'
                  : `legalFormPartners[${partner}].userId`
              }
              type="hidden"
              component="input"
              initialValue={
                contractUsers[partner]?.userId ||
                (partner === selectedNumberOfPartners && organization?.mainContactId)
              }
            />
            <Field
              name="mainContact"
              initialValue={organization?.mainContactId ? getInitialValue(partner) : null}
            >
              {({ input }) => (
                <div
                  className={classNames('col-4 col-bleed', {
                    'legal-form__radio-button--second-row': partner > 2,
                  })}
                >
                  <RadioWithoutCheck
                    isChecked={partner === input.value}
                    label={
                      partner === selectedNumberOfPartners
                        ? t('shared.other')
                        : t('legal_form.input.header', {
                            partner: partner + 1,
                          })
                    }
                    name={`item-${partner}`}
                    key={`item-${partner}`}
                    onChange={() => input.onChange(partner)}
                    size="medium"
                  />
                </div>
              )}
            </Field>
          </>
        ))}
      </div>
      {validationError?.mainContact && (
        <div className="legal-form__required-field">
          {t('legal_form.contract_person.error_text')}
        </div>
      )}
      {values?.mainContact === selectedNumberOfPartners && (
        <div className="col-12 col-bleed">
          <ContractPartnerList
            name="otherPartner"
            userId={
              organization?.mainContactId &&
              !contractUsers.some(user => user.userId === organization?.mainContactId) &&
              organization.mainContactId
            }
            status="other"
            validationError={validationError}
          />
        </div>
      )}
    </>
  );
};

ContactPersons.defaultProps = {
  selectedNumberOfPartners: null,
  contractUsers: [],
  validationError: {},
};

ContactPersons.propTypes = {
  selectedNumberOfPartners: PropTypes.number,
  contractUsers: PropTypes.arrayOf(PropTypes.shape()),
  validationError: PropTypes.shape(),
};

export default ContactPersons;
