import Card from 'components/base-components/Card';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import React from 'react';
import TitleComponent from '../../plant-cultivate/shared/title.component';
import SharedMeatField from './shared-field.component';

const PigsForm = ({ label, icon, isVisible, initialValue }) => {
  if (!isVisible) return;
  const pigData = [
    {
      name: 'numberOfFatteningPigs',
      label: t('meat_production.pig_data.fattering_pigs'),
    },
    {
      name: 'numberOfBreedingPigs',
      label: t('meat_production.pig_data.breeding_pigs'),
    },
  ];

  return (
    <Card className="product-cultivation__card">
      <TitleComponent title={label} icon={icon} />
      <Card className="product-cultivation__inside-card">
        {pigData.map(({ name, label }, index) => (
          <SharedMeatField
            fieldName={`pigs.${name}`}
            name={name}
            label={label}
            index={index}
            initialValue={initialValue}
          />
        ))}
      </Card>
    </Card>
  );
};

PigsForm.defaultProps = {
  label: null,
  icon: null,
  isVisible: false,
  initialValue: {},
};

PigsForm.propTypes = {
  label: PropTypes.string,
  icon: PropTypes.node,
  isVisible: PropTypes.string,
  initialValue: PropTypes.shape(),
};

export default PigsForm;
