import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

function Title(props) {
  const { children, className, level, weight } = props;

  return (
    <h1 className={classNames('title', className, `title--level-${level}`, `title--${weight}`)}>
      {children}
    </h1>
  );
}

Title.defaultProps = {
  children: null,
  className: null,
  level: 1,
  weight: 'normal',
};

Title.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  level: PropTypes.oneOf([1, 2]),
  weight: PropTypes.oneOf(['bold', 'medium', 'normal', 'light']),
};

export default Title;
