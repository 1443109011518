import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import SelectBox from '../base-components/SelectBox';
import { useGetCountriesQuery } from '../../api/countries';

const CountrySelector = props => {
  const { t } = useTranslation();
  const {
    data: countries = [],
    isFetching,
    isSuccess: isCountriesFetched,
  } = useGetCountriesQuery();

  const initiallySelectedCountryCode = 'CH';

  const initialCountry =
    isCountriesFetched && countries.find(country => country.code === initiallySelectedCountryCode);

  const initialCountryOptions = isCountriesFetched && {
    label: initialCountry?.name,
    value: initialCountry?.code,
  };

  const countriesOptions = useMemo(
    () => countries.map(country => ({ label: country.name, value: country.code })),
    [JSON.stringify(countries)],
  );

  return (
    <SelectBox
      label={t('shared.label.address.country')}
      placeholderText={t('shared.placeholder.address.country')}
      options={countriesOptions}
      isDisabled={props.isDisabled || isFetching}
      {...props}
      value={props.value || initialCountryOptions}
    />
  );
};

CountrySelector.propTypes = {
  isDisabled: PropTypes.bool.isRequired,
  value: PropTypes.string.isRequired,
};

export default CountrySelector;
