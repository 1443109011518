import React from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import { Field, useField } from 'react-final-form';
import Icon from 'components/base-components/Icon';
import Calendar from 'components/base-components/Calendar';
import { dateFullMonthYear, yearMonthDayWithHyphen } from 'utils/date-time-formatter';

const DateRangeFilter = ({ title, fieldName, form }) => {
  const dateField = useField(fieldName);

  const getDate = date => {
    const dateValue = dateField.input.value[date];

    if (dateValue === 'Invalid date') {
      return '';
    }

    return dateValue ? dateFullMonthYear(dateValue) : '';
  };

  return (
    <div>
      <span className="modal__content__title">{title}</span>
      <span
        className="modal__content__action-buttons"
        onClick={() => {
          form.change(fieldName, {});
        }}
      >
        {t('shared.action.reset')}
      </span>
      <div className="modal__content__date-range">
        <input
          type="text"
          placeholder={t('invoice.placeholder.start_date')}
          value={getDate('start_date')}
        />
        <Icon size="tiny" name="arrowRightAlt" />
        <input
          type="text"
          placeholder={t('invoice.placeholder.end_date')}
          value={getDate('end_date')}
        />
        <Icon size="tiny" name="calendarAlt" />
      </div>
      <Field name={fieldName}>
        {({ input }) => (
          <Calendar
            onChange={selectedDate => {
              input.onChange({
                start_date: yearMonthDayWithHyphen(selectedDate.startDate),
                end_date: yearMonthDayWithHyphen(selectedDate.endDate),
              });
            }}
            enablePastDates={true}
            enableFilterDropdown={true}
            enableDateRangeSelection={true}
          />
        )}
      </Field>
    </div>
  );
};

DateRangeFilter.propTypes = {
  title: PropTypes.string.isRequired,
  fieldName: PropTypes.string.isRequired,
  form: PropTypes.func.isRequired,
};

export default DateRangeFilter;
