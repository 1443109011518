import React, { useEffect, useState } from 'react';
import { Form, Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import Input from 'components/base-components/Input';
import Button from 'components/base-components/Button';
import SelectBox from 'components/base-components/SelectBox';
import DatePicker from 'components/base-components/DatePicker';
import Card from 'components/base-components/Card';
import CheckBox from 'components/base-components/CheckBox';
import useCustomNavigate from 'hooks/useCustomNavigate';
import TextEditor from 'components/base-components/TextEditor';
import { Title } from 'components/base-components/Typography';
import { useCreateTaskMutation, useGetSingleTaskQuery, useUpdateTaskMutation } from 'api/tasks';
import { useParams } from 'react-router';
import SubmitModal from 'components/shared-components/modal/submit-modal';
import { useGetUsersQuery } from 'api/users';
import { useGetOrganizationsQuery } from 'api/organizations';
import { debounce } from 'throttle-debounce';
import useNavigationLinks from 'hooks/useNavigationLinks';
import { removeEmptyFromObject, toSnakecaseKeys } from 'utils/object-cleanup';
import { removeMarkdownLink } from 'utils/markdown-converter';
import { Link } from 'react-router-dom';
import { ORGANISATIONS_STATUS, USERS_STATUS } from '../../utils/statuses';
import { isAutomatedTask } from './task-helper';

const CreateUpdateTask = () => {
  const [isDueDateOpen, setIsDueDateOpen] = useState(false);
  const [assigneeQuery, setAssigneeQuery] = useState(null);
  const [selectedAssigneeType, setSelectedAssigneeType] = useState('individual');
  const [
    createTask,
    { isLoading: isCreateLoading, isSuccess: isCreateSuccess, requestId: createRequestId },
  ] = useCreateTaskMutation();

  // TODO: setRecipientOption state will be handled in the story: https://welltravel.atlassian.net/browse/BIOS-179

  const [recipientOption, setRecipientOption] = useState(null);
  const [
    updateTask,
    { isLoading: isUpdateLoading, isSuccess: isUpdateSuccess, requestId: updateRequestId },
  ] = useUpdateTaskMutation();
  const { id } = useParams();
  const { t, i18n } = useTranslation();
  const navigate = useCustomNavigate();
  const navigationLinks = useNavigationLinks();

  const { data: task } = useGetSingleTaskQuery(id);

  const assignedUserIds = 'assigned_user_ids';
  const assignedOrganizationId = 'assigned_organization_id';
  const biosuisse = 'biosuisse';

  let initialTitleValue = task?.title;
  let initialDescriptionValue = task?.description;
  if (isAutomatedTask(task)) {
    initialTitleValue = removeMarkdownLink(task?.title);
    initialDescriptionValue = removeMarkdownLink(task?.description);
  }

  useEffect(() => {
    if (task?.assignedOrganizationId) {
      setSelectedAssigneeType('organisation');
    } else if (task?.assigned_organization_id?.length > 0) {
      setSelectedAssigneeType('individual');
    } else if (id) {
      setSelectedAssigneeType('bio-suisse');
    }
  }, [task]);

  const userSearchParams = {
    q: assigneeQuery,
    status: USERS_STATUS.active,
  };

  const organizationSearchParams = {
    q: assigneeQuery,
    status: ORGANISATIONS_STATUS.active,
  };

  const { data: organisationsResponse } = useGetOrganizationsQuery(organizationSearchParams);
  const organisations = organisationsResponse ? organisationsResponse.organizations : [];

  const { data: usersCollection } = useGetUsersQuery(userSearchParams);
  const users = usersCollection ? usersCollection.users : [];

  const bioSuisseOptions = [
    {
      label: t('tasks.assignee.bio_suisse'),
      value: true,
    },
  ];

  let assigneeOptions;

  const getAssignee = () => {
    if (selectedAssigneeType === 'individual') {
      assigneeOptions = users.map(user => {
        return { label: user.name, value: user.id };
      });
      return assigneeOptions;
    } else if (selectedAssigneeType === 'organisation') {
      assigneeOptions = organisations.map(organisation => {
        return { label: organisation?.name, value: organisation?.id };
      });
      return assigneeOptions;
    } else if (selectedAssigneeType === 'bio-suisse') {
      assigneeOptions = bioSuisseOptions;
      return assigneeOptions;
    }
  };

  const priorityOptions = [
    {
      label: t('tasks.priority.low'),
      value: 'low',
    },
    {
      label: t('tasks.priority.medium'),
      value: 'medium',
    },
    {
      label: t('tasks.priority.high'),
      value: 'high',
    },
  ];

  const assignCheckOptions = [
    {
      name: t('tasks.assignee.individual'),
      value: 'individual',
    },
    {
      name: t('tasks.assignee.organisation'),
      value: 'organisation',
    },
    {
      name: t('tasks.assignee.bio_suisse'),
      value: 'bio-suisse',
    },
  ];

  const assigneeFieldNames = [assignedUserIds, assignedOrganizationId, biosuisse];

  const onAssigneeInputChange = (assignee, form) => {
    const assigneeName = getAssigneeFieldName();

    setRecipientOption(assignee);

    assigneeFieldNames.forEach(field => {
      if (field !== assigneeName) {
        form.change(field, undefined);
      }
    });

    switch (assigneeName) {
      case assignedUserIds:
        form.change(assigneeName, [assignee.value]);
        break;
      case assignedOrganizationId:
        form.change(assigneeName, assignee.value);
        break;
      case biosuisse:
        form.change(assigneeName, true);
        break;
      default:
        return null;
    }
  };

  const onSubmit = values => {
    const payload = removeEmptyFromObject(values);
    if (id) {
      return updateTask({ id: task.id, payload })
        .unwrap()
        .catch(({ data: { errors } }) => toSnakecaseKeys(errors));
    } else {
      return createTask(payload)
        .unwrap()
        .catch(({ data: { errors } }) => toSnakecaseKeys(errors));
    }
  };

  const onSearchAssignee = debounce(500, value => {
    setAssigneeQuery(value);
  });

  const getAssigneeFieldName = () => {
    if (selectedAssigneeType === 'bio-suisse') {
      return biosuisse;
    } else if (selectedAssigneeType === 'individual') {
      return assignedUserIds;
    } else if (selectedAssigneeType === 'organisation') {
      return assignedOrganizationId;
    }
  };

  const getAssigneeInitialValue = () => {
    switch (selectedAssigneeType) {
      case 'bio-suisse':
        return true;
      case 'organisation':
        return task?.assignedOrganizationId;
      case 'organisation_members':
        return task?.assignedUserIds?.[0];
      default:
        return null;
    }
  };

  const updateRecipientType = (value, form) => {
    assigneeFieldNames.forEach(field => {
      form.change(field, undefined);
    });

    setSelectedAssigneeType(value);
    setRecipientOption(value === 'bio-suisse' ? bioSuisseOptions[0] : null);
  };

  return (
    <div className="grid">
      <SubmitModal
        isLoading={isCreateLoading}
        isSuccess={isCreateSuccess}
        requestId={createRequestId}
        loaderContent={t('tasks.modal.create.loader_message')}
        successTitle={t('tasks.modal.create.success.title')}
        successContent={t('tasks.modal.create.success.content')}
        onSuccess={() => navigate.openTasksListPage()}
        showError={false}
      />
      <SubmitModal
        isLoading={isUpdateLoading}
        isSuccess={isUpdateSuccess}
        requestId={updateRequestId}
        loaderContent={t('tasks.modal.update.loader_message')}
        successTitle={t('tasks.modal.update.success.title')}
        successContent={t('tasks.modal.update.success.content')}
        onSuccess={() => navigate.openTasksListPage()}
        showError={false}
      />
      <Card className="task__create-card col-6">
        <Title className="task__create--form__header">
          {id ? t('tasks.edit_form.header') : t('tasks.create_form.header')}
        </Title>
        <Form
          onSubmit={onSubmit}
          render={({ handleSubmit, submitting, form }) => (
            <form onSubmit={handleSubmit}>
              <div className="col-12 col-bleed-x task__create-card--box">
                <Field name="title" initialValue={initialTitleValue}>
                  {({ input, meta }) => (
                    <div>
                      <Input
                        size="tiny"
                        label={t('tasks.create_form.title')}
                        required={true}
                        disabled={isAutomatedTask(task) || id}
                        touched={!meta.dirtySinceLastSubmit}
                        error={meta.submitError}
                        placeholder={t('tasks.create_form.title_placeholder')}
                        {...input}
                      />
                    </div>
                  )}
                </Field>
              </div>
              <div className="col-12 col-bleed-x task__create-card--box">
                <Field name="description" initialValue={initialDescriptionValue}>
                  {({ input }) => (
                    <div className="task__create--form__wrapper">
                      <TextEditor
                        label={t('tasks.create_form.description')}
                        placeholder={t('tasks.create_form.description_placeholder')}
                        disabled={isAutomatedTask(task)}
                        {...input}
                      />
                    </div>
                  )}
                </Field>
              </div>
              <div className="task__create--form col-12 col-bleed-x task__create-card--box">
                <div className="task__create--form__wrapper">
                  <div className="task__create--form__wrapper-label">
                    {t('tasks.create_form.assignee')}
                  </div>
                  <div className="task__create--form__checkbox">
                    {assignCheckOptions.map((option, index) => (
                      <CheckBox
                        key={index}
                        label={option.name}
                        size="tiny"
                        isChecked={option.value === selectedAssigneeType}
                        onChange={() => updateRecipientType(option.value, form)}
                        disabled={id}
                      />
                    ))}
                  </div>
                </div>
              </div>
              <div className="col-12 col-bleed-x task__create-card--box">
                <Field
                  name={getAssigneeFieldName()}
                  type="select"
                  initialValue={getAssigneeInitialValue()}
                >
                  {({ input, meta }) => (
                    <div>
                      <SelectBox
                        size="tiny"
                        isDisabled={!selectedAssigneeType || id}
                        isSearchable={true}
                        onInputChange={onSearchAssignee}
                        placeholderText={t('tasks.create_form.assignee_placeholder')}
                        isClearable={false}
                        options={getAssignee()}
                        value={recipientOption}
                        selectedValue={input.label}
                        onChange={e => {
                          onAssigneeInputChange(e, form);
                        }}
                        required={true}
                        touched={true}
                        errorMsg={meta.submitError}
                      />
                    </div>
                  )}
                </Field>
              </div>
              <div className="col-6 col-bleed-x">
                <Field name="due_date" initialValue={task?.dueDate}>
                  {({ input, meta }) => (
                    <DatePicker
                      focused={isDueDateOpen}
                      required={true}
                      date={input.value}
                      onChange={e => input.onChange(e)}
                      onFocusChange={() => setIsDueDateOpen(!isDueDateOpen)}
                      displayFormat="LL"
                      label={t('tasks.create_form.due_date')}
                      placeholder={t('tasks.create_form.due_date_placeholder')}
                      navNext="arrowForwardAlt"
                      navPrev="arrowBackAlt"
                      size="tiny"
                      enablePastDates={false}
                      pastYearsCount={0}
                      touched={!meta.dirtySinceLastSubmit}
                      error={meta.submitError}
                      locale={i18n.language}
                    />
                  )}
                </Field>
              </div>
              <div className="col-6 task__create--priority">
                <Field name="priority" type="select" initialValue={task?.priority}>
                  {({ input, meta }) => (
                    <SelectBox
                      size="tiny"
                      label={t('tasks.create_form.priority')}
                      placeholderText={t('tasks.create_form.priority_placeholder')}
                      required={true}
                      isClearable={false}
                      options={priorityOptions}
                      value={priorityOptions.find(option => option.value === input.value)}
                      selectedValue={input.label}
                      onChange={e => input.onChange(e.value)}
                      touched={!meta.dirtySinceLastSubmit}
                      errorMsg={meta?.submitError}
                    />
                  )}
                </Field>
              </div>
              <div className="task__create--footer">
                <Button
                  label={id ? t('common.edit_button') : t('tasks.create_form.create_button')}
                  type="success"
                  size="small"
                  submitType="submit"
                  disabled={submitting}
                />
                <Link to={navigationLinks.tasksListPage()}>
                  <Button
                    className="task__create--footer__cancel-btn"
                    size="small"
                    label={t('tasks.create_form.cancel_button')}
                  />
                </Link>
              </div>
            </form>
          )}
        />
      </Card>
    </div>
  );
};

export default CreateUpdateTask;
