import PropTypes from 'prop-types';
import Icon from 'components/base-components/Icon';
import IconButton from 'components/base-components/IconButton';
import { t } from 'i18next';
import React, { useState } from 'react';
import {
  useDeleteRecognitionCertificateMutation,
  useGetRecognitionCertificateQuery,
  useMarkCertificateMutation,
} from 'api/recognition';
import { dateMonthYear } from 'utils/date-time-formatter';
import Button from 'components/base-components/Button';
import Pagination from 'components/base-components/Pagination/Pagination';
import Popover from 'components/base-components/Popover';
import { Link, useSearchParams } from 'react-router-dom';
import Spinner from 'components/base-components/Spinner/Spinner';
import { Field, useFormState } from 'react-final-form';
import { DELETE, SEND, SENT, UNSENT } from 'utils/recognition-status';
import ConfirmationModalWrapper from 'components/users/ConfirmationModalWrapper';
import { useParams } from 'react-router';
import useNavigationLinks from 'hooks/useNavigationLinks';
import RecognitionDetailsTable from './table.component';
import '../recognition.styles.scss';

const RecognitionDetails = ({ form }) => {
  const [openPopover, setOpenPopover] = useState(false);
  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] = useState(false);
  const [isMarkAsSentConfirmationModalOpen, setIsMarkAsSentConfirmationModalOpen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams({ page: 1 });
  const [markAsSentCertificate, { isLoading, isError }] = useMarkCertificateMutation();
  const [deleteCertificate, { isLoading: isDeleteLoading, isError: isDeleteError }] =
    useDeleteRecognitionCertificateMutation();

  const { values } = useFormState();
  const navigationLinks = useNavigationLinks();
  const { date } = useParams();

  const currentPage = searchParams.get('page');
  const currentTab = searchParams.get('tab');

  const params = {
    date: date,
    'statuses[]': [currentTab],
    page: currentPage,
    per_page: 10,
  };

  const { data: certificateCollection, isFetching } = useGetRecognitionCertificateQuery(params);

  const certificateList = certificateCollection?.collection || [];

  const totalCertificateCount = certificateCollection?.pagination?.totalCount || 0;

  const deleteModalContent = () => {
    if (isDeleteLoading) {
      return (
        <div className="user__invite--modal-loading">
          <Spinner size="small" color="success" bgColor="none" />
          <div className="user__invite--modal-loading-message">
            {t('recognition.modal.delete_loader')}
          </div>
        </div>
      );
    }

    if (isDeleteError) {
      return (
        <div className="user__invite--modal-failure">
          <Icon className="user__invite--modal-failure-icon" name="invalid" size="xxl" />
          <div className="user__invite--modal-failure-message">
            <div>{t('recognition.details.modal.delete.failure_header')}</div>
            <div>{t('recognition.details.modal.delete.failure_description')}</div>
            <div>{t('contract.modal.try_again')}</div>
          </div>
        </div>
      );
    }

    return (
      <div className="user__invite--modal-success">
        <Icon
          className="user__invite--modal-success-icon"
          name="check"
          size="large"
          showBGColor={true}
        />
        <div className="user__invite--modal-success-message">
          <div>{t('recognition.details.modal.delete.success_title')}</div>
          <div>{t('recognition.details.modal.delete.success_content')}</div>
        </div>
      </div>
    );
  };

  const sentModalContent = () => {
    if (isLoading) {
      return (
        <div className="user__invite--modal-loading">
          <Spinner size="small" color="success" bgColor="none" />
          <div className="user__invite--modal-loading-message">
            {t('recognition.modal.send_loader')}
          </div>
        </div>
      );
    }

    if (isError) {
      return (
        <div className="user__invite--modal-failure">
          <Icon className="user__invite--modal-failure-icon" name="invalid" size="xxl" />
          <div className="user__invite--modal-failure-message">
            <div>{t('recognition.details.modal.sent.failure_header')}</div>
            <div>{t('recognition.details.modal.sent.failure_description')}</div>
            <div>{t('contract.modal.try_again')}</div>
          </div>
        </div>
      );
    }

    return (
      <div className="user__invite--modal-success">
        <Icon
          className="user__invite--modal-success-icon"
          name="check"
          size="large"
          showBGColor={true}
        />
        <div className="user__invite--modal-success-message">
          <div>{t('recognition.details.modal.sent.success_title')}</div>
          <div>{t('recognition.details.modal.sent.success_content')}</div>
        </div>
      </div>
    );
  };

  const onMarkSentOrDelete = status => {
    const allCertificates = [].concat(...values?.certificates);

    const isAllItemsSelected = values?.selectAllCertificate;

    const includedIds =
      allCertificates?.length > 0 &&
      allCertificates.filter(certificate => certificate?.checked === true).map(data => data.id);

    const excludedIds = allCertificates
      .filter(certificate => certificate?.checked === false)
      .map(data => data.id);

    let sentParams = {};

    if (
      isAllItemsSelected &&
      includedIds?.length === allCertificates?.length &&
      excludedIds?.length === 0
    ) {
      sentParams['all_item_selected'] = true;
    } else if (!isAllItemsSelected && includedIds.length > 0) {
      sentParams['included_ids'] = includedIds;
    } else if (isAllItemsSelected && excludedIds.length > 0) {
      sentParams['excluded_ids'] = excludedIds;
    }

    let action;

    if (status === SEND) {
      action = markAsSentCertificate;
    } else if (status === DELETE) {
      action = deleteCertificate;
    }

    return action({ payload: sentParams })
      .then(() => form.reset())
      .then(() => setSearchParams({ page: 1, tab: currentTab }))
      .then(() => {
        status === SEND
          ? setIsMarkAsSentConfirmationModalOpen(true)
          : setIsDeleteConfirmationModalOpen(true);
      });
  };

  const handleDeleteCertificate = id => {
    const params = {
      included_ids: [id],
    };
    deleteCertificate({ payload: params })
      .then(() => setIsDeleteConfirmationModalOpen(true))
      .then(() => form.reset());
  };

  const getTotalCount = () =>
    values?.certificates?.flat().filter(certificate => certificate.checked).length;

  const selectCount = certificates => {
    if (!certificates) return t('recognition.no_selected');
    const allCertificates = values?.certificates && [].concat(...values?.certificates);
    const selectedItemsLength = allCertificates?.filter(list => list?.checked === true)?.length;

    if (values.selectAllCertificate) {
      let countedSelectedCertificate = totalCertificateCount;

      const disSelectedItemsLength = allCertificates?.filter(
        list => list?.checked === false,
      )?.length;

      return t('recognition.selected_certificate', {
        count: countedSelectedCertificate - disSelectedItemsLength,
      });
    }

    return t('recognition.selected_certificate', {
      count: selectedItemsLength,
    });
  };

  return (
    <>
      <div className="record-payment__header">
        <Link to={navigationLinks.recognitionListPage()}>
          <IconButton
            className="organisation__head-popover--go-back"
            icon={<Icon name="goBack" />}
          />
        </Link>
        <span className="invoice-header--heading">
          {t('recognition.details.title', { date: dateMonthYear(date) })}
        </span>
      </div>
      {getTotalCount() > 0 && (
        <div className="recognition__marks--wrapper">
          <Icon name="check" color="tertiary" />
          <div className="recognition__selection-text">{selectCount(values.certificates)}</div>
          <div className="recognition__vertical-line" />
          <Field name="selectAllCertificate">
            {({ input, meta }) => (
              <div
                className="recognition__selection-text recognition__select-all"
                onClick={() => input.onChange(true)}
              >
                {t('recognition.details.select_all', { count: totalCertificateCount })}
              </div>
            )}
          </Field>
          <div className="recognition__vertical-line" />
          <div
            className="recognition__selection-text recognition__clear-all"
            onClick={() => form.reset()}
          >
            {t('recognition.details.clear')}
          </div>
          <Popover
            isVisible={openPopover}
            size="medium"
            content={
              <div className="task__popover">
                {currentTab === UNSENT && (
                  <div className="task__popover-content" onClick={() => onMarkSentOrDelete(SEND)}>
                    {t('recognition.details.mark_as_sent')}
                  </div>
                )}
                {currentTab === SENT && (
                  <div className="task__popover-content" onClick={() => onMarkSentOrDelete(DELETE)}>
                    {t('recognition.details.delete_all')}
                  </div>
                )}
              </div>
            }
            direction="bottom-right"
            onOutsideClick={() => setOpenPopover(false)}
          >
            <Button
              label={t('recognition.details.bulk_actions')}
              size="small"
              onClick={() => setOpenPopover(!openPopover)}
            />
          </Popover>
        </div>
      )}
      <RecognitionDetailsTable
        certificateList={certificateList}
        currentTab={currentTab}
        isFetching={isFetching}
        currentPage={currentPage}
        handleDeleteCertificate={handleDeleteCertificate}
        form={form}
      />
      <ConfirmationModalWrapper
        isVisible={isDeleteConfirmationModalOpen}
        contentAlign="center"
        footerAlign="center"
        content={deleteModalContent()}
        className="user__invite--modal"
        timeOut={!isLoading ? 3000 : 0}
        onTimeout={() => {
          setIsDeleteConfirmationModalOpen(false);
        }}
      />
      <ConfirmationModalWrapper
        isVisible={isMarkAsSentConfirmationModalOpen}
        contentAlign="center"
        footerAlign="center"
        content={sentModalContent()}
        className="user__invite--modal"
        timeOut={!isLoading ? 3000 : 0}
        onTimeout={() => {
          setIsMarkAsSentConfirmationModalOpen(false);
        }}
      />
      <div className="user-details__assignment-list">
        <Pagination
          onPaginationClick={newPage => {
            setSearchParams({ page: newPage, tab: currentTab });
          }}
          totalPages={
            certificateCollection && parseInt(certificateCollection.pagination.totalPages, 10)
          }
          currentPage={parseInt(currentPage, 10)}
          nextText={t('pagination.next')}
          previousText={t('pagination.prev')}
          firstText={t('pagination.first')}
          lastText={t('pagination.last')}
        />
      </div>
    </>
  );
};

RecognitionDetails.propTypes = {
  form: PropTypes.node.isRequired,
};

export default RecognitionDetails;
