import {
  useDeactivateUserMutation,
  useGetCurrentUserQuery,
  useGetUserQuery,
  useReactivateUserMutation,
} from 'api/users';
import Avatar from 'components/base-components/Avatar';
import Button from 'components/base-components/Button';
import Card from 'components/base-components/Card';
import EmphasisTag from 'components/base-components/EmphasisTag';
import Icon from 'components/base-components/Icon';
import Modal from 'components/base-components/Modal';
import { Tabs } from 'components/base-components/RTabs';
import OrganizationName from 'components/organisations/organization-name';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import ASSIGNMENT_ROLES from 'utils/assignment-roles';
import { ADMIN, USER } from 'utils/users-role';
import { dateMonthYearFormatter } from 'utils/date-time-formatter';
import useNavigationLinks from 'hooks/useNavigationLinks';
import AssignmentListComponent from '../assignment/assignment-list.component';
import EducationListComponent from '../education/education-list.component';
import '../users.styles.scss';

const UserDetails = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deactivateUser] = useDeactivateUserMutation();
  const [reactivateUser] = useReactivateUserMutation();
  const navigationLinks = useNavigationLinks();
  const { t } = useTranslation();

  const { data: currentUser } = useGetCurrentUserQuery();

  const { id } = useParams();

  const { id: userId } = useParams();

  const [searchParams, setSearchParams] = useSearchParams({ tab: 'education' });
  const currentTab = searchParams.get('tab');

  const { data: user = {} } = useGetUserQuery({ id });

  const TAB_MAPPER = {
    education: 0,
    assignment: 1,
  };

  const onChangeTab = selectedTabKey => {
    const selectedTab = Object.keys(TAB_MAPPER).find(tab => TAB_MAPPER[tab] === selectedTabKey);
    setSearchParams({ tab: selectedTab });
  };

  const ownOrganizationsIDs = [];
  const assignedOrganizationsIDs = [];

  if (user.assignments && user.assignments.length > 0) {
    user.assignments.forEach(assignment => {
      if (assignment.role === ASSIGNMENT_ROLES.owner) {
        ownOrganizationsIDs.push(assignment.organizationId);
      } else if (assignment.role === ASSIGNMENT_ROLES.member) {
        assignedOrganizationsIDs.push(assignment.organizationId);
      }
    });
  }

  return (
    <div className="grid user-details">
      <div className="col-3">
        <Card className="user-details__wrapper">
          <div className="user-details__avatar">
            <Avatar
              firstName={user.firstName}
              lastName={user.lastName}
              src={user.profilePictureUrl}
              size="xxl"
            />
          </div>
          <div className="user-details__name">{user.name}</div>
          <div className="user-details__items">
            <Icon className="user-details__phone--icon" name="call" size="tiny" />
            <span className="user-details__content user-details__phone">
              {user.cellphone ? user.cellphone : t('shared.not_added')}
            </span>
            <EmphasisTag
              type={user.phoneVerified ? 'success' : 'danger'}
              size="tiny"
              text={user.phoneVerified ? t('shared.verified') : t('shared.unverified')}
            />
          </div>
          <div className="user-details__items user-details__email--wrapper">
            <Icon className="user-details__email--icon" name="email" />
            <span className="user-details__content user-details__email">
              {user.email ? user.email : t('shared.not_added')}
            </span>
            <EmphasisTag
              type={user.emailVerified ? 'success' : 'danger'}
              size="tiny"
              text={user.emailVerified ? t('shared.verified') : t('shared.unverified')}
            />
          </div>
          <div className="user-details__items user-details__dob-label--wrapper">
            <Icon className="user-details__dob-label--icon" name="calendar" />
            <span className="user-details__content user-details__dob-label">
              {t('user.birthday')}
            </span>
          </div>
          <div className="user-details__items user-details__dob--wrapper">
            <span className="user-details__content user-details__dob">
              {user.birthday ? dateMonthYearFormatter(user.birthday) : t('shared.not_added')}
            </span>
          </div>
          <div className="user-details__items user-details__language--wrapper">
            <Icon className="user-details__language--icon" name="globe" />
            <span className="user-details__content user-details__language">
              {t('user_general.prefered_language')}
            </span>
          </div>
          <div className="user-details__items user-details__language--list__wrapper">
            {user && user.language ? (
              <span className="user-details__content user-details__language--list">
                {t(`languages.${user.language}`)}
              </span>
            ) : (
              t('common.not_applicable')
            )}
          </div>
          <div className="user-details__items user-details__organisation--wrapper">
            <Icon className="user-details__organisation--icon" name="organization" />
            <span className="user-details__content user-details__organisation">
              {t('user.own_organizations')}
            </span>
          </div>
          <div className="user-details__items user-details__organisation--list__wrapper">
            <div className="user-details__content user-details__organisation--list">
              {ownOrganizationsIDs.length > 0
                ? ownOrganizationsIDs.map(organizationId => (
                    <OrganizationName
                      key={`organization-${organizationId}`}
                      organizationId={organizationId}
                    />
                  ))
                : t('shared.no_availability')}
            </div>
          </div>
          <div className="user-details__items user-details__assigned-organisation--wrapper">
            <Icon className="user-details__assigned-organisation--icon" name="organization" />
            <span className="user-details__content user-details__assigned-organisation">
              {t('user.assigned_organizations')}
            </span>
          </div>
          <div className="user-details__items user-details__assigned-organisation--list__wrapper">
            <div className="user-details__content user-details__assigned-organisation--list">
              {assignedOrganizationsIDs.length > 0
                ? assignedOrganizationsIDs.map(organizationId => (
                    <OrganizationName
                      key={`organization-${organizationId}`}
                      organizationId={organizationId}
                    />
                  ))
                : t('shared.no_availability')}
            </div>
          </div>
          <div className="user-details__items user-details__view--wrapper">
            <span
              className="user-details__content user-details__view"
              onClick={() => setSearchParams({ tab: 'assignment' })}
            >
              {t('user_details.view_assignment')}{' '}
            </span>
          </div>
        </Card>
      </div>
      <div className="col-9">
        <Card className="user-details__head">
          <div>
            <span> {t('user_details.department')} </span>
            <span className="user-details__gastronomy"> {t('user_details.department_name')} </span>
          </div>
          <div>
            {user?.status !== 'unverified' &&
              parseInt(userId) !== currentUser?.id &&
              (currentUser.superAdmin || (currentUser.role === ADMIN && user.role === USER)) && (
                <Button
                  label={
                    user?.status === 'active'
                      ? t('user_details.deactivate_user')
                      : t('user_details.activate_user')
                  }
                  size="small"
                  onClick={() => setIsModalOpen(true)}
                />
              )}
            <Link to={navigationLinks.userEditPage(userId)}>
              <Button
                className="user-details__edit"
                size="small"
                label={t('user_details.edit_user')}
                type="success"
              />
            </Link>
          </div>
        </Card>
        <Tabs
          controlled={true}
          selectedTabKey={TAB_MAPPER[currentTab]}
          items={[
            {
              title: t('user_details.tabs.education'),
              key: TAB_MAPPER.education,
              getContent: () => <EducationListComponent />,
            },
            {
              title: t('user_details.tabs.assignment'),
              key: TAB_MAPPER.assignment,
              getContent: () => <AssignmentListComponent />,
            },
          ]}
          onChange={onChangeTab}
        />
      </div>
      <Modal
        className="user-details__modal"
        isVisible={isModalOpen}
        onOutsideClick={() => setIsModalOpen(false)}
      >
        <Modal.Title className="user-details__modal--title">
          <Icon name="warning" showBGColor={true} size="medium" color="warning" />
          <div className="user-details__modal--title__name">
            {user?.status === 'active'
              ? t('user_details.deactivate_user_modal.title')
              : t('user_details.activate_user_modal.title')}
          </div>
        </Modal.Title>
        <Modal.Content className="user-details__modal--content">
          {user?.status === 'active'
            ? t('user_details.deactivate_user_modal.description', { name: user?.name })
            : t('user_details.activate_user_modal.description', { name: user?.name })}
        </Modal.Content>
        <Modal.Footer>
          <Button
            label={t('user_details.deactivate_user_modal.confirm')}
            type="success"
            onClick={() => {
              if (user?.status === 'active') {
                deactivateUser(userId);
              } else {
                reactivateUser(userId);
              }
              setIsModalOpen(false);
            }}
          />
          <Button
            label={t('user_details.deactivate_user_modal.cancel_button')}
            onClick={() => setIsModalOpen(false)}
          />
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default UserDetails;
