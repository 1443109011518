import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Icon from 'components/base-components/Icon';
import IconButton from 'components/base-components/IconButton/IconButton';
import TableLoaderPlaceholder from 'components/shared-components/placeholders/table-loader-placeholder';
import EmptyContentPlaceholder from 'components/shared-components/placeholders/empty-content-placeholder-component';
import {
  Table,
  TableHeader,
  TableHead,
  TableRow,
  TableData,
  TableBody,
} from 'components/base-components/Table';
import { dateMonthYear, twentyFourHourTimeFormatOnlyTimeInput } from 'utils/date-time-formatter';
import { UNSENT } from 'utils/recognition-status';
import useNavigationLinks from 'hooks/useNavigationLinks';
import { Link } from 'react-router-dom';

const RecognitionTableRow = ({ recognition, currentTab }) => {
  const { t } = useTranslation();
  const navigationLinks = useNavigationLinks();

  return (
    <TableRow className="organisation__table-row">
      <TableData>{dateMonthYear(recognition.createDate) || t('common.not_applicable')}</TableData>
      <TableData>{recognition.numberOfCertificates || t('common.not_applicable')}</TableData>
      {
        <TableData>
          {currentTab === UNSENT
            ? twentyFourHourTimeFormatOnlyTimeInput(recognition.generationTime)
            : twentyFourHourTimeFormatOnlyTimeInput(recognition.sentTime)}
        </TableData>
      }
      <TableData align="right">
        <Link to={navigationLinks.recognitionDetailsPage(recognition.createDate, currentTab)}>
          <IconButton
            className="organisation__action-show"
            icon={<Icon name="show" size="small" />}
            size="tiny"
            color="tertiary"
          />
        </Link>
      </TableData>
    </TableRow>
  );
};

RecognitionTableRow.defaultProps = {
  recognition: null,
  currentTab: UNSENT,
};

RecognitionTableRow.propTypes = {
  recognition: PropTypes.shape(),
  currentTab: PropTypes.string,
};

const RecognitionTable = ({ recognitionList, isFetching, currentTab }) => {
  const { t } = useTranslation();

  const RecignitionTableHeader = () => (
    <TableHead>
      <TableRow>
        <TableHeader>{t('recognition.table.date')}</TableHeader>
        <TableHeader>{t('recognition.table.new_certificate_number')}</TableHeader>
        <TableHeader>
          {currentTab === 'unsent'
            ? t('recognition.table.generate_time')
            : t('recognition.table.sent_time')}
        </TableHeader>
        <TableHeader align="right">{t('recognition.table.actions')}</TableHeader>
      </TableRow>
    </TableHead>
  );

  return (
    <>
      {!isFetching && recognitionList.length === 0 ? (
        <EmptyContentPlaceholder iconName="certificate" text={t('recognition.no_recognition')} />
      ) : (
        <Table className="organisation_table">
          <RecignitionTableHeader />
          <TableBody>
            {isFetching ? (
              <TableLoaderPlaceholder numberOfRows={10} />
            ) : (
              recognitionList.map(recognition => (
                <RecognitionTableRow recognition={recognition} currentTab={currentTab} />
              ))
            )}
          </TableBody>
        </Table>
      )}
    </>
  );
};

RecognitionTable.defaultProps = {
  recognitionList: [],
  isFetching: false,
  currentTab: 'unsent',
};

RecognitionTable.propTypes = {
  recognitionList: PropTypes.arrayOf(PropTypes.shape()),
  isFetching: PropTypes.bool,
  currentTab: PropTypes.string,
};

export default RecognitionTable;
