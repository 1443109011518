import React from 'react';
import routes from 'utils/routes';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import Link from 'components/base-components/Link';

const DownloadPdf = ({ invoiceId, type }) => {
  const url = routes.invoice.entity({ invoiceId, format: 'pdf' });

  return (
    <Link
      href={`${process.env.REACT_APP_BACKEND_URL}/api/v1${url}`}
      download
      type={type}
      size="small"
      modifier="default"
    >
      <span className="invoice__list--download">{t('invoice.download')}</span>
    </Link>
  );
};

DownloadPdf.defaultProps = {
  onFinished: () => {},
};

DownloadPdf.propTypes = {
  invoiceId: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
};

export default DownloadPdf;
