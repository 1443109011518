import classNames from 'classnames';
import PropTypes from 'prop-types';
import Icon from 'components/base-components/Icon';
import IconButton from 'components/base-components/IconButton';
import Tooltip from 'components/base-components/Tooltip';
import { t } from 'i18next';
import React from 'react';
import RadioboxWithIcon from '../shared/radiobox-with-icon.component';
import './legal-form.styles.scss';

const CompanyType = ({ initialValue }) => {
  const companyTypesData = [
    {
      label: t('legal_form.company_type_options.main_company'),
      name: 'main',
      icon: 'cow',
    },
    {
      label: t('legal_form.company_type_options.alpine_summer_farm'),
      name: 'alpine_summer',
      icon: 'cows',
    },
    {
      label: t('legal_form.company_type_options.all_options'),
      name: 'main_and_alpine_summer',
      icon: 'cowWithMountain',
    },
  ];

  return (
    <div>
      <div className="legal-form__company--title">
        <span>{t('legal_form.company_type')}</span>
        <span className="organisation__form-modal--uid-position__icon">
          <Tooltip
            content={t('legal_form.company_type_hint')}
            type="inverse"
            position="right-top"
            size="medium"
            showArrow={true}
          >
            <IconButton
              icon={<Icon name="info" size="tiny" />}
              color="default"
              size="tiny"
              isIconOnly={true}
            />
          </Tooltip>
        </span>
      </div>
      <div className="grid col-12 col-bleed">
        {companyTypesData.map(({ name, label, icon }, index) => (
          <div
            className={classNames({
              'col-6 col-bleed-x': index !== companyTypesData.length - 1,
              'legal-form__input-field': index % 2 !== 0 && index !== companyTypesData.length - 1,
            })}
          >
            <RadioboxWithIcon
              label={label}
              fieldName="companyType"
              value={name}
              icon={icon}
              initialValue={initialValue}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

CompanyType.propTypes = {
  initialValue: PropTypes.string.isRequired,
};

export default CompanyType;
