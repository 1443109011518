import classNames from 'classnames';
import Card from 'components/base-components/Card';
import CheckBox from 'components/base-components/CheckBox/CheckBox';
import Icon from 'components/base-components/Icon';
import PropTypes from 'prop-types';
import React from 'react';
import { Field, useFormState } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import FruitCheckboxInput from './fruit-checkbox-input.component';

const FruitFieldComponent = ({ mainFieldName, isVisible, data, label, initialValue }) => {
  const getInitialValue = name => {
    return initialValue.find(
      value => value.fruitType === name && value.plantType === mainFieldName,
    );
  };

  const { values } = useFormState();

  const isMainFieldSelected =
    initialValue && initialValue.some(value => value.plantType === mainFieldName);

  return (
    <Card
      className={classNames('plant-cultivation__method--card', {
        'plant-cultivation__method--card__selected': isMainFieldSelected || values[mainFieldName],
      })}
    >
      <Field name={mainFieldName} type="checkbox" initialValue={isMainFieldSelected}>
        {({ input }) => (
          <CheckBox
            className={classNames('plant-cultivation__method--fruit-main-checkbox', {
              'plant-cultivation__method--fruit-main-checkbox__deselected': !input.checked,
            })}
            isChecked={input.checked}
            size="tiny"
            label={
              <div className="plant-cultivation__method--header-wrapper">
                {label}
                <Icon size="tiny" name={input.checked ? 'upArrow' : 'downArrow'} />
              </div>
            }
            onChange={e => input.onChange(e.target.checked)}
          />
        )}
      </Field>
      {isVisible && (
        <>
          <FieldArray name="fruitData">
            {() => (
              <div className="plant-cultivation__method--open-fruit--wrapper">
                {data.map(({ name, label }, index) => (
                  <div key={index}>
                    <FruitCheckboxInput
                      name={name}
                      mainFieldName={mainFieldName}
                      fieldName={`fruitData.[${mainFieldName}][${index}]`}
                      label={label}
                      index={index}
                      initialValue={getInitialValue(name)}
                    />
                  </div>
                ))}
              </div>
            )}
          </FieldArray>
        </>
      )}
    </Card>
  );
};

FruitFieldComponent.defaultProps = {
  title: null,
  label: null,
  mainFieldName: null,
  isVisible: false,
  data: [],
  initialValue: [],
};

FruitFieldComponent.propTypes = {
  title: PropTypes.string,
  label: PropTypes.string,
  mainFieldName: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.shape()),
  isVisible: PropTypes.bool,
  initialValue: PropTypes.arrayOf(PropTypes.shape()),
};

export default FruitFieldComponent;
