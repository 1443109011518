import { SUCCESS, WARNING } from 'utils/status';
import { GASTRONOMY, PRODUCER } from 'utils/organisation-industries';

const handleMoveToNextStep = (
  validationError = {},
  activeIndex,
  setActiveIndex,
  setNextStepDisabled,
  setStepStates,
  stepStates,
  department,
) => {
  const {
    registeredName,
    secondSpokenLanguage,
    assignments,
    partner,
    mainAddress,
    mailingAddress,
    agriculturalLandInHectare,
    greenlandInHectare,
    openCultivatedLandInHectare,
    productionStartingYear,
    operationManager,
    mainContact,
    tvdStickerNumbers,
    type,
  } = validationError;
  let steps = [...stepStates];
  steps[activeIndex] = SUCCESS;

  const index = activeIndex + 1;
  const hasValidationErrors = Object.keys(validationError).length !== 0;

  let legalTabMessage;

  switch (department) {
    case PRODUCER:
      legalTabMessage = {
        errorStepCondition: assignments || partner || operationManager || mainContact,
        moveNextCondition: !assignments && !partner && !operationManager && !mainContact,
      };
      break;
    case GASTRONOMY:
      legalTabMessage = {
        errorStepCondition: type,
        moveNextCondition: !type,
      };
      break;
    default:
      legalTabMessage = { errorStepCondition: false, moveNextCondition: true };
  }

  const stepConditions = {
    0: {
      errorStepCondition: registeredName || secondSpokenLanguage,
      moveNextCondition:
        stepStates[0] === SUCCESS ||
        (hasValidationErrors && !registeredName && !secondSpokenLanguage),
    },
    1: legalTabMessage,
    2: {
      errorStepCondition: mainAddress || mailingAddress,
      moveNextCondition: !mainAddress && !mailingAddress,
      disableNext: true,
    },
    4: {
      errorStepCondition:
        agriculturalLandInHectare ||
        greenlandInHectare ||
        openCultivatedLandInHectare ||
        productionStartingYear,
      moveNextCondition:
        !agriculturalLandInHectare &&
        !greenlandInHectare &&
        !openCultivatedLandInHectare &&
        !productionStartingYear,
    },
    6: {
      errorStepCondition: tvdStickerNumbers,
      moveNextCondition: !tvdStickerNumbers,
    },
  };

  const step = stepConditions[activeIndex];

  if (step) {
    if (step.errorStepCondition) {
      steps[activeIndex] = WARNING;

      if (step.disableNext) {
        setNextStepDisabled(true);
      }
    } else if (step.moveNextCondition) {
      setActiveIndex(index);
    }
  } else {
    setActiveIndex(index);
  }

  setStepStates(steps);
};

export default handleMoveToNextStep;
