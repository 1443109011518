import { Text } from '@react-pdf/renderer';
import { Break } from 'components/pdf/shared/shared-components';
import React from 'react';

const addressFormatter = value => {
  if (!value) return;

  return (
    <>
      {value} <Break />
    </>
  );
};

const organizationMainAddress = organization => {
  const address = organization.addresses.find(address => address.main === true);

  if (address) {
    return (
      <Text>
        {addressFormatter(address.line1)}
        {addressFormatter(address.line2)}
        {address.zipCode && `${address.zipCode} `}
        {addressFormatter(address.city)}
        {address.country && address.country?.name}
      </Text>
    );
  }
};

export default organizationMainAddress;
