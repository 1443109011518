import React from 'react';
import { useTranslation } from 'react-i18next';
import Card from 'components/base-components/Card';
import CredentialForm from './credential-form-component';

const CredentialEditComponent = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="user__form--title">{t('user_edit.credential_tab')}</div>
      <Card className="user__credential-form--card">
        <CredentialForm />
      </Card>
    </>
  );
};

export default CredentialEditComponent;
