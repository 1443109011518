import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Card from 'components/base-components/Card';
import ContentLoaderPlaceholder from 'components/base-components/ContentLoader/';
import { useGetControlBodySurveysQuery, useGetScheduleQuery } from 'api/control-body-surveys';
import { Header } from 'components/base-components/Typography';
import EmptyContentPlaceholder from 'components/shared-components/placeholders/empty-content-placeholder-component';
import { dateMonthYear, twelveHourTimeFormat } from 'utils/date-time-formatter';
import OrganizationBasic from './organisation-basic.component';

const AssociatedSchedule = ({ scheduleId }) => {
  const { t } = useTranslation();
  const { data: schedule = {} } = useGetScheduleQuery({ scheduleId });

  return (
    <div className="dashboard__upcoming-control-schedule">
      <span>
        {t('shared.date')}
        <span className="dashboard--boldest-font">{dateMonthYear(schedule.startsAt)}</span>
      </span>
      <span>
        {t('shared.time')}
        <span className="dashboard--boldest-font">{twelveHourTimeFormat(schedule.startsAt)}</span>
      </span>
    </div>
  );
};

AssociatedSchedule.propTypes = {
  scheduleId: PropTypes.number.isRequired,
};

const UpcomingControls = () => {
  const { t } = useTranslation();

  const searchParams = {
    upcoming: true,
    page: 1,
    per_page: 5,
    column: 'starts_at',
    direction: 'up',
  };

  const {
    data: controlBodySurveysRepose,
    isSuccess,
    isFetching,
  } = useGetControlBodySurveysQuery(searchParams);
  const controlBodySurveys = (isSuccess && controlBodySurveysRepose.collection) || [];

  return (
    <div className="dashboard__container-item">
      <Card>
        <Header level={6} weight="bold">
          {t('dashboard.upcoming_controls')}
        </Header>
        {controlBodySurveys.map(survey => (
          <Card className="dashboard__upcoming-control" key={`survye-${survey.id}`}>
            <AssociatedSchedule scheduleId={survey.confirmedScheduleId} />
            <OrganizationBasic organizationId={survey.organizationId} />
          </Card>
        ))}
        {isFetching && (
          <ContentLoaderPlaceholder numberOfLines={10} showBackground={false} isRounded={true} />
        )}
        {!isFetching && controlBodySurveys.length === 0 && (
          <EmptyContentPlaceholder
            text={t('dashboard.no_upcoming_controls')}
            iconName="tasks"
            showCard={false}
          />
        )}
      </Card>
    </div>
  );
};

export default UpcomingControls;
