import { useGetOrganizationQuery } from 'api/organizations';
import DefaultSpinner from 'components/shared-components/default-spinner';
import React from 'react';
import { useParams } from 'react-router-dom';
import { PRODUCER } from 'utils/organisation-industries';
import ContractComponent from './bio-cuisine/contract-component';
import ProducerContractComponent from './producer/producer-contract.component';

const Contract = () => {
  const { id } = useParams();
  const { data: organization, isLoading } = useGetOrganizationQuery(id);

  if (isLoading) return <DefaultSpinner />;

  return organization.industry === PRODUCER ? <ProducerContractComponent /> : <ContractComponent />;
};

export default Contract;
