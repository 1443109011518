import { BlobProvider } from '@react-pdf/renderer';
import { useGetOrganizationQuery } from 'api/organizations';
import Card from 'components/base-components/Card';
import Icon from 'components/base-components/Icon';
import Link from 'components/base-components/Link';
import Spinner from 'components/base-components/Spinner';
import ProducerContractPDF from 'components/pdf/producer-contract/producer-contract.document';
import { t } from 'i18next';
import React from 'react';
import { useParams } from 'react-router-dom';
import bytesToMegabytes from 'utils/bytes-to-megabytes';
import ContractVerification from '../shared/shared-verification.component';

const PDF_FILENAME = 'producer-contract.pdf';

// TODO: https://welltravel.atlassian.net/browse/BIOS-626

const ProducerContractComponent = () => {
  const { id } = useParams();
  const { data: organisation = {} } = useGetOrganizationQuery(id);

  return (
    <>
      <div className="grid col-12 col-bleed-x">
        <Card className="organisation-contract__step-one">
          <div className="organisation-contract__first-step">Step 1: Download Contract</div>
          <div className="organisation-contract__eligibility">
            To be eligible for the Bio Cuisine program, every organisation has to agree to and sign
            the following contract.
          </div>
          <div className="organisation-contract__eligibility">
            Please download this PDF file and review it throughly. After reviewing, you have to put
            a signature and prepare for the next step.
          </div>
          <BlobProvider document={<ProducerContractPDF addresses={organisation.addresses} />}>
            {({ blob, url, loading, error }) => {
              if (loading) return <Spinner bgColor="none" color="success" size="tiny" />;
              const fileSize = blob ? blob.size : 0;
              return (
                <div className="organisation-contract__download">
                  <div className="organisation-contract__download-file">
                    <div>{PDF_FILENAME}</div>
                    <div className="organisation-contract__download-file--seperator" />
                    <div>{bytesToMegabytes(fileSize)} MB</div>
                  </div>
                  <div className="choose-file__contract-footer">
                    <Link
                      download={PDF_FILENAME}
                      type="button"
                      size="small"
                      href={url}
                      modifier="default"
                    >
                      <Icon
                        className="organisation-contract__download-file--icon"
                        name="downloadFile"
                        color="tertiary"
                        size="medium"
                      />
                      <span className="choose-file__contract-delete--text">
                        {t('contract.download_button')}
                      </span>
                    </Link>
                  </div>
                </div>
              );
            }}
          </BlobProvider>
        </Card>
      </div>
      <div className="grid col-12 col-bleed">
        <ContractVerification title={t('contract.second_step_producer')} />
      </div>
    </>
  );
};

export default ProducerContractComponent;
