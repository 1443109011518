import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'components/base-components/Button';
import { useGetOrganizationsQuery } from 'api/organizations';
import { Tabs } from 'components/base-components/RTabs';
import '../organisations.styles.scss';
import { Link, useSearchParams } from 'react-router-dom';
import { useGetCurrentUserQuery } from 'api/users';
import isBioSuisseAdmin from 'utils/is-bio-suisse-admin';
import isControlBodyMember from 'utils/is-control-body-member';
import useNavigationLinks from 'hooks/useNavigationLinks';
import { ORGANISATIONS_STATUS } from 'utils/statuses';
import Pagination from '../../base-components/Pagination';
import TabContent from './tab-content.component';

const OrganisationsList = () => {
  const { data: currentUser = null } = useGetCurrentUserQuery();
  const isControlBodyUser = isControlBodyMember(currentUser);
  const [searchParams, setSearchParams] = useSearchParams({
    tab: isControlBodyUser ? ORGANISATIONS_STATUS.verified : ORGANISATIONS_STATUS.active,
    q: '',
    page: 1,
    department: [],
    stars: [],
    organizationIds: [],
    minRangeOfUsers: [],
    maxRangeOfUsers: [],
  });
  const { t } = useTranslation();
  const navigationLinks = useNavigationLinks();
  const currentTab = searchParams.get('tab');
  const currentPage = parseInt(searchParams.get('page'));
  const searchQuery = searchParams.get('q');
  const department = searchParams.getAll('department');
  const stars = searchParams.getAll('stars');
  const organizationIds = searchParams.getAll('organizationIds');
  const noRating = searchParams.get('noRating');
  const minimumUsers = searchParams.get('minimumUsers');
  const minRangeOfUsers = searchParams.getAll('minRangeOfUsers');
  const maxRangeOfUsers = searchParams.getAll('maxRangeOfUsers');

  const organizationParams = {
    q: searchQuery,
    status: currentTab,
    page: currentPage,
    no_rating: noRating,
    minimum_users: minimumUsers,
    'department[]': department,
    'star_count[]': stars,
    'organization_ids[]': organizationIds,
    range_of_users: minRangeOfUsers.map((min, index) => ({ min, max: maxRangeOfUsers[index] })),
  };

  const { isFetching, data } = useGetOrganizationsQuery(organizationParams);
  const organizations = data ? data.organizations : [];
  const isAdmin = isBioSuisseAdmin(currentUser);

  const setSearchQueryParams = useCallback(
    params => {
      const validParams = {};

      Object.entries(params).forEach(([key, value]) => {
        if (value) validParams[key] = params[key];
      });

      setSearchParams(validParams);
    },
    [currentTab],
  );

  const CONTROL_BODY_TABS = {
    verified: 0,
    unverified: 1,
  };

  const NORMAL_ORGANIZATION_TABS = {
    active: 0,
    inactive: 1,
    draft: 2,
  };

  const TAB_MAPPER = isControlBodyUser ? CONTROL_BODY_TABS : NORMAL_ORGANIZATION_TABS;

  const onChangeParams = (params = {}) => {
    const allParams = {
      q: searchQuery,
      tab: currentTab,
      page: currentPage,
      stars,
      department,
      organizationIds,
      noRating,
      minimumUsers,
      minRangeOfUsers,
      maxRangeOfUsers,
    };
    setSearchQueryParams({ ...allParams, ...params });
  };

  const onChangeTab = selectedTabKey => {
    const selectedTab = Object.keys(TAB_MAPPER).find(tab => TAB_MAPPER[tab] === selectedTabKey);
    onChangeParams({ page: 1, tab: selectedTab });
  };

  // TODO: Needs to remove this one
  if (!currentUser) {
    return;
  }

  return (
    <>
      {isAdmin && (
        <div className="organisation__list">
          {isBioSuisseAdmin(currentUser) && (
            <div className="organisation__create-button">
              <Link to={navigationLinks.organisationsCreatePage()}>
                <Button type="success" size="small" label={t('organisation.create_organisation')} />
              </Link>
            </div>
          )}
        </div>
      )}

      <div className="organisation__rtabs">
        <Tabs
          controlled={true}
          selectedTabKey={TAB_MAPPER[currentTab]}
          items={Object.keys(TAB_MAPPER).map(tab => ({
            title: t(`organisation.list.tab.${tab}`),
            key: tab,
            getContent: () => (
              <TabContent
                tab={tab}
                key={tab}
                searchQuery={searchQuery}
                organizations={organizations}
                onChangeParams={onChangeParams}
                isLoading={isFetching}
              />
            ),
          }))}
          onChange={onChangeTab}
        />
      </div>
      <div className="organisation__pagination">
        <Pagination
          onPaginationClick={newPage => {
            onChangeParams({ tab: currentTab, q: searchQuery, page: newPage });
          }}
          totalPages={data && parseInt(data.pagination.totalPages, 10)}
          currentPage={currentPage}
          firstText="First"
          lastText="Last"
          nextText="Next"
          previousText="Prev"
        />
      </div>
    </>
  );
};

export default OrganisationsList;
