import Card from 'components/base-components/Card';
import React from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';

const AddressOverview = ({ address, type }) => (
  <Card>
    <div className="col-12 organisation-overview__content-header">{type}</div>
    <div className="col-6">
      <div className="organisation-overview__address-header">
        {t('organisation_view.overview.address_header.address_line')}
      </div>
      <div className="organisation-overview__address-data text-ellipsis">
        {address?.line1 || t('common.not_applicable')}
      </div>
    </div>
    <div className="col-6">
      <div className="organisation-overview__address-header">
        {t('organisation_view.overview.address_header.address_suffix')}
      </div>
      <div className="organisation-overview__address-data text-ellipsis">
        {address?.line2 || t('common.not_applicable')}
      </div>
    </div>
    <div className="col-3">
      <div className="organisation-overview__address-header">{t('shared.label.address.city')}</div>
      <div className="organisation-overview__address-data text-ellipsis">
        {address?.city || t('common.not_applicable')}
      </div>
    </div>
    <div className="col-3">
      <div className="organisation-overview__address-header">
        {t('control_body.input_field.zip')}
      </div>
      <div className="organisation-overview__address-data">
        {address?.zipCode || t('common.not_applicable')}
      </div>
    </div>
    <div className="col-3">
      <div className="organisation-overview__address-header">
        {t('organisation_view.overview.address_header.canton')}
      </div>
      <div className="organisation-overview__address-data text-ellipsis">
        {address?.canton || t('common.not_applicable')}
      </div>
    </div>
    <div className="col-2">
      <div className="organisation-overview__address-header">
        {t('shared.label.address.country')}
      </div>
      <div className="organisation-overview__address-data text-ellipsis">
        {address?.country?.name || t('common.not_applicable')}
      </div>
    </div>
  </Card>
);

AddressOverview.defaultProps = {
  address: {},
  type: null,
};

AddressOverview.propTypes = {
  address: PropTypes.shape({
    line1: PropTypes.node,
    line2: PropTypes.node,
    city: PropTypes.node,
    zipCode: PropTypes.node,
    canton: PropTypes.node,
    country: PropTypes.shape({
      name: PropTypes.node,
      code: PropTypes.node,
    }),
  }),
  type: PropTypes.node,
};

export default AddressOverview;
