import React from 'react';
import { Document, Page, View, Text, Image } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import BioLogo from 'assets/bio_logo_large.png';
import { useLanguageContext } from 'contexts/languageContext';
import { STATUS_UK } from 'utils/organization-certificate-status.js';
import PdfFooter from 'components/pdf/footer/pdf-footer.component.jsx';
import { RecognitionSignatureForm } from '../../recognition/content.view.jsx';
import { Break, ListItem } from '../../shared/shared-components';
import certificateStyles from '../../styles/anerkennung/certificate';
import organizationMainAddress from '../helpers/organization-address.js';

const CertificationPDF = ({ organization, status, generationDate, user }) => {
  const { t } = useLanguageContext();
  return (
    <Document>
      <Page size="A4" style={certificateStyles.page}>
        <View style={certificateStyles.body}>
          <Image style={certificateStyles.headerLogo} src={BioLogo} />
          <Text style={certificateStyles.title}>
            {t(`pdf.anerkennung.certificate.${status}.title`)}
          </Text>

          <View style={certificateStyles.mainContact}>
            <Text style={certificateStyles.user}>{t(user?.salutation)}</Text>
            <Text style={certificateStyles.user}>
              {user?.firstName} {user?.lastName}
            </Text>
          </View>
          <Text style={certificateStyles.address}>
            {organizationMainAddress(organization)}
            <Break count={2} />
          </Text>

          <Text style={certificateStyles.subTitle}>
            {t('pdf.recognition.company_number', {
              companyNumber: organization?.debitNumber,
            })}
          </Text>

          <Text>{t(`pdf.anerkennung.certificate.${status}.description`)}</Text>

          <ListItem bulletPoint={true}>{t('pdf.anerkennung.certificate.list.item_1')}</ListItem>
          <ListItem bulletPoint={true}>{t('pdf.anerkennung.certificate.list.item_2')}</ListItem>
          <ListItem bulletPoint={true}>{t('pdf.anerkennung.certificate.list.item_3')}</ListItem>
          <ListItem bulletPoint={true}>{t('pdf.anerkennung.certificate.list.item_4')}</ListItem>
          <ListItem bulletPoint={true}>{t('pdf.anerkennung.certificate.list.item_5')}</ListItem>
          <ListItem bulletPoint={true}>{t('pdf.anerkennung.certificate.list.item_6')}</ListItem>
          <ListItem bulletPoint={true}>{t('pdf.anerkennung.certificate.list.item_7')}</ListItem>
          <Text style={certificateStyles.subFooter}>
            {status === STATUS_UK && t('pdf.anerkennung.certificate.uk.valid_until')}
          </Text>

          <RecognitionSignatureForm />

          <Text>
            <Break count={2} />
            {t('pdf.anerkennung.basel')} {generationDate}
          </Text>
          <Text style={certificateStyles.footer}>{t('pdf.anerkennung.certificate.footer')}</Text>
        </View>
        <View style={certificateStyles.footerContent}>
          <PdfFooter showInFirstPageOnly={true} />
        </View>
      </Page>
    </Document>
  );
};

CertificationPDF.propTypes = {
  organization: PropTypes.shape().isRequired,
  user: PropTypes.shape().isRequired,
  status: PropTypes.string.isRequired,
  generationDate: PropTypes.string.isRequired,
};

export default CertificationPDF;
