import React, { Fragment, useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import SidePanel from 'components/base-components/SidePanel';
import { useGetUsersQuery } from 'api/users';
import { useSetEducationAssigneeMutation } from 'api/educations-asignee';
import Icon from 'components/base-components/Icon';
import Button from 'components/base-components/Button';
import { USERS_STATUS } from 'utils/statuses';
import { useTranslation } from 'react-i18next';
import SelectBox from 'components/base-components/SelectBox';
import ChartaValidity from 'components/shared-components/charta-validity/charta-validity.component';
import { debounce } from 'throttle-debounce';
import { useGetActiveChartaQuery } from '../../../../api/chartas';

const SetAssignee = ({ associatedUser, organizationId, type, label, disabled }) => {
  const [enableSidePannel, setEnableSidePanel] = useState(false);
  const [selectedUser, setSelectedUser] = useState();
  const [query, setQuery] = useState('');

  const { data: activeCharta } = useGetActiveChartaQuery({
    organizationId,
  });

  const { t } = useTranslation();

  const userParams = {
    'organization_ids[]': [organizationId],
    q: query,
    status: USERS_STATUS.active,
  };

  const { data } = useGetUsersQuery(userParams, { skip: !enableSidePannel });
  const users = data ? data.users : [];

  const [createAssignee] = useSetEducationAssigneeMutation();

  const userOptions = useMemo(() => {
    return users.map(user => ({ label: user.name, value: user.id }));
  }, [JSON.stringify(users)]);

  const onSubmit = () => {
    const params = {
      queryParams: {
        organizationId,
      },
      payload: {
        user_id: selectedUser.value,
      },
    };
    createAssignee(params)
      .unwrap()
      .then(() => {
        setEnableSidePanel(false);
      });
  };

  const onSearchUser = debounce(500, value => {
    setQuery(value);
  });

  const previousLySelectedUserId = associatedUser && associatedUser.id;

  useEffect(() => {
    if (previousLySelectedUserId) {
      setSelectedUser({ label: associatedUser.name, value: associatedUser.id });
    }
  }, [previousLySelectedUserId]);

  return (
    <Fragment>
      <SidePanel
        title={t('educations.assignee.title')}
        closeIcon={<Icon name="close" />}
        body={
          <div className="organization-education__assignee-set">
            <span className="organization-education__assignee-set-for">
              {t('educations.assignee.adding_for')}
            </span>
            <div className="organization-education__assignee-set-charta-validity">
              <ChartaValidity
                validFrom={activeCharta?.validFrom}
                validUntil={activeCharta?.validUntil}
                withValidityOnly={true}
                currentTimePeriodTransKey={'educations.assignee.time_period.current'}
              />
            </div>
            <SelectBox
              onInputChange={onSearchUser}
              isSearchable={true}
              size="tiny"
              width="large"
              label={t('educations.assignee.select.label')}
              placeholderText={t('educations.assignee.select.placeholder')}
              required={true}
              isClearable={false}
              options={userOptions}
              value={selectedUser}
              onChange={user => setSelectedUser(user)}
            />
            <div className="organization-education__assignee-set-action">
              <Button
                label={t('shared.action.save')}
                size="small"
                onClick={onSubmit}
                disabled={!selectedUser}
                type="success"
              />
              <Button
                label={t('shared.action.cancel')}
                size="small"
                onClick={() => {
                  setEnableSidePanel(false);
                }}
              />
            </div>
          </div>
        }
        isOpened={enableSidePannel}
        onClick={() => {}}
        onClose={() => {
          setEnableSidePanel(false);
        }}
      />
      <Button
        disabled={disabled}
        label={label}
        size="small"
        onClick={() => setEnableSidePanel(true)}
        type={type}
      />
    </Fragment>
  );
};

SetAssignee.defaultProps = {
  disabled: false,
};

SetAssignee.propTypes = {
  organizationId: PropTypes.number.isRequired,
  disabled: PropTypes.bool,
  type: PropTypes.string.isRequired,
  associatedUser: PropTypes.shape().isRequired,
  label: PropTypes.string.isRequired,
};

export default SetAssignee;
