import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useGetAddressQuery } from 'api/producers';
import Card from 'components/base-components/Card';
import AttributeAlignment from './attribute-alignment.component';

const Address = ({ addressId, companyIdentifier, title }) => {
  const { data: address = {} } = useGetAddressQuery(
    { addressId, companyIdentifier },
    { skip: !addressId },
  );

  const { t } = useTranslation();

  return (
    <Card title={title} className="super-office-details__addresses-block">
      <div className="address">
        <div className="address__name">
          <AttributeAlignment label={t('shared.label.address.line_1')} value={address.address} />
          <AttributeAlignment label={t('shared.label.address.line_2')} value={address.address2} />
        </div>
        <div className="address__details">
          <AttributeAlignment label={t('shared.label.address.address_name')} value={address.city} />
          <AttributeAlignment label={t('shared.label.address.zip_code')} value={address.zipcode} />
          <AttributeAlignment label={t('shared.label.address.canton')} value={address.cantonCode} />
          <AttributeAlignment label={t('shared.label.address.country')} value={address.country} />
        </div>
      </div>
    </Card>
  );
};

Address.propTypes = {
  addressId: PropTypes.number.isRequired,
  companyIdentifier: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
};

export default Address;
