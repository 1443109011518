import React from 'react';
import {
  useApproveContractMutation,
  useDeleteAttachmentMutation,
  useGetContractQuery,
  useUpdateContractMutation,
  useUploadContractMutation,
} from 'api/contract';
import { useGetOrganizationQuery } from 'api/organizations';
import { useGetCurrentUserQuery } from 'api/users';
import Button from 'components/base-components/Button';
import Card from 'components/base-components/Card';
import EmphasisTag from 'components/base-components/EmphasisTag';
import Icon from 'components/base-components/Icon';
import Link from 'components/base-components/Link';
import Spinner from 'components/base-components/Spinner';
import ApprovalConfirmationModal from 'components/shared-components/ApprovalModal/approval-confirmation-modal.component';
import ChooseFile from 'components/shared-components/choose-file/ChooseFile';
import SubmitModal from 'components/shared-components/modal/submit-modal';
import WarningModal from 'components/shared-components/modal/warning';
import ConfirmationModalWrapper from 'components/users/ConfirmationModalWrapper';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { Trans } from 'react-i18next';
import { useParams } from 'react-router-dom';
import bytesToMegabytes from 'utils/bytes-to-megabytes';
import { dateMonthYear } from 'utils/date-time-formatter';
import isBioSuisseAdmin from 'utils/is-bio-suisse-admin';
import { ORGANISATIONS_STATUS } from 'utils/statuses';

const ContractVerification = ({ title }) => {
  const [isVerifyModalOpen, setIsVerifyModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] = useState(false);
  const { id } = useParams();

  const [uploadFile, uploadStatus] = useUploadContractMutation();
  const [deleteFile, { isLoading, isError }] = useDeleteAttachmentMutation();
  const [approveContract, verifyStatus = {}] = useApproveContractMutation();
  const { data: organisationData } = useGetOrganizationQuery(id);
  const { data: contractData } = useGetContractQuery(id);
  const { data: currentUser } = useGetCurrentUserQuery();

  const isDeactivated = organisationData?.status === ORGANISATIONS_STATUS.inactive;

  const [updateContract] = useUpdateContractMutation();
  const isAdmin = isBioSuisseAdmin(currentUser);

  const handleUploadFile = file => {
    const formData = new FormData();

    formData.append('contract', file);
    if (!contractData) {
      formData.append('organization_id', id);
      return uploadFile({ organisationId: id, payload: formData });
    } else {
      return updateContract({
        contractId: contractData?.id,
        payload: formData,
      });
    }
  };

  const handleDelete = () => {
    return deleteFile({
      contractId: contractData?.id,
    })
      .unwrap()
      .then(() => setIsDeleteModalOpen(false))
      .then(() => setIsDeleteConfirmationModalOpen(true));
  };

  const handleVerifyContract = () => {
    return approveContract({
      organisationId: id,
    })
      .unwrap()
      .then(() => setIsVerifyModalOpen(false));
  };

  const handleTag = isVerified => {
    if (isVerified) {
      return (
        <EmphasisTag type="success" size="tiny" text={t('supplier_certificates.status.verified')} />
      );
    } else {
      return <EmphasisTag type="warning" size="tiny" text={t('contract.not_declared_tag')} />;
    }
  };

  const modalContent = () => {
    if (isLoading) {
      return (
        <div className="user__invite--modal-loading">
          <Spinner size="small" color="success" bgColor="none" />
          <div className="user__invite--modal-loading-message">
            {t('contract.modal.sending_invitation')}
          </div>
        </div>
      );
    }

    if (isError) {
      return (
        <div className="user__invite--modal-failure">
          <Icon className="user__invite--modal-failure-icon" name="invalid" size="xxl" />
          <div className="user__invite--modal-failure-message">
            <div>{t('contract.modal.failure_header')}</div>
            <div>{t('contract.modal.failure_description')}</div>
            <div>{t('contract.modal.try_again')}</div>
          </div>
        </div>
      );
    }

    return (
      <div className="user__invite--modal-success">
        <Icon
          className="user__invite--modal-success-icon"
          name="check"
          size="large"
          showBGColor={true}
        />
        <div className="user__invite--modal-success-message">
          <div>{t('contract.modal.success_header')}</div>
          <div>{t('contract.modal.success_description')}</div>
        </div>
      </div>
    );
  };

  return (
    <>
      <Card>
        <div className="organisation-contract__second-step">
          <div>{title}</div>
          <div className="organisation-contract__second-step--tag">
            {handleTag(contractData?.verified)}
          </div>
        </div>
        {!isAdmin ? (
          <>
            <div className="organisation-contract__eligibility">
              {t('contract.declare_suggestion')}
            </div>
            <div>
              <div className="organisation-contract__address-wrapper">
                {t('contract.office_address_text')}
              </div>
              <div className="organisation-contract__address">
                <Icon name="location" color="tertiary" size="medium" />
                <span className="organisation-contract__address-text">
                  <Trans i18nKey="contract.office_address" />
                </span>
              </div>
              <div className="organisation-contract__address">
                <Icon name="call" color="tertiary" size="medium" />
                <span className="organisation-contract__address-text">
                  {t('contract.office_number')}
                </span>
              </div>
            </div>
          </>
        ) : (
          <div className="organisation-contract__eligibility">
            {t('contract.admin_verify_request')}
          </div>
        )}

        {contractData && contractData?.contract && (
          <div className="choose-file__contract-footer">
            <div className="choose-file__contract-filename">
              <Link
                modifier="default"
                size="tiny"
                openNewTab={true}
                href={contractData.contract.url}
              >
                {contractData.contract.filename}
              </Link>
            </div>

            <div className="choose-file__contract-vertical--line__right" />
            <div>
              {bytesToMegabytes(contractData?.contract?.sizeInByte)} {t('shared.mb')}
            </div>
            {contractData?.approvedAt && (
              <>
                <div className="choose-file__contract-vertical--line__right" />
                <div>{dateMonthYear(contractData.approvedAt)}</div>
              </>
            )}
          </div>
        )}

        {!isAdmin && contractData?.contract && (
          <div className="organisation-contract__save">
            <Link
              type="button"
              modifier="default"
              size="small"
              openNewTab={true}
              href={'contractData.contract.url'}
              disabled={isDeactivated}
            >
              <>
                <Icon color="tertiary" name="downloadFile" />
                <span className="choose-file__contract-delete--text">
                  {t('contract.download_button')}
                </span>
              </>
            </Link>
          </div>
        )}

        <div className="organisation-contract__choose-btn">
          {isAdmin && (
            <>
              <div className="organisation-contract__verify-btn">
                <ChooseFile
                  showText={false}
                  onChange={file => {
                    handleUploadFile(file);
                  }}
                  labelText={
                    <>
                      <Icon name="uploadFile" size="medium" />
                      <span className="organisation-contract__upload">
                        {t('contract.upload_file')}
                      </span>
                    </>
                  }
                  disabled={isDeactivated || contractData?.contract}
                />
                <Button
                  className="organisation-contract__delete-btn"
                  label={
                    <>
                      <Icon className="choose-file__contract-delete--icon" name="delete" />
                      <span className="choose-file__contract-delete--text">
                        {t('contract.delete_button')}
                      </span>
                    </>
                  }
                  size="small"
                  disabled={!contractData?.contract}
                  onClick={() => setIsDeleteModalOpen(true)}
                />
                <Button
                  label={t('contract.verify_button')}
                  className="organisation-contract__success"
                  type="success"
                  size="small"
                  onClick={() => setIsVerifyModalOpen(true)}
                  disabled={isDeactivated || contractData?.verified}
                />
              </div>
            </>
          )}
        </div>
      </Card>

      <ApprovalConfirmationModal
        organizationId={id}
        isModalOpen={isVerifyModalOpen}
        setIsModalOpen={setIsVerifyModalOpen}
        title={t('contract.verify_contract_title')}
        content={t('contract.verify_contract_description')}
        onConfirm={handleVerifyContract}
        confirmActionText={t('shared.action.okay')}
        cancelActionText={t('shared.action.cancel')}
      />
      <WarningModal
        onOutsideClick={() => setIsDeleteModalOpen(false)}
        isVisible={isDeleteModalOpen}
        title={t('contract.delete_contract_title')}
        content={t('contract.delete_contract_description')}
        confirmActionText={t('shared.action.yes')}
        cancelActionText={t('shared.action.cancel')}
        onCancel={() => setIsDeleteModalOpen(false)}
        onConfirm={handleDelete}
      />
      <ConfirmationModalWrapper
        isVisible={isDeleteConfirmationModalOpen}
        contentAlign="center"
        footerAlign="center"
        content={modalContent()}
        className="user__invite--modal"
        timeOut={!isLoading ? 3000 : 0}
        onTimeout={() => {
          setIsDeleteConfirmationModalOpen(false);
        }}
      />
      <SubmitModal
        isLoading={verifyStatus.isLoading}
        isSuccess={verifyStatus.isSuccess}
        requestId={verifyStatus.requestId}
        successTitle={t('control_body.modal.verification.success.title')}
        successContent={t('control_body.modal.verification.success.content')}
        showError={false}
        showLoader={false}
      />
      <SubmitModal
        isLoading={uploadStatus.isLoading}
        isSuccess={uploadStatus.isSuccess}
        requestId={uploadStatus.requestId}
        successContent={t('contract.upload_successful')}
        showError={false}
        showLoader={true}
        loaderContent={t('contract.upload_loading')}
      />
    </>
  );
};

ContractVerification.propTypes = {
  title: PropTypes.string.isRequired,
};

export default ContractVerification;
