import PropTypes from 'prop-types';
import React from 'react';
import { t } from 'i18next';
import { useGetOtherFarmItemQuery, useGetOtherFarmOptionsQuery } from 'api/producers/productions';
import { toSnakecaseKeys } from 'utils/object-cleanup';
import SharedProductCultivationComponent from '../shared/shared-product-cultivation.component';

const OtherFarmProduction = ({ productionId }) => {
  const { data: otherFarmProductionData } = useGetOtherFarmItemQuery(
    { productionId },
    { skip: !productionId },
  );

  const otherFarms = toSnakecaseKeys(otherFarmProductionData?.productionDetails || {});
  const { data: otherFarmListOptions = [] } = useGetOtherFarmOptionsQuery();

  return (
    <SharedProductCultivationComponent
      isCheckboxComponent={true}
      data={otherFarmListOptions}
      activeItem={otherFarms}
      fieldName="otherFarms"
      title={t('plant_cultivation.other_farms.other_animals')}
      icon="animal"
    />
  );
};

OtherFarmProduction.defaultProps = {
  productionId: null,
};

OtherFarmProduction.propTypes = {
  productionId: PropTypes.number,
};

export default OtherFarmProduction;
