const ASSIGNMENT = 'Assignment';
const ORGANISATION = 'Organisation';
const ORGANISATION_OVERVIEW = 'OrganisationOverview';
const ORGANISATION_DECLARATION_STATUS = 'OrganisationDeclarationStatus';
const CHARTA = 'Charta';
const SUPPLIER_CERTIFICATE = 'SupplierCertificate';
const CERTIFICATE_DECLARATION = 'CertificateDeclaration';
const EDUCATION_ASSIGNEE = 'EducationAssignee';
const EDUCATION = 'Education';
const ORGANIZATIONS_EDUCATION = 'OrganizationsEducation';
const EDUCATION_DECLARATION = 'EducationDeclaration';
const PRODUCT = 'Product';
const PRODUCT_DECLARATION = 'ProductDeclaration';
const COMMENT = 'taskComment';
const TASK = 'Task';
const MESSAGE = 'Message';
const PRODUCT_OVERVIEW = 'ProductOverview';
const GOODS_OVERVIEW = 'GoodsOverview';
const GOODS = 'Goods';
const USER = 'User';
const SUPPLIER_CERTIFICATE_OVERVIEW = 'SupplierCertificateOverview';
const EDUCATION_OVERVIEW = 'EducationOverview';
const CONTROL_BODY_OVERVIEW = 'ControlBodyOverview';
const CONTROL_BODY_SURVEY = 'ControlBodySurvey';
const CONTROL_BODY_SURVEY_DELETE_IMAGE = 'ControlBodySurveyDeleteImage';
const CONTROL_BODY_SURVEY_DELETE_AUDIO = 'ControlBodySurveyDeleteAudio';
const CONTROL_BODY = 'Control';
const CONTRACT = 'Contract';
const LOCAL_UNIT = 'LocalUnit';
const PRODUCTIONS = 'Productions';
const INVOICE = 'Invoice';
const INVOICE_RELEASE = 'InvoiceRelease';
const INVOICE_REJECT = 'InvoiceReject';
const INVOICE_ITEMS = 'InvoiceItems';
const INVOICE_TRANSACTIONS = 'InvoiceTransactions';
const INVOICE_JOB = 'InvoiceJob';
const RECOGNITION = 'Recognition';

// Bio Cuisine Assesment
const ACCOUNTING_PROCESSES = 'AccountingProcess';
const LOGO_ATTACHMENT_PHOTOS = 'LogoAttachmentPhotos';

// Negative flow additional task
const ACCOUNTING_PROCESSES_ADDITIONAL_TASK = 'AccountingProcessesAdditionalTask';
const LOGO_ATTACHMENT_ADDITIONAL_TASK = 'LogoAttachmentAdditionalTask';
const INGREDIENT_RESERVES_ADDITIONAL_TASK = 'IngredientReservesAdditionalTask';
const OFFLINE_SESSTIONS_ADDITIONAL_TASK = 'OfflineSesstionsAdditionalTask';
const RESTRICTED_INGREDIENTS_ADDITIONAL_TASK = 'RestrictedIngredientsAdditionalTask';

export {
  ACCOUNTING_PROCESSES,
  LOGO_ATTACHMENT_PHOTOS,
  ASSIGNMENT,
  ORGANISATION,
  ORGANISATION_OVERVIEW,
  CHARTA,
  SUPPLIER_CERTIFICATE,
  CERTIFICATE_DECLARATION,
  EDUCATION_ASSIGNEE,
  EDUCATION,
  ORGANIZATIONS_EDUCATION,
  EDUCATION_DECLARATION,
  PRODUCT,
  PRODUCT_DECLARATION,
  COMMENT,
  TASK,
  LOCAL_UNIT,
  PRODUCTIONS,
  MESSAGE,
  PRODUCT_OVERVIEW,
  GOODS,
  USER,
  INVOICE,
  INVOICE_RELEASE,
  INVOICE_REJECT,
  INVOICE_ITEMS,
  GOODS_OVERVIEW,
  CONTROL_BODY,
  CONTROL_BODY_OVERVIEW,
  CONTROL_BODY_SURVEY,
  CONTROL_BODY_SURVEY_DELETE_IMAGE,
  CONTROL_BODY_SURVEY_DELETE_AUDIO,
  SUPPLIER_CERTIFICATE_OVERVIEW,
  EDUCATION_OVERVIEW,
  CONTRACT,
  ORGANISATION_DECLARATION_STATUS,
  ACCOUNTING_PROCESSES_ADDITIONAL_TASK,
  LOGO_ATTACHMENT_ADDITIONAL_TASK,
  INGREDIENT_RESERVES_ADDITIONAL_TASK,
  OFFLINE_SESSTIONS_ADDITIONAL_TASK,
  RESTRICTED_INGREDIENTS_ADDITIONAL_TASK,
  INVOICE_TRANSACTIONS,
  INVOICE_JOB,
  RECOGNITION,
};
