import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import CheckBox from 'components/base-components/CheckBox';
import Input from 'components/base-components/Input';
import { t } from 'i18next';

const CheckboxDependentInput = props => {
  const { checkboxFieldName, inputFieldName, initialValue, checkboxLabel, inputLabel } = props;
  const { areasInHectare } = initialValue;
  const [isInputDisabled, setisInputDisabled] = useState(!areasInHectare);

  useEffect(() => setisInputDisabled(!areasInHectare), [areasInHectare]);

  return (
    <div className="plant-cultivation__method--mushroom">
      <Field name={checkboxFieldName} type="checkbox" initialValue={areasInHectare}>
        {({ input }) => (
          <CheckBox
            isChecked={input.checked}
            size="tiny"
            label={checkboxLabel}
            onChange={e => {
              input.onChange(e.target.checked);
              setisInputDisabled(!e.target.checked);
            }}
          />
        )}
      </Field>
      <Field name={inputFieldName} initialValue={areasInHectare}>
        {({ input }) => (
          <Input
            size="tiny"
            data-testid="input-field"
            type="number"
            label={inputLabel}
            placeholder={t('plant_cultivation.shared.surface_area_input_placeholder')}
            disabled={isInputDisabled}
            {...input}
          />
        )}
      </Field>
    </div>
  );
};

CheckboxDependentInput.defaultProps = {
  initialValue: {},
  checkboxFieldName: '',
  inputFieldName: '',
};

CheckboxDependentInput.propTypes = {
  initialValue: PropTypes.shape(),
  checkboxFieldName: PropTypes.string,
  inputFieldName: PropTypes.string,
  checkboxLabel: PropTypes.string.isRequired,
  inputLabel: PropTypes.string.isRequired,
};

export default CheckboxDependentInput;
