import routes from 'utils/routes';
import { USER, ASSIGNMENT } from '../../utils/tag-types';
import { apiSlice } from '../index';

const usersApi = apiSlice.injectEndpoints({
  endpoints: build => ({
    getUsers: build.query({
      query: searchParams => routes.users.collection(searchParams),
      transformResponse: (response, meta, arg) => {
        return {
          users: response.collection,
          pagination: response.pagination,
          tab: arg.status,
        };
      },
      providesTags: [USER],
    }),
    getUser: build.query({
      query: params => routes.users.entity(params),
      providesTags: [USER],
    }),
    inviteUser: build.mutation({
      query: ({ payload }) => ({
        url: routes.users.invite(),
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: [USER],
    }),
    updateUser: build.mutation({
      query: ({ userId, payload }) => ({
        url: routes.users.entity({ id: userId }),
        method: 'PATCH',
        body: payload,
      }),
      invalidatesTags: [USER],
    }),
    deactivateUser: build.mutation({
      query: userId => ({
        url: routes.users.deactivate({ userId }),
        method: 'PUT',
      }),
      invalidatesTags: [USER],
    }),
    reactivateUser: build.mutation({
      query: userId => ({
        url: routes.users.reactivate({ userId }),
        method: 'PUT',
      }),
      invalidatesTags: [USER],
    }),
    resendUserInvitation: build.mutation({
      query: userId => ({
        url: routes.users.resendUserInvitation({ userId }),
        method: 'PUT',
      }),
    }),
    getUserRoles: build.query({
      query: () => routes.roles.collection(),
    }),
    getCurrentUser: build.query({
      query: () => routes.currentUser.entity(),
    }),
    updateUserPassword: build.mutation({
      query: payload => ({
        url: routes.users.reset(),
        method: 'PUT',
        body: payload,
      }),
    }),
    getUserEmails: build.query({
      query: userId => routes.email.collectionOfUser({ userId }),
      providesTags: ['Email'],
    }),

    createUserEmail: build.mutation({
      query: ({ userId, payload }) => ({
        url: routes.email.collectionOfUser({ userId }),
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['Email'],
    }),
    updateUserEmail: build.mutation({
      query: ({ params, payload }) => ({
        url: routes.email.entity(params),
        method: 'PATCH',
        body: payload,
      }),
      invalidatesTags: ['Email'],
    }),
    deleteUserEmail: build.mutation({
      query: params => ({
        url: routes.email.entity(params),
        method: 'DELETE',
        credentials: 'include',
      }),
      invalidatesTags: ['Email'],
    }),
    getUserPhones: build.query({
      query: userId => routes.phone.collectionOfUser({ userId }),
      providesTags: ['Phone'],
    }),
    createUserPhone: build.mutation({
      query: ({ userId, payload }) => ({
        url: routes.phone.collectionOfUser({ userId }),
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['Phone'],
    }),
    updateUserPhone: build.mutation({
      query: ({ params, payload }) => ({
        url: routes.phone.entity(params),
        method: 'PATCH',
        body: payload,
      }),
      invalidatesTags: ['Phone'],
    }),
    deleteUserPhone: build.mutation({
      query: params => ({
        url: routes.phone.entity(params),
        method: 'DELETE',
        credentials: 'include',
      }),
      invalidatesTags: ['Phone'],
    }),
    getUserAssignments: build.query({
      query: params => routes.assignments.collectionOfUser(params),
      transformResponse: (response, meta, arg) => {
        return {
          assignments: response.collection,
          pagination: response.pagination,
          tab: arg.status,
        };
      },
      providesTags: [ASSIGNMENT],
    }),
    createUserAssignment: build.mutation({
      query: ({ userId, payload }) => ({
        url: routes.assignments.collectionOfUser({ id: userId }),
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: [ASSIGNMENT],
    }),
    updateUserAssignment: build.mutation({
      query: ({ id, payload }) => ({
        url: routes.assignments.entity(id),
        method: 'PATCH',
        body: payload,
      }),
      invalidatesTags: [ASSIGNMENT],
    }),
    deleteUserAssignment: build.mutation({
      query: id => ({
        url: routes.assignments.entity(id),
        method: 'DELETE',
        credentials: 'include',
      }),
      invalidatesTags: [ASSIGNMENT],
    }),
    getSpokenLanguageList: build.query({
      query: () => routes.users.spokenLanguageList(),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetUsersQuery,
  useGetUserQuery,
  useInviteUserMutation,
  useUpdateUserMutation,
  useDeactivateUserMutation,
  useReactivateUserMutation,
  useResendUserInvitationMutation,
  useGetCurrentUserQuery,
  useCreateUserEmailMutation,
  useGetUserEmailsQuery,
  useUpdateUserEmailMutation,
  useDeleteUserEmailMutation,
  useCreateUserPhoneMutation,
  useGetUserPhonesQuery,
  useUpdateUserPhoneMutation,
  useDeleteUserPhoneMutation,
  useGetUserAssignmentsQuery,
  useCreateUserAssignmentMutation,
  useUpdateUserAssignmentMutation,
  useDeleteUserAssignmentMutation,
  useGetUserRolesQuery,
  useUpdateUserPasswordMutation,
  useGetSpokenLanguageListQuery,
} = usersApi;
