import Icon from 'components/base-components/Icon';
import EmptyPlaceholder from 'components/users/empty-placeholder';
import { t } from 'i18next';
import React from 'react';
import './producer.styles.scss';

const ProducerOverview = () => {
  return (
    <div className="grid col-12 col-bleed producer-wrapper">
      <div className="col-4 col-bleed-x producer-wrapper--empty-placeholder">
        <EmptyPlaceholder
          emptyCardText={t('common.coming_soon')}
          emptyIcon={<Icon name="add" color="tertiary" />}
        />
      </div>
      <div className="col-4 producer-wrapper--empty-placeholder">
        <EmptyPlaceholder
          emptyCardText={t('common.coming_soon')}
          emptyIcon={<Icon name="add" color="tertiary" />}
        />
      </div>
      <div className="col-4 col-bleed-x producer-wrapper--empty-placeholder">
        <EmptyPlaceholder
          emptyCardText={t('common.coming_soon')}
          emptyIcon={<Icon name="add" color="tertiary" />}
        />
      </div>
    </div>
  );
};

export default ProducerOverview;
