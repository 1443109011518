export const legalFormValues = (values, formData, id) => {
  if (values.legalForm) {
    formData.append('legal_form', values.legalForm);
  }

  if (values.companyType) {
    formData.append('producer_type', values.companyType);
  }

  if (values.numberOfPartners) {
    formData.append('number_of_partners', values.numberOfPartners);
  }

  if (values?.legalFormPartners) {
    let filteredLegalFormPartners = values?.legalFormPartners;

    if (!id) {
      if (values.numberOfPartners < values.legalFormPartners.length) {
        filteredLegalFormPartners = values.legalFormPartners.slice(0, values.numberOfPartners);
      }
    }

    filteredLegalFormPartners.forEach((partner, index) => {
      const partnerMapper = {
        firstName: 'first_name',
        lastName: 'last_name',
        email: 'email',
        salutation: 'salutation',
        operationManager: 'operation_manager',
      };

      Object.keys(partnerMapper).forEach(key => {
        const apiKey = partnerMapper[key];
        if (apiKey === 'operation_manager') {
          formData.append(`assignments[][${apiKey}]`, partner[key] || false);
        } else {
          formData.append(`assignments[][${apiKey}]`, partner[key]);
        }
      });

      formData.append(`assignments[][main_contact]`, values?.mainContact === index);
      formData.append(`assignments[][contract_person]`, true);

      if (partner?.userId) {
        formData.append(`assignments[][user_id]`, partner?.userId);
      }

      if (index + 1 > values.numberOfPartners) {
        formData.append(`assignments[][destroy]`, true);
      }
    });
  }

  if (values?.legalFormPartners && values?.legalFormPartners?.length === values?.mainContact) {
    formData.append(`assignments[][first_name]`, values?.otherPartner?.firstName);
    formData.append(`assignments[][last_name]`, values?.otherPartner?.lastName);
    formData.append(`assignments[][email]`, values?.otherPartner?.email);
    formData.append(`assignments[][salutation]`, values?.otherPartner?.salutation);
    formData.append(`assignments[][operation_manager]`, false);
    formData.append(`assignments[][main_contact]`, true);
    formData.append(`assignments[][contract_person]`, false);
    if (values?.otherPartner?.userId) {
      formData.append(`assignments[][user_id]`, values?.otherPartner?.userId);
    }
  }
};
