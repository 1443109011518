import { Text, View } from '@react-pdf/renderer';
import { InputField } from 'components/pdf/shared/shared-components';
import styles from 'components/pdf/styles/styles';
import { t } from 'i18next';
import React from 'react';

const SignatureForm = () => (
  <View style={styles.signatureForm}>
    <View style={{ width: '100%', marginRight: 10 }}>
      <Text style={styles.textBold}>{t('producer_contract.signature_form.left.title')}</Text>
      <InputField title={t('producer_contract.signature_form.left.first_input')} />
      <InputField title={t('producer_contract.signature_form.left.second_input')} />
      <InputField title={t('producer_contract.signature_form.left.third_input')} />
    </View>
    <View style={{ width: '100%', marginLeft: 10 }}>
      <Text style={styles.textBold}>{t('producer_contract.signature_form.right.title')}</Text>
      <InputField title={t('producer_contract.signature_form.right.first_input')} />
      <InputField title={t('producer_contract.signature_form.right.second_input')} />
      <InputField
        style={{ marginTop: 10 }}
        title={t('producer_contract.signature_form.right.third_input')}
      />
    </View>
  </View>
);

export default SignatureForm;
