import routes from 'utils/routes';
import { RECOGNITION } from 'utils/tag-types';
import { apiSlice } from '../index';

const recognitionApi = apiSlice.injectEndpoints({
  endpoints: build => ({
    getRecognitions: build.query({
      query: params => routes.recognition.collection(params),
      providesTags: [RECOGNITION],
    }),
    getRecognitionCertificate: build.query({
      query: params => routes.recognition.entity(params),
      providesTags: [RECOGNITION],
    }),
    deleteRecognitionCertificate: build.mutation({
      query: ({ payload }) => ({
        url: routes.recognition.entity(),
        method: 'DELETE',
        body: payload,
      }),
      invalidatesTags: [RECOGNITION],
    }),
    markCertificate: build.mutation({
      query: ({ payload }) => ({
        url: routes.recognition.update(),
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: [RECOGNITION],
    }),
  }),
});

export const {
  useGetRecognitionsQuery,
  useGetRecognitionCertificateQuery,
  useDeleteRecognitionCertificateMutation,
  useMarkCertificateMutation,
} = recognitionApi;
