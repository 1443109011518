import PropTypes from 'prop-types';
import React from 'react';
import { t } from 'i18next';
import { useGetFishFarmProductionQuery } from 'api/producers/productions';
import SharedProductCultivationComponent from '../shared/shared-product-cultivation.component';

const FishProduction = ({ farmlandId }) => {
  const { data: fishProductionData } = useGetFishFarmProductionQuery(
    { productionId: farmlandId },
    { skip: !farmlandId },
  );

  const fields = [
    {
      name: 'quantity_of_fish',
      initialValue: fishProductionData?.fishProducedKg,
      label: t('plant_cultivation.fish_farming.quantity_of_fish'),
      placeholder: t('plant_cultivation.shared.surface_area_input_placeholder'),
    },
  ];

  return (
    <SharedProductCultivationComponent
      fieldName="fish"
      icon="fish"
      name="fish"
      label={t('plant_cultivation.fish_farming.self')}
      title={t('plant_cultivation.fish_farming.self')}
      fields={fields}
      isTypePresent={true}
    />
  );
};

FishProduction.propTypes = {
  farmlandId: PropTypes.number.isRequired,
};

export default FishProduction;
