import classNames from 'classnames';
import Card from 'components/base-components/Card';
import Icon from 'components/base-components/Icon';
import IconButton from 'components/base-components/IconButton';
import YesNoInput from 'components/base-components/YesNoInput';
import ChooseFile from 'components/shared-components/choose-file/ChooseFile';
import { boolToYesNo } from 'utils/yes-no-helper';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import bytesToMegabytes from 'utils/bytes-to-megabytes';
import { Field } from 'react-final-form';
import Textarea from 'components/base-components/Textarea/Textarea';
import { useGetAudioResponsesQuery } from 'api/control-body-survey';
import { TEMPORARY } from 'utils/control-body-survey';

const AudioUpload = ({
  text,
  questionNumber,
  setAudioFile,
  activeAudio,
  handleAudioDelete,
  status,
  isCertifier,
  audioIds,
  surveyId,
}) => {
  const { t } = useTranslation();
  const [file, setFile] = useState(null);
  const [isCommentVisible, setIsCommentVisible] = useState(false);

  const { data: audiosFromAuditor } = useGetAudioResponsesQuery(
    { surveyId, 'ids[]': audioIds },
    { skip: !surveyId },
  );

  const selectedAudiosFromAuditor =
    audiosFromAuditor?.length > 0 &&
    audiosFromAuditor.find(audio => audio.audioAnswer === activeAudio.audioAnswer);

  const handleUploadFile = file => {
    setFile(file);
    setAudioFile(questionNumber, file);
  };

  const handleCertifierComment = (updatedValue, fixedValue) => {
    if (updatedValue !== boolToYesNo(fixedValue)) {
      setIsCommentVisible(true);
    } else if (updatedValue === boolToYesNo(fixedValue)) {
      setIsCommentVisible(false);
    }
  };

  useEffect(() => {
    if (activeAudio && selectedAudiosFromAuditor) {
      handleCertifierComment(
        boolToYesNo(activeAudio?.satisfied),
        selectedAudiosFromAuditor?.satisfied,
      );
    }
  }, [activeAudio, selectedAudiosFromAuditor]);

  if (!activeAudio) return null;

  return file || Object.keys(activeAudio).length !== 0 ? (
    <>
      <div
        className={classNames(
          `organisation-checklist__audio-text`,
          `organisation-checklist__audio-text--${questionNumber}`,
        )}
      >
        {text}
      </div>
      <div className="organisation-checklist__audio-card--wrapper">
        <Card className="organisation-checklist__audio-card--content">
          <div className="organisation-checklist__audio-card">
            <div className="organisation-checklist__audio-card--icon">
              <Icon className="choose-file__contract-delete--icon" name="audio" size="medium" />
              <span className="choose-file__contract-delete--text">
                {activeAudio?.audio?.filename || file?.name || t('common.not_applicable')}
              </span>
            </div>
            <div>{bytesToMegabytes(activeAudio?.audio?.sizeInByte || file?.size)}MB</div>
            <div className="organisation-checklist__audio-card--icon">
              <a href={activeAudio?.audio?.url} target="_blank" rel="noreferrer">
                <IconButton
                  className="topnav__back-button"
                  icon={
                    <Icon
                      className="choose-file__contract-delete--icon"
                      name="downloadFile"
                      size="normal"
                    />
                  }
                />
              </a>
              <IconButton
                className="topnav__back-button"
                color="danger"
                icon={
                  <Icon
                    className="choose-file__contract-delete--icon"
                    name="delete"
                    size="normal"
                    color="danger"
                  />
                }
                disabled={isCertifier}
                onClick={() => {
                  if (Object.keys(activeAudio).length === 0) {
                    setFile(null);
                    setAudioFile(questionNumber, null);
                  } else {
                    handleAudioDelete(activeAudio?.id);
                  }
                }}
              />
            </div>
          </div>
        </Card>
        <div className="organisation-checklist__audio-card--vertical-line" />
        <div>
          <div className="organisation-checklist__audio-card--satisfy">
            {t('control_body.survey.form.audio.satisfied')}
          </div>
          <div className="organisation-checklist__checkbox-content">
            <label className="organisation-checklist__radio organisation-checklist__radio-positive">
              <div className="organisation-checklist__radio-wrapper organisation-checklist__radio-wrapper--audio">
                <Field
                  name={`audio_${questionNumber}`}
                  initialValue={boolToYesNo(activeAudio?.satisfied)}
                >
                  {({ input, meta }) => (
                    <YesNoInput
                      {...input}
                      onChange={value => {
                        handleCertifierComment(value, selectedAudiosFromAuditor?.satisfied);
                        input.onChange(value);
                      }}
                      disabled={status === TEMPORARY}
                    />
                  )}
                </Field>
              </div>
            </label>
          </div>
        </div>
      </div>
      {isCommentVisible && isCertifier && Object.keys(activeAudio).length !== 0 && (
        <div className="organisation-checklist__certifier-audio">
          <Field name={`audio_${questionNumber}_comment`} initialValue={activeAudio?.comment || ''}>
            {({ input }) => (
              <Textarea
                className="organisation-checklist__certifier-audio--field"
                label={t('control_body.survey.form.reason_of_change')}
                placeholder={t('control_body.survey.form.comments_placeholder')}
                disabled={status === TEMPORARY}
                {...input}
              />
            )}
          </Field>
        </div>
      )}
    </>
  ) : (
    <>
      <div
        className={classNames(
          `organisation-checklist__audio-text`,
          `organisation-checklist__audio-text--${questionNumber}`,
        )}
      >
        {text}
      </div>
      <ChooseFile
        showText={false}
        onChange={file => {
          handleUploadFile(file);
        }}
        labelText={
          <>
            <Icon className="choose-file__contract-delete--icon" name="uploadFile" size="medium" />
            <span className="choose-file__contract-delete--text">
              {t('control_body.survey.form.upload_audio')}
            </span>
          </>
        }
      />
    </>
  );
};

AudioUpload.defaultProps = {
  text: null,
  status: null,
  surveyId: null,
  isAuditor: null,
  isCertifier: null,
  setAudioFile: () => {},
  handleAudioDelete: () => {},
  activeAudio: {},
  audioIds: [],
};

AudioUpload.propTypes = {
  text: PropTypes.node,
  status: PropTypes.string,
  surveyId: PropTypes.node,
  isAuditor: PropTypes.bool,
  isCertifier: PropTypes.bool,
  setAudioFile: PropTypes.func,
  handleAudioDelete: PropTypes.func,
  questionNumber: PropTypes.string.isRequired,
  activeAudio: PropTypes.shape(),
  audioIds: PropTypes.arrayOf(PropTypes.node),
};

export default AudioUpload;
