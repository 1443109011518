import React from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import POULTRY from 'utils/egg-sub-type.js';
import { EGG_PRODUCTION } from 'utils/production-type';
import { useGetEggProductionsQuery } from '../../../../../api/producers/productions';
import SharedProductCultivationComponent from '../shared/shared-product-cultivation.component';

const Egg = ({ id, onChangeSubTypeSelection }) => {
  const { data: eggProductionData } = useGetEggProductionsQuery(
    { productionId: id },
    { skip: !id },
  );

  const poultryEggFarm = eggProductionData?.find(eggFarm => eggFarm.subType === POULTRY);

  const onChange = (name, checked) => {
    onChangeSubTypeSelection(EGG_PRODUCTION, name, checked);
  };

  const fields = [
    {
      name: 'numberOfLayingHen',
      initialValue: poultryEggFarm?.productionDetails?.layingHens,
      label: t('egg_production.laying_hens'),
      placeholder: t('plant_cultivation.shared.surface_area_input_placeholder'),
    },
    {
      name: 'numberOfFemalePullets',
      initialValue: poultryEggFarm?.productionDetails?.femalePullets,
      label: t('egg_production.female_pullets'),
      placeholder: t('plant_cultivation.shared.surface_area_input_placeholder'),
    },
    {
      name: 'numberOfQuails',
      initialValue: poultryEggFarm?.productionDetails?.quails,
      label: t('egg_production.quails'),
      placeholder: t('plant_cultivation.shared.surface_area_input_placeholder'),
    },
  ];

  return (
    <>
      <SharedProductCultivationComponent
        fieldName="poultry"
        icon="poultry"
        name="poultry"
        title={t('egg_production.poultry')}
        onChange={onChange}
        label={t('egg_production.poultry')}
        fields={fields}
        isTypePresent={true}
      />
    </>
  );
};
Egg.defaultProps = {
  id: null,
};

Egg.propTypes = {
  id: PropTypes.number,
  onChangeSubTypeSelection: PropTypes.func.isRequired,
};
export default Egg;
