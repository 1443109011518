export const privateParkValues = (values, formData) => {
  const privateAreaAttributes = {
    private_area: values?.private_area_in_hectares,
    municipal_area: values?.municipal_area_in_hectares,
  };

  Object.keys(privateAreaAttributes).forEach(area => {
    if (values[area]) {
      formData.append('production_details[private_and_municipal_area][][sub_type]', area);
      formData.append(
        'production_details[private_and_municipal_area][][areas_in_hectare]',
        privateAreaAttributes[area] || 0,
      );
    }
  });
};
