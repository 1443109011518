export const tvdSticker = (values, formData) => {
  if (values.tvd_sticker_enabled !== undefined && values.tvd_sticker_enabled !== null) {
    formData.append('tvd_sticker_enabled', values.tvd_sticker_enabled);
  }

  if (values.tvd_sticker_enabled) {
    (values.tvd_sticker_numbers || []).forEach(number => {
      if (number) formData.append('tvd_sticker_numbers[]', number);
    });
  }
};
