import classNames from 'classnames';
import PropTypes from 'prop-types';
import Textarea from 'components/base-components/Textarea/Textarea';
import YesNoInput from 'components/base-components/YesNoInput';
import { t } from 'i18next';
import React from 'react';
import { Field } from 'react-final-form';
import { DOOR, TEMPORARY } from 'utils/control-body-survey';

const LogoIngredientsCheckboxItem = ({
  attachedTo,
  currentComment,
  auditorValue,
  currentValue,
  isCommentBoxShown,
  isCertifier,
  item,
  status,
  handleCertifierComment,
  setCheckedLogos,
  isAuditor,
  form,
}) => {
  return (
    <div key={item?.label}>
      <ul className="organisation-checklist__unordered-list">
        {isCertifier && currentComment && status === TEMPORARY && auditorValue && (
          <li className="organisation-checklist__unordered-list--disabled">
            <Field name={attachedTo} initialValue={auditorValue}>
              {({ input, meta }) => (
                <YesNoInput
                  {...input}
                  error={meta.submitError}
                  label={`${t(`control_body.survey.form.location.${attachedTo}`)}(${t(
                    `control_body.survey.form.previous`,
                  )})`}
                  disabled={true}
                  showNotRelevant={attachedTo !== DOOR}
                />
              )}
            </Field>
          </li>
        )}
        <li
          className={classNames({
            'organisation-checklist__unordered-list--hide': currentComment && status === TEMPORARY,
          })}
        >
          <Field name={attachedTo} initialValue={currentValue}>
            {({ input, meta }) => (
              <YesNoInput
                {...input}
                error={meta.submitError}
                label={t(`control_body.survey.form.location.${attachedTo}`)}
                onChange={value => {
                  if (auditorValue === value) {
                    form.change(`${attachedTo}__comment`, '');
                  }
                  if (isAuditor) {
                    setCheckedLogos(prev => ({ ...prev, [attachedTo]: value }));
                  }
                  handleCertifierComment(value, attachedTo);
                  input.onChange(value);
                }}
                disabled={status === TEMPORARY}
                showNotRelevant={attachedTo !== DOOR}
              />
            )}
          </Field>
        </li>
      </ul>
      {isCommentBoxShown(attachedTo) && (
        <div className="organisation-checklist__certifier-comment">
          <Field name={`${attachedTo}__comment`} initialValue={currentComment || ''}>
            {({ input }) => (
              <Textarea
                className="organisation-checklist__certifier-comment--field"
                label={t('control_body.survey.form.reason_of_change')}
                placeholder={t('control_body.survey.form.comments_placeholder')}
                required={false}
                touched={true}
                disabled={status === TEMPORARY}
                {...input}
              />
            )}
          </Field>
        </div>
      )}
      <hr className="organisation-checklist__checkbox-horizontal-line" />
    </div>
  );
};

LogoIngredientsCheckboxItem.defaultProps = {
  attachedTo: null,
  currentComment: null,
  item: null,
  auditorValue: null,
  currentValue: null,
  status: null,
  isCertifier: false,
  isCommentBoxShown: () => {},
  handleCertifierComment: () => {},
};

LogoIngredientsCheckboxItem.propTypes = {
  attachedTo: PropTypes.shape(),
  currentComment: PropTypes.node,
  currentValue: PropTypes.node,
  status: PropTypes.string,
  item: PropTypes.arrayOf(PropTypes.string),
  auditorValue: PropTypes.arrayOf(PropTypes.string),
  isCommentBoxShown: PropTypes.func,
  handleCertifierComment: PropTypes.func,
  setCheckedLogos: PropTypes.func.isRequired,
  isCertifier: PropTypes.bool,
  isAuditor: PropTypes.bool.isRequired,
  form: PropTypes.shape().isRequired,
};

export default LogoIngredientsCheckboxItem;
