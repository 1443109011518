import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useGetOrganizationAssignmentsQuery, useGetOrganizationQuery } from 'api/organizations';
import Card from 'components/base-components/Card';
import ContentLoaderPlaceholder from 'components/base-components/ContentLoader';
import Icon from 'components/base-components/Icon';
import classNames from 'classnames';
import OrganizationName from 'components/organisations/organization-name';
import EmptyPlaceholder from 'components/users/empty-placeholder';
import UserName from 'components/users/user-name.component';
import { t } from 'i18next';
import { useParams } from 'react-router';
import { OWNER } from 'utils/users-role';
import { Trans } from 'react-i18next';
import AddressOverview from './address-overview.component';
import ContactOverview from './contact-overview.component';

const OverviewHeader = ({ isFetching, organisation, isProducer, owners }) => {
  const gridSpace = {
    'col-1': !isProducer,
    'col-2': isProducer,
  };

  return (
    <div className="col-12 col-bleed">
      <Card>
        {isFetching ? (
          <ContentLoaderPlaceholder numberOfLines={2} showBackground={false} />
        ) : (
          <>
            <div className={classNames(gridSpace)}>
              <div className="organisation-overview__header">{t('organisation.bio_id')}</div>
              <div className="organisation-overview__text">
                {organisation?.bioId || t('common.not_applicable')}
              </div>
            </div>
            <div className="col-2">
              <div className="organisation-overview__header">{t('organisation_type.uid')}</div>
              <div className="organisation-overview__text">
                {organisation?.uid || t('common.not_applicable')}
              </div>
            </div>
            <Fragment>
              {isProducer && (
                <div className="col-2">
                  <div className="organisation-overview__header">KT_ID_B</div>
                  <div className="organisation-overview__text">
                    {organisation?.ktIdB || t('common.not_applicable')}
                  </div>
                </div>
              )}
            </Fragment>
            <div className="col-2">
              <div className="organisation-overview__header">
                {t('organisation_view.overview.registered_name')}
              </div>
              <div className="organisation-overview__text">
                {organisation?.mainName || organisation?.name || t('common.not_applicable')}
              </div>
            </div>
            <div className={classNames(gridSpace)}>
              <div className="organisation-overview__header">
                {t('organisation_view.overview.industry')}
              </div>
              <div className="organisation-overview__text">
                {(organisation.industry && t(`organisation.industry.${organisation.industry}`)) ||
                  t('common.not_applicable')}
              </div>
            </div>
            <div className="col-2">
              <div className="organisation-overview__header">
                {t('organisation_view.overview.owner')}
              </div>
              <div className="organisation-overview__text">
                {owners && owners.length > 0
                  ? owners?.map((owner, index) => (
                      <>
                        <UserName userID={owner.userId} />
                        {index !== owners.length - 1 ? ', ' : ''}
                      </>
                    ))
                  : t('common.not_applicable')}
              </div>
            </div>
            <Fragment>
              {!isProducer && (
                <div className="col-2">
                  <div className="organisation-overview__header">
                    {t('organisation_view.overview.parent_organisation')}
                  </div>
                  <div className="organisation-overview__text organisation-overview__text-link">
                    <OrganizationName organizationId={organisation?.parentId} />
                  </div>
                </div>
              )}
            </Fragment>
            <Fragment>
              {!isProducer && (
                <div className="col-2">
                  <div className="organisation-overview__header">
                    {t('organisation_view.overview.education_user')}
                  </div>
                  <div className="organisation-overview__text organisation-overview__text-link">
                    {organisation?.educationAssigneeUserId ? (
                      <UserName userID={organisation?.educationAssigneeUserId} />
                    ) : (
                      t('common.not_applicable')
                    )}
                  </div>
                </div>
              )}
            </Fragment>
          </>
        )}
      </Card>
    </div>
  );
};

OverviewHeader.defaultProps = {
  isFetching: true,
  isProducer: true,
  organisation: {},
  owners: [],
};

OverviewHeader.propTypes = {
  isFetching: PropTypes.bool,
  isProducer: PropTypes.bool,
  organisation: PropTypes.shape(),
  owners: PropTypes.arrayOf(PropTypes.shape()),
};

const OverViewComponent = ({ isProducer }) => {
  const { id } = useParams();

  const {
    data: organisation,
    isFetching,
    isSuccess: isOrganisationDataFetched,
  } = useGetOrganizationQuery(id);

  const { data: owners } = useGetOrganizationAssignmentsQuery({ organizationId: id, role: OWNER });

  const mainAddress = organisation?.addresses?.find(address => address.main === true);
  const billingAddress = organisation?.addresses?.find(address => address.type === 'invoice');
  const mailingAddress = organisation?.addresses?.find(address => address.type === 'mailing');
  const visitorAddress = organisation?.addresses?.find(address => address.type === 'visitor');

  return (
    <div className="grid">
      <OverviewHeader
        isFetching={isFetching}
        organisation={organisation}
        isProducer={isProducer}
        owners={owners}
      />
      <div className="grid col-12 col-bleed direction-row organisation-overview__content">
        <div className="col-4 col-bleed-x organisation-overview__zero-padding">
          {isFetching ? (
            <Card>
              <ContentLoaderPlaceholder numberOfLines={5} showBackground={false} />
            </Card>
          ) : (
            <>
              {isOrganisationDataFetched && mainAddress ? (
                <AddressOverview
                  address={mainAddress}
                  type={t('organisation_view.overview.main_address')}
                />
              ) : (
                <EmptyPlaceholder
                  emptyCardText={
                    <Trans i18nKey="organisation_view.overview.no_main_address_found" />
                  }
                  emptyIcon={<Icon name="add" color="tertiary" />}
                />
              )}
            </>
          )}
        </div>
        <div className="col-4 organisation-overview__zero-padding">
          {isFetching ? (
            <Card>
              <ContentLoaderPlaceholder numberOfLines={5} showBackground={false} />
            </Card>
          ) : (
            <>
              {isOrganisationDataFetched && mailingAddress ? (
                <AddressOverview
                  address={mailingAddress}
                  type={t('organisation_view.overview.mailing_address')}
                />
              ) : (
                <EmptyPlaceholder
                  emptyCardText={
                    <Trans i18nKey="organisation_view.overview.no_mailing_address_found" />
                  }
                  emptyIcon={<Icon name="add" color="tertiary" />}
                />
              )}
            </>
          )}
        </div>
        <div className=" col-4 col-bleed-x organisation-overview__zero-padding">
          {isFetching ? (
            <Card>
              <ContentLoaderPlaceholder numberOfLines={5} showBackground={false} />
            </Card>
          ) : (
            <>
              {isOrganisationDataFetched && billingAddress ? (
                <AddressOverview
                  address={billingAddress}
                  type={t('organisation_view.overview.invoice_mailing_address')}
                />
              ) : (
                <EmptyPlaceholder
                  emptyCardText={
                    <Trans i18nKey="organisation_view.overview.no_billing_address_found" />
                  }
                  emptyIcon={<Icon name="add" color="tertiary" />}
                />
              )}
            </>
          )}
        </div>
        <div className="col-4 col-bleed organisation-overview__zero-padding">
          {isFetching ? (
            <Card>
              <ContentLoaderPlaceholder numberOfLines={5} showBackground={false} />
            </Card>
          ) : (
            <>
              {isOrganisationDataFetched && visitorAddress ? (
                <AddressOverview
                  address={visitorAddress}
                  type={t('organisation_view.overview.visitor_address')}
                />
              ) : (
                <EmptyPlaceholder
                  emptyCardText={
                    <Trans i18nKey="organisation_view.overview.no_visitor_address_found" />
                  }
                  emptyIcon={<Icon name="add" color="tertiary" />}
                />
              )}
            </>
          )}
        </div>
        <div className="col-6 col-bleed-y organisation-overview__zero-padding">
          {isFetching ? (
            <Card>
              <ContentLoaderPlaceholder numberOfLines={5} showBackground={false} />
            </Card>
          ) : (
            <ContactOverview />
          )}
        </div>
        <div className="col-2 col-bleed organisation-overview__zero-padding">
          {isFetching ? (
            <Card>
              <ContentLoaderPlaceholder numberOfLines={5} showBackground={false} />
            </Card>
          ) : (
            <>
              {isOrganisationDataFetched ? (
                <Card className="organisation-overview__communication">
                  <div className="col-12 organisation-overview__content-header">
                    {t('organisation_view.overview.address_header.communication_mode')}
                  </div>
                  <div className="organisation-overview__address-wrapper">
                    <div className="organisation-overview__address-header">
                      {t('organisation_view.overview.address_header.language')}
                    </div>
                    <div className="organisation-overview__address-data">
                      {organisation?.language || t('common.not_applicable')}
                    </div>
                  </div>
                  <div className="organisation-overview__address-wrapper--last">
                    <div className="organisation-overview__address-header">
                      {t('user_general.spoken_language')}
                    </div>
                    <div className="organisation-overview__address-data">
                      {organisation?.secondSpokenLanguage || t('common.not_applicable')}
                    </div>
                  </div>
                </Card>
              ) : (
                <EmptyPlaceholder
                  emptyCardText={
                    <Trans i18nKey="organisation_view.overview.no_billing_address_found" />
                  }
                  emptyIcon={<Icon name="add" color="tertiary" />}
                />
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

OverViewComponent.defaultProps = {
  isProducer: false,
};

OverViewComponent.propTypes = {
  isProducer: PropTypes.bool,
};

export default OverViewComponent;
