import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'throttle-debounce';
import { useTranslation } from 'react-i18next';
import { Field } from 'react-final-form';
import SelectBox from 'components/base-components/SelectBox';
import { useGetControlBodyDeclarationOptionsQuery } from 'api/control-body-declarations';

const SelectControlBody = ({ label, controlBodyData }) => {
  const { t } = useTranslation();
  const [query, setQuery] = useState(null);

  const { data: controlBodyOptionsResponse } = useGetControlBodyDeclarationOptionsQuery({
    q: query,
  });

  const controlBodyList = controlBodyOptionsResponse?.collection || [];

  const controlBodyOptions = controlBodyList.map(item => ({ label: item.name, value: item.id }));

  const onSearchOrganisation = debounce(500, value => setQuery(value));

  return (
    <Field
      name="control_body_organization_id"
      initialValue={controlBodyData?.controlBodyOrganizationId}
    >
      {({ input }) => (
        <SelectBox
          size="tiny"
          width="large"
          label={label || t('control_body.input_field.name')}
          placeholderText={t('control_body.input_field.name_placeholder')}
          isClearable={false}
          options={controlBodyOptions}
          value={controlBodyOptions.find(option => option.value === input.value)}
          selectedValue={input.label}
          onInputChange={onSearchOrganisation}
          onChange={e => input.onChange(e.value)}
          required={true}
        />
      )}
    </Field>
  );
};

SelectControlBody.defaultProps = {
  label: null,
  controlBodyData: null,
};

SelectControlBody.propTypes = {
  label: PropTypes.string,
  controlBodyData: PropTypes.shape({
    controlBodyOrganizationId: PropTypes.number.isRequired,
  }),
};

export default SelectControlBody;
