import { removeEmptyFromObject } from 'utils/object-cleanup';

export const farmlandValues = (values, formData) => {
  const farmlandAttributes = {
    open_cultivated_land_in_hectare: values?.openCultivatedLandInHectare,
    agricultural_land_in_hectare: values?.agriculturalLandInHectare,
    greenland_in_hectare: values?.greenlandInHectare,
    dgve: values?.dgve,
  };

  const preparePlantAttributes = attributes => {
    removeEmptyFromObject(attributes);

    Object.keys(attributes).forEach(attribute =>
      formData.append(`production_details[${attribute}]`, attributes[attribute]),
    );
  };

  preparePlantAttributes(farmlandAttributes);

  if (values.productionStartingYear) {
    formData.append('production_starting_year', values.productionStartingYear);
  }

  if (values.cultivationStatus) {
    formData.append('previous_cultivation', values.cultivationStatus);
  }

  if (values.processings) {
    const validProcessingValues = values.processings.filter(data => !!data);
    validProcessingValues.map(data => formData.append('production_details[processings][]', data));
  }
};
