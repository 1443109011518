import routes from 'utils/routes';
import { CONTRACT } from 'utils/tag-types';
import { apiSlice } from '../index';

const ContractApi = apiSlice.injectEndpoints({
  endpoints: build => ({
    getContract: build.query({
      query: organisationId => routes.contract.entity({ organisationId }),
      providesTags: [CONTRACT],
    }),
    uploadContract: build.mutation({
      query: ({ organisationId, payload }) => ({
        url: routes.contract.upload({ organisationId }),
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: [CONTRACT],
    }),
    deleteAttachment: build.mutation({
      query: ({ contractId }) => ({
        url: routes.contract.delete({ contractId }),
        method: 'DELETE',
      }),
      invalidatesTags: [CONTRACT],
    }),
    updateContract: build.mutation({
      query: ({ contractId, payload }) => ({
        url: routes.contract.update({ contractId }),
        method: 'PATCH',
        body: payload,
      }),
      invalidatesTags: [CONTRACT],
    }),
    approveContract: build.mutation({
      query: ({ organisationId }) => ({
        url: routes.contract.approve({ organisationId }),
        method: 'PUT',
      }),
      invalidatesTags: [CONTRACT],
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetContractQuery,
  useUploadContractMutation,
  useUpdateContractMutation,
  useApproveContractMutation,
  useDeleteAttachmentMutation,
} = ContractApi;
