import PropTypes from 'prop-types';
import CheckBox from 'components/base-components/CheckBox/CheckBox';
import Input from 'components/base-components/Input';
import React from 'react';
import { t } from 'i18next';
import { Field } from 'react-final-form';
import classNames from 'classnames';
import Card from 'components/base-components/Card';
import TitleComponent from '../shared/title.component';

const VegetablesForm = ({ isVisible, vegetableData, values }) => {
  if (!isVisible) return;
  return (
    <Card className="plant-cultivation__card">
      <TitleComponent title={t('plant_cultivation.vegetables.title')} icon="vegetable" />
      <div
        className={classNames('plant-cultivation__method--open', {
          'plant-cultivation__method--open__checked': values['vegetables__open_checkbox'],
        })}
      >
        <Field
          name="vegetables__open_checkbox"
          type="checkbox"
          initialValue={vegetableData?.openFieldInHectares}
        >
          {({ input }) => (
            <CheckBox
              isChecked={input.checked}
              size="tiny"
              label={t('plant_cultivation.vegetables.open_field')}
              onChange={e => input.onChange(e.target.checked)}
            />
          )}
        </Field>
        <Field
          name="vegetables__open_field_in_hectares"
          initialValue={vegetableData?.openFieldInHectares}
        >
          {({ input, meta }) => (
            <Input
              size="tiny"
              label={t('plant_cultivation.shared.surface_area_input_label')}
              placeholder={t('plant_cultivation.shared.surface_area_input_placeholder')}
              disabled={!values.vegetables__open_checkbox}
              {...input}
            />
          )}
        </Field>
      </div>
      <div
        className={classNames('plant-cultivation__method--open', {
          'plant-cultivation__method--open__checked': values['vegetables__covered_checkbox'],
        })}
      >
        <Field
          name="vegetables__covered_checkbox"
          type="checkbox"
          initialValue={vegetableData?.coveredSolidLandInHectares}
        >
          {({ input }) => (
            <CheckBox
              className="plant-cultivation__method--open__vegetable"
              isChecked={input.checked}
              size="tiny"
              label={t('plant_cultivation.vegetables.covered_cultivation_without_foundation')}
              onChange={e => input.onChange(e.target.checked)}
            />
          )}
        </Field>
        <Field
          name="vegetables__covered_solid_land_in_hectares"
          initialValue={vegetableData?.coveredSolidLandInHectares}
        >
          {({ input, meta }) => (
            <Input
              size="tiny"
              label={t('plant_cultivation.mushroom.surface_area_ares')}
              placeholder={t('plant_cultivation.shared.surface_area_input_placeholder')}
              disabled={!values.vegetables__covered_checkbox}
              {...input}
            />
          )}
        </Field>
      </div>
      <div
        className={classNames('plant-cultivation__method--open', {
          'plant-cultivation__method--open__checked':
            values['vegetables__covered_without_solid_land_checkbox'],
        })}
      >
        <Field
          name="vegetables__covered_without_solid_land_checkbox"
          type="checkbox"
          initialValue={vegetableData?.coveredCultivationWithoutSolidLandInHectares}
        >
          {({ input }) => (
            <CheckBox
              className="plant-cultivation__method--open__vegetable"
              isChecked={input.checked}
              size="tiny"
              label={t('plant_cultivation.vegetables.covered_cultivation_with_foundation')}
              onChange={e => input.onChange(e.target.checked)}
            />
          )}
        </Field>
        <Field
          name="vegetables__covered_cultivation_without_solid_land_in_hectares"
          initialValue={vegetableData?.coveredCultivationWithoutSolidLandInHectares}
        >
          {({ input, meta }) => (
            <Input
              size="tiny"
              label={t('plant_cultivation.mushroom.surface_area_ares')}
              placeholder={t('plant_cultivation.shared.surface_area_input_placeholder')}
              disabled={!values.vegetables__covered_without_solid_land_checkbox}
              {...input}
            />
          )}
        </Field>
      </div>
    </Card>
  );
};

VegetablesForm.defaultProps = {
  isVisible: false,
  vegetableData: {},
  values: {},
};

VegetablesForm.propTypes = {
  isVisible: PropTypes.bool,
  vegetableData: PropTypes.shape(),
  values: PropTypes.shape(),
};

export default VegetablesForm;
