import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/base-components/Icon';
import classNames from 'classnames';
import { useGetChartaOverviewQuery } from 'api/chartas';
import { useGetControlBodyDeclarationsOverviewQuery } from 'api/control-body-declarations';
import { useGetSupplierCertificatesOverviewQuery } from 'api/supplier-certificates';
import { useGetEducationsOverviewQuery } from 'api/educations';
import { useGetProductDeclarationsOverviewQuery } from 'api/product-declarations';
import { useGetActiveGoodsOverviewQuery } from 'api/goods-declarations';
import { Field } from 'react-final-form';
import Textarea from 'components/base-components/Textarea/Textarea';
import { t } from 'i18next';
import { boolToYesNo } from 'utils/yes-no-helper';
import YesNoInput from 'components/base-components/YesNoInput';
import { TEMPORARY } from 'utils/control-body-survey';
import { ARCHIVED } from 'utils/verification-status';

const ConditionalStatus = ({
  text,
  organizationId,
  surveyDraft,
  showLine,
  isCertifier,
  status,
}) => {
  const { data: chartaOverview = {} } = useGetChartaOverviewQuery({
    organizationId,
  });

  const { data: controlBodyOverview = {} } = useGetControlBodyDeclarationsOverviewQuery({
    organizationId,
  });

  const { data: certificatesOverview = {} } = useGetSupplierCertificatesOverviewQuery({
    organizationId,
  });

  const { data: educationsOverview = {} } = useGetEducationsOverviewQuery({
    organizationId,
  });

  const { data: productDeclarationOverview = {} } = useGetProductDeclarationsOverviewQuery({
    organizationId,
  });

  const { data: activeGoodsOverview = {} } = useGetActiveGoodsOverviewQuery({
    organizationId,
  });
  const statuses = [
    {
      label: t('control_body.survey.shared.contract_status_header.charta'),
      value: chartaOverview?.status,
      name: 'charta',
    },
    {
      label: t('control_body.survey.shared.contract_status_header.education'),
      value: educationsOverview?.status,
      name: 'education',
    },
    {
      label: t('control_body.survey.shared.contract_status_header.control_body'),
      value: controlBodyOverview?.status,
      name: 'control_body',
    },
    {
      label: t('control_body.survey.shared.contract_status_header.supplier'),
      value: certificatesOverview?.status,
      name: 'supplier',
    },
    {
      label: t('control_body.survey.shared.contract_status_header.products'),
      value: productDeclarationOverview?.status,
      name: 'products',
    },
    {
      label: t('control_body.survey.shared.contract_status_header.goods'),
      value: activeGoodsOverview?.status,
      name: 'goods',
    },
  ];

  const isNotAllApproved = statuses.some(
    status => status.value !== 'active' && status.value !== 'approved',
  );

  return (
    <>
      <div className="organisation-checklist__condition">{text}</div>
      {statuses.map(status => (
        <>
          <ul className="organisation-checklist__condition-list">
            <li>
              <div className="organisation-checklist__condition-wrapper">
                <div className="organisation-checklist__condition-text">{status.label}</div>
                <Icon
                  name="check"
                  size="tiny"
                  showBGColor={true}
                  className={classNames('status__step-icon', {
                    'status__step-icon--disabled':
                      status.value === null || status.value === ARCHIVED,
                    'status__step-icon--submitted':
                      status.value === 'submitted' ||
                      status.value === 'draft' ||
                      status.value === 'complained' ||
                      status.value === 'new_declaration',
                    'status__step-icon--success':
                      status.value === 'approved' || status.value === 'active',
                    'status__step-icon--expired': status.value === 'expired',
                    [status.name]:
                      status.value === 'approved' ||
                      status.value === 'active' ||
                      status.value === 'expired',
                  })}
                />
              </div>
            </li>
          </ul>
          {showLine && <hr className="organisation-checklist__checkbox-horizontal-line" />}
        </>
      ))}

      <div className="organisation-checklist__auditor-acknowledgement">
        <Field
          name="contract_acknowledgement_status"
          initialValue={boolToYesNo(surveyDraft?.contractAcknowledgementStatus)}
        >
          {({ input, meta }) => (
            <YesNoInput
              {...input}
              error={meta.submitError}
              label={t('control_body.survey.form.control_pass')}
              disabled={isCertifier || status === TEMPORARY}
            />
          )}
        </Field>
      </div>

      {isNotAllApproved && (
        <Field
          name="contractual_status_comment"
          initialValue={surveyDraft?.contractualStatusComment || ''}
        >
          {({ input }) => (
            <Textarea
              className="organisation-checklist__quarter-comment"
              label={t('control_body.survey.form.comments')}
              placeholder={t('control_body.survey.form.comments_placeholder')}
              disabled={isCertifier}
              {...input}
            />
          )}
        </Field>
      )}
    </>
  );
};

ConditionalStatus.defaultProps = {
  text: null,
  status: null,
  showLine: false,
  isCertifier: false,
  statuses: [],
  surveyDraft: {},
};

ConditionalStatus.propTypes = {
  text: PropTypes.string,
  status: PropTypes.string,
  showLine: PropTypes.bool,
  isCertifier: PropTypes.bool,
  statuses: PropTypes.arrayOf(PropTypes.string),
  organizationId: PropTypes.number.isRequired,
  surveyDraft: PropTypes.shape(),
};

export default ConditionalStatus;
