import { Text } from '@react-pdf/renderer';
import { Break, ListItem, Section } from 'components/pdf/shared/shared-components';
import { t } from 'i18next';
import React from 'react';
import SignatureForm from './signature-form.view';

const Content = () => (
  <>
    <Section title={t('producer_contract.point_one_title')}>
      <Text>{t('producer_contract.point_one_description')}</Text>
    </Section>

    <Section title={t('producer_contract.point_two_title')}>
      <Text>
        <Text>{t('producer_contract.point_two_description')}</Text>
      </Text>
    </Section>

    <Section title={t('producer_contract.point_three_title')}>
      <Text>{t('producer_contract.point_three_description')}</Text>
      <ListItem prefix="a)">{t('producer_contract.point_three_list.a')}</ListItem>
      <ListItem prefix="b)">{t('producer_contract.point_three_list.b')}</ListItem>
      <ListItem prefix="c)">{t('producer_contract.point_three_list.c')}</ListItem>
      <ListItem prefix="d)">{t('producer_contract.point_three_list.d')}</ListItem>
      <ListItem prefix="e)">{t('producer_contract.point_three_list.e')}</ListItem>
      <ListItem prefix="f)">{t('producer_contract.point_three_list.f')}</ListItem>
      <ListItem prefix="g)">{t('producer_contract.point_three_list.g')}</ListItem>
    </Section>

    <Section title={t('producer_contract.point_four_title')}>
      <Text>{t('producer_contract.point_four_description')}</Text>
    </Section>

    <Section title={t('producer_contract.point_five_title')}>
      <Text>{t('producer_contract.point_five_description')}</Text>
      <ListItem prefix="1.">{t('producer_contract.point_five_list.a')}</ListItem>
      <ListItem prefix="2.">
        {t('producer_contract.point_five_list.b.before_break')}
        <Break />
        {t('producer_contract.point_five_list.b.after_break')}
      </ListItem>
    </Section>

    <Section title={t('producer_contract.point_six_title')}>
      <Text>
        {t('producer_contract.point_six_description.before_break')}
        <Break count={2} />
        {t('producer_contract.point_six_description.after_break')}
      </Text>
    </Section>

    <Section title={t('producer_contract.point_seven_title')}>
      <Text>{t('producer_contract.point_seven_description')}</Text>
    </Section>

    <SignatureForm />
  </>
);

export default Content;
