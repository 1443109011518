import { useGetGardeningItemQuery, useGetGardeningOptionsQuery } from 'api/producers/productions';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import React from 'react';
import { toSnakecaseKeys } from 'utils/object-cleanup';
import SharedProductCultivationComponent from '../shared/shared-product-cultivation.component';
import './styles.scss';

const GardeningComponent = ({ productionId }) => {
  const { data: gardeningOptions = [] } = useGetGardeningOptionsQuery();
  const { data: gardeningItem } = useGetGardeningItemQuery(
    { productionId },
    { skip: !productionId },
  );

  const activeItem = toSnakecaseKeys(gardeningItem?.productionDetails || {});

  return (
    <SharedProductCultivationComponent
      isCheckboxComponent={true}
      data={gardeningOptions}
      activeItem={activeItem}
      fieldName="gardening_items"
      title={t('gardening.title')}
      icon="garden"
    />
  );
};

GardeningComponent.defaultProps = {
  productionId: null,
};

GardeningComponent.propTypes = {
  productionId: PropTypes.number,
};

export default GardeningComponent;
