import React from 'react';
import { useGetUserQuery, useUpdateUserPasswordMutation } from 'api/users';
import Button from 'components/base-components/Button';
import Input from 'components/base-components/Input';
import SelectBox from 'components/base-components/SelectBox';
import { useLogOutMutation } from 'api/session';
import SubmitModal from 'components/shared-components/modal/submit-modal';
import useCustomNavigate from 'hooks/useCustomNavigate';
import { Field, Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { toSnakecaseKeys } from 'utils/object-cleanup';
import '../users.styles.scss';

const CredentialForm = () => {
  const [updateUserPassword, { isSuccess, isError, requestId }] = useUpdateUserPasswordMutation();
  const { userId } = useParams();
  const { data: userData } = useGetUserQuery({ id: userId });
  const [userLogOut] = useLogOutMutation();
  const emailOptions = [
    {
      label: userData?.email,
      value: userData?.email,
    },
  ];

  const { t } = useTranslation();
  const navigate = useCustomNavigate();

  const logoutCurrentUser = () => {
    userLogOut()
      .unwrap()
      .then(() => navigate.openLoginPage());
  };

  const onSubmit = (values, form) => {
    return updateUserPassword(values)
      .unwrap()
      .then(() => form.reset())
      .catch(({ data: { errors, errorMsg } }) =>
        errorMsg ? { base: errorMsg } : toSnakecaseKeys(errors),
      );
  };

  return (
    <>
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit, submitErrors, submitting }) => (
          <form onSubmit={handleSubmit}>
            <div className="user__credential-form">
              <div className="col-6 col-bleed user__credential-email">
                <Field name="email" initialValue={userData?.email}>
                  {({ input, meta }) => (
                    <SelectBox
                      size="small"
                      label={t('user_credential.email.label')}
                      placeholderText={t('user_credential.email.placeholder')}
                      isClearable={false}
                      options={emailOptions}
                      value={emailOptions.find(option => option.value === input.value)}
                      selectedValue={input.label}
                      onChange={e => input.onChange(e.value)}
                      touched={!meta.dirtySinceLastSubmit}
                      errorMsg={meta?.submitError}
                    />
                  )}
                </Field>
              </div>
              <div className="col-12 col-bleed user__credential-email-explanation">
                {t('user_credential.login_email_explanation')}
              </div>
              <div className="col-6 col-bleed user__credential-oldpassword">
                <Field name="old_password" initialValue="">
                  {({ input, meta }) => (
                    <Input
                      label={t('user_credential.old_password.label')}
                      type="password"
                      placeholder={t('user_credential.old_password.placeholder')}
                      {...input}
                      touched={!meta.dirtySinceLastSubmit}
                      error={meta?.submitError}
                    />
                  )}
                </Field>
              </div>
              <div className="col-grid col-12 col-bleed direction-row">
                <div className="col-6 col-bleed">
                  <Field name="password" initialValue="">
                    {({ input, meta }) => (
                      <Input
                        label={t('user_credential.new_password.label')}
                        type="password"
                        placeholder={t('user_credential.new_password.placeholder')}
                        {...input}
                        touched={!meta.dirtySinceLastSubmit}
                        error={meta?.submitError}
                      />
                    )}
                  </Field>
                </div>
                <div className="col-6 col-bleed user__credential-form--confirm-password">
                  <Field name="password_confirmation" initialValue="">
                    {({ input, meta }) => (
                      <Input
                        label={t('user_credential.confirm_new_password.label')}
                        type="password"
                        placeholder={t('user_credential.confirm_new_password.placeholder')}
                        {...input}
                        touched={!meta.dirtySinceLastSubmit}
                        error={meta?.submitError}
                      />
                    )}
                  </Field>
                </div>
              </div>
              <div className="col-12 col-bleed user__credential--footer">
                <Button
                  label={t('user_credential.update_button')}
                  type="success"
                  size="small"
                  submitType="submit"
                  disabled={submitting}
                />
              </div>
            </div>

            <SubmitModal
              isSuccess={isSuccess}
              isError={isError}
              requestId={requestId}
              successTitle={t('user_credential.update_successfull')}
              successContent={t('user_credential.update_successfull_text')}
              errorTitle={t('user_credential.update_failed')}
              errorContent={submitErrors?.base}
              onSuccess={logoutCurrentUser}
              showLoader={false}
              showError={!!submitErrors?.base}
            />
          </form>
        )}
      />
    </>
  );
};

export default CredentialForm;
