import Card from 'components/base-components/Card';
import { Title } from 'components/base-components/Typography';
import EmptyContentPlaceholder from 'components/shared-components/placeholders/empty-content-placeholder-component';
import { t } from 'i18next';
import React from 'react';

const LatestUpdateComponent = () => {
  return (
    <div className="dashboard__container-item">
      <Card className="dashboard__messages-wrapper">
        <Title className="dashboard__latest-title">{t('dashboard.latest_update')}</Title>
        {/* //TODO: Will be added Later */}
        {/* <div className="col-12  col-bleed-x">
          <Card>
            <div> New Sign Up has been made by Barcode Restaurants LLC </div>
            <div>26 Nov 2022</div>
          </Card>
        </div>
        <div className="col-12  col-bleed-x">
          <Card>
            <div> New Sign Up has been made by Barcode Restaurants LLC </div>
            <div>26 Nov 2022</div>
          </Card>
        </div>
        <div className="col-12 col-bleed-x">
          <Card>
            <div> New Sign Up has been made by Barcode Restaurants LLC </div>
            <div>26 Nov 2022</div>
          </Card>
        </div> */}
        <EmptyContentPlaceholder
          text={t('common.coming_soon')}
          iconName="newspaper"
          showCard={false}
        />
      </Card>
    </div>
  );
};

export default LatestUpdateComponent;
