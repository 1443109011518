export const greenAreaValues = (values, formData) => {
  const greenAreaAttributes = {
    publicly_accessible_green_area: values?.publicly_accessible_green_area_in_hectares,
    urban_gardening: values?.urban_gardening_in_hectares,
  };

  Object.keys(greenAreaAttributes).forEach(area => {
    if (values[area]) {
      formData.append('production_details[recreational_and_green_area][][sub_type]', area);
      formData.append(
        'production_details[recreational_and_green_area][][areas_in_hectare]',
        greenAreaAttributes[area] || 0,
      );
    }
  });
};
