import Input from 'components/base-components/Input';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import React, { useEffect } from 'react';
import { Field } from 'react-final-form';
import { useGetUserQuery } from 'api/users';
import SelectBox from 'components/base-components/SelectBox';
import Card from 'components/base-components/Card';

const ContractPartnerList = ({ partner, name, userId, status, validationError }) => {
  const { data: user = {}, refetch } = useGetUserQuery({ id: userId }, { skip: !userId });

  const salutationOptions = [
    {
      label: t('user_salutation.mister'),
      value: 'mr',
    },
    {
      label: t('user_salutation.miss'),
      value: 'ms',
    },
  ];

  useEffect(() => {
    refetch();
  }, []);

  return (
    <Card className="legal-form__card" data-testid="legal-form-partner">
      <div className="legal-form__card--header">
        {status === 'other'
          ? t('shared.other')
          : t('legal_form.input.header', {
              partner: partner + 1,
            })}
      </div>
      <Field name={`${name}.userId`} initialValue={user?.id} type="hidden" component="input" />
      <div className="col-2 col-bleed">
        <Field name={`${name}.salutation`} initialValue={user?.salutation}>
          {({ input, meta }) => (
            <SelectBox
              size="tiny"
              width="large"
              label={t('legal_form.input.salutation')}
              isClearable={false}
              options={salutationOptions}
              value={salutationOptions.find(option => option.value === input.value)}
              onChange={e => input.onChange(e.value)}
              errorMsg={meta.submitError}
            />
          )}
        </Field>
      </div>
      <div className="col-5 col-bleed-y legal-form__card--firstname">
        <Field name={`${name}.firstName`} initialValue={user?.firstName}>
          {({ input, meta }) => (
            <Input
              {...input}
              size="tiny"
              label={t('legal_form.input.firstname')}
              placeholder={t('user_invite.firstname.placeholder')}
              touched={!meta.dirtySinceLastSubmit}
              error={meta.submitError || (!input.value && validationError?.assignments)}
            />
          )}
        </Field>
      </div>
      <div className="col-5 col-bleed">
        <Field name={`${name}.lastName`} initialValue={user?.lastName}>
          {({ input, meta }) => (
            <Input
              {...input}
              size="tiny"
              label={t('legal_form.input.lastname')}
              placeholder={t('user_invite.lastname.placeholder')}
              touched={!meta.dirtySinceLastSubmit}
              error={meta.submitError || (!input.value && validationError?.assignments)}
            />
          )}
        </Field>
      </div>
      <div className="col-6 col-bleed legal-form__email">
        <Field name={`${name}.email`} initialValue={user?.email}>
          {({ input, meta }) => (
            <Input
              {...input}
              size="tiny"
              label={t('legal_form.input.email')}
              placeholder={t('user_contact.email_address.placeholder')}
              touched={!meta.dirtySinceLastSubmit}
              error={meta.submitError || (!input.value && validationError?.assignments)}
            />
          )}
        </Field>
      </div>
    </Card>
  );
};

ContractPartnerList.defaultProps = {
  partner: null,
  name: null,
  userId: null,
  status: null,
  validationError: {},
};

ContractPartnerList.propTypes = {
  partner: PropTypes.number,
  name: PropTypes.string,
  userId: PropTypes.number,
  status: PropTypes.string,
  validationError: PropTypes.shape(),
};

export default ContractPartnerList;
