import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

function SideNavLink(props) {
  const { icon, label, isPrivate, isLoggedIn, activeLink, href } = props;
  const shouldShow = isPrivate ? isLoggedIn : true;

  return shouldShow ? (
    <Link to={href}>
      <li>
        <div
          className={classNames('side-nav__list-item', {
            'side-nav__list-item--active': activeLink,
          })}
        >
          <div className="side-nav__list-item-icon">{icon}</div>
          <div className="side-nav__list-item-label">{label}</div>
        </div>
      </li>
    </Link>
  ) : null;
}

SideNavLink.defaultProps = {
  isPrivate: false,
  label: '',
  notificationsCount: 0,
  isLoggedIn: false,
  activeLink: false,
  href: null,
};

SideNavLink.propTypes = {
  icon: PropTypes.node.isRequired,
  isPrivate: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  notificationsCount: PropTypes.number,
  isLoggedIn: PropTypes.bool,
  activeLink: PropTypes.bool,
  href: PropTypes.string,
};

export default SideNavLink;
