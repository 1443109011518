import Card from 'components/base-components/Card';
import PropTypes from 'prop-types';
import React from 'react';
import Legend from 'components/base-components/Legend/Legend';
import Icon from 'components/base-components/Icon';
import YesNoInput from 'components/base-components/YesNoInput';
import { boolToYesNo } from 'utils/yes-no-helper';
import { Trans, useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Field } from 'react-final-form';
import Textarea from 'components/base-components/Textarea/Textarea';
import { TEMPORARY } from 'utils/control-body-survey';
import numberFormatter from 'utils/number-formatter';
import { monthYearShort } from 'utils/date-time-formatter';
import { useGetDeclarationStatusesQuery } from 'api/control-body-survey';

const LegendWrapper = ({
  deviationAcknowledgement,
  deadlinesMissedComment,
  monthlyVariationComment,
  valueOfGoodsComment,
  isCertifier,
  surveyStatus,
  errorMessage,
  surveyId,
}) => {
  const { t } = useTranslation();

  const { data: deviationDetails } = useGetDeclarationStatusesQuery(
    { surveyId },
    { skip: !surveyId },
  );

  const monthlyDeviations = deviationDetails?.goodsDeclarationsDeviationsPercentage;

  return (
    <>
      <div className="organisation-checklist__deviation">
        {' '}
        <>
          <span className="organisation-checklist__number-bold">4 .</span>{' '}
          <span> {t('control_body.survey.form.legend.header')} </span>
        </>
      </div>
      <Card className="col-bleed-y organisation-checklist__deviation-card--legend">
        <div className="organisation-checklist__deviation__wrapper">
          <div className="organisation-checklist__deviation-scale">
            <div>{t('control_body.survey.form.legend.variation')}</div>
            <div className="organisation-checklist__deviation-scale--header">
              {t('control_body.survey.form.legend.deviation_scale')}
            </div>
          </div>
          <div className="organisation-checklist__deviation-legend--wrapper">
            <div>
              <span
                className={classNames(
                  'organisation-checklist__deviation-legend-monthly-variation',
                  {
                    'organisation-checklist__deviation-legend--status__good':
                      monthlyDeviations?.status === 'good',
                    'organisation-checklist__deviation-legend--status__average':
                      monthlyDeviations?.status === 'average',
                    'organisation-checklist__deviation-legend--status__bad':
                      monthlyDeviations?.status === 'bad',
                  },
                )}
              >
                {monthlyDeviations?.value !== null ? (
                  <>
                    {monthlyDeviations?.value}%{' '}
                    <span className="organisation-checklist__deviation-legend--status-disabled">
                      {t('control_body.survey.form.legend.deviation')}
                    </span>
                    <span className="organisation-checklist__deviation-legend--status-disabled">
                      {monthlyDeviations?.bioKnopseMinValue?.amount} (
                      {monthYearShort(monthlyDeviations?.bioKnopseMinValue?.month)})
                    </span>
                    <span className="organisation-checklist__deviation-legend--status-disabled">
                      {monthlyDeviations?.bioKnopseMaxValue?.amount} (
                      {monthYearShort(monthlyDeviations?.bioKnopseMaxValue?.month)})
                    </span>
                  </>
                ) : (
                  <span className="organisation-checklist__deviation-legend--status-disabled">
                    {' '}
                    {t('common.no_data_available')}{' '}
                  </span>
                )}
              </span>{' '}
            </div>
            <div className="organisation-checklist__deviation-legend">
              <Legend
                className="organisation-checklist__deviation-legend--individual"
                label="+/- 10%"
              />
              <Legend
                className="organisation-checklist__deviation-legend--individual"
                label="(+/- 10%) - (+/- 20%)"
                type="warning"
              />
              <Legend
                className="organisation-checklist__deviation-legend--individual"
                label="> +/- 20%"
                type="danger"
              />
            </div>
          </div>
          <Field name="monthlyVariationComment" initialValue={monthlyVariationComment}>
            {({ input, meta }) => (
              <Textarea
                data-testid="monthlyVariationComment"
                className={'organisation-checklist__deviation-card-vat-percentage-comment'}
                label={t('control_body.survey.form.comments')}
                placeholder={t('control_body.survey.form.comments_placeholder')}
                disabled={isCertifier}
                {...input}
              />
            )}
          </Field>
          <hr className="organisation-checklist__checkbox-horizontal-line" />
        </div>
        <div className="organisation-checklist__deviation__wrapper">
          <div className="organisation-checklist__deviation-scale">
            <div>{t('control_body.survey.form.legend.missed_deadline')}</div>
            <div className="organisation-checklist__deviation-scale--header">
              {t('control_body.survey.form.legend.deviation_scale')}
            </div>
          </div>
          <div className="organisation-checklist__deviation-legend--wrapper">
            <div
              className={classNames({
                'organisation-checklist__deviation-legend--deadline__good':
                  deviationDetails?.deadlinesMissed?.status === 'good',
                'organisation-checklist__deviation-legend--deadline__average':
                  deviationDetails?.deadlinesMissed?.status === 'average',
                'organisation-checklist__deviation-legend--deadline__bad':
                  deviationDetails?.deadlinesMissed?.status === 'bad',
              })}
            >
              {deviationDetails?.deadlinesMissed?.value !== null ? (
                <span>
                  {deviationDetails?.deadlinesMissed?.value !== null
                    ? deviationDetails?.deadlinesMissed?.value
                    : t('common.not_applicable')}{' '}
                  <span className="organisation-checklist__deviation-legend--deadline__disabled">
                    {t('control_body.survey.form.pdf_list.days_within')}{' '}
                  </span>{' '}
                </span>
              ) : (
                <span className="organisation-checklist__deviation-legend--status-disabled">
                  {' '}
                  {t('common.no_data_available')}{' '}
                </span>
              )}
            </div>
            <div className="organisation-checklist__deviation-legend">
              <Legend
                className="organisation-checklist__deviation-legend--individual"
                label={t('control_body.survey.form.legend.less_than_twenty')}
              />
              <Legend
                className="organisation-checklist__deviation-legend--individual"
                label={t('control_body.survey.form.legend.twenty_to_forty')}
                type="warning"
              />
              <Legend
                className="organisation-checklist__deviation-legend--individual"
                label={t('control_body.survey.form.legend.greater_than_forty')}
                type="danger"
              />
            </div>
          </div>
          <Field name="deadlinesMissedComment" initialValue={deadlinesMissedComment}>
            {({ input, meta }) => (
              <Textarea
                data-testid="deadlinesMissedComment"
                className={'organisation-checklist__deviation-card-vat-percentage-comment'}
                label={t('control_body.survey.form.comments')}
                placeholder={t('control_body.survey.form.comments_placeholder')}
                disabled={isCertifier}
                touched={meta.touched}
                error={errorMessage?.goodsDeclarationEvaluationCommentToChangeVatPercentage}
                {...input}
              />
            )}
          </Field>
          <hr className="organisation-checklist__checkbox-horizontal-line" />
        </div>
        <div className="organisation-checklist__deviation-scale--wrapper">
          <div className="organisation-checklist__deviation-scale">
            <div>{t('control_body.survey.form.legend.declaration_supplier_relation')}</div>
            <div className="organisation-checklist__deviation-scale--header">
              {t('control_body.survey.form.legend.deviation_scale')}
            </div>
          </div>
          <div className="organisation-checklist__deviation-legend--wrapper">
            {deviationDetails?.certificatesDeclarationsRatio?.value !== null ? (
              <span className="organisation-checklist__deviation-legend--status-disabled">
                <Trans
                  i18nKey={'control_body.survey.form.pdf_list.goods_declaration_comparision'}
                  values={{
                    amount: numberFormatter(deviationDetails?.certificatesDeclarationsRatio?.value),
                  }}
                  components={{
                    div: (
                      <span
                        className={classNames({
                          'organisation-checklist__deviation-legend--certificate__good':
                            deviationDetails?.certificatesDeclarationsRatio?.status === 'good',
                          'organisation-checklist__deviation-legend--certificate__average':
                            deviationDetails?.certificatesDeclarationsRatio?.status === 'average',
                          'organisation-checklist__deviation-legend--certificate__bad':
                            deviationDetails?.certificatesDeclarationsRatio?.status === 'bad',
                        })}
                      />
                    ),
                  }}
                />
                {' | '}
                <span className="organisation-checklist__deviation-legend--status-disabled-bold">
                  {deviationDetails?.certificatesDeclarationsRatio?.bioAmountMinValue}{' '}
                </span>
                ({t('control_body.survey.shared.min')}){' | '}
                <span className="organisation-checklist__deviation-legend--status-disabled-bold">
                  {deviationDetails?.certificatesDeclarationsRatio?.bioAmountMaxValue}{' '}
                </span>
                ({t('control_body.survey.shared.max')})
              </span>
            ) : (
              <span className="organisation-checklist__deviation-legend--status-disabled">
                {t('common.no_data_available')}
              </span>
            )}
            <div className="organisation-checklist__deviation-legend">
              <Legend
                label="< 10%"
                className="organisation-checklist__deviation-legend--individual"
              />
              <Legend
                label="10% - 20%"
                type="warning"
                className="organisation-checklist__deviation-legend--individual"
              />
              <Legend
                label="> 20%"
                type="danger"
                className="organisation-checklist__deviation-legend--individual"
              />
            </div>
          </div>
        </div>
        <Field name="valueOfGoodsComment" initialValue={valueOfGoodsComment}>
          {({ input, meta }) => (
            <Textarea
              data-testid="valueOfGoodsComment"
              className={'organisation-checklist__deviation-card-vat-percentage-comment'}
              label={t('control_body.survey.form.comments')}
              placeholder={t('control_body.survey.form.comments_placeholder')}
              disabled={isCertifier}
              touched={meta.touched}
              error={errorMessage?.goodsDeclarationEvaluationCommentToChangeVatPercentage}
              {...input}
            />
          )}
        </Field>
        <hr className="organisation-checklist__checkbox-horizontal-line" />
        <div className="organisation-checklist__deviation__wrapper">
          <Field
            name="deviationAcknowledgement"
            initialValue={boolToYesNo(deviationAcknowledgement)}
          >
            {({ input, meta }) => (
              <YesNoInput
                {...input}
                error={meta.submitError}
                label={t('control_body.survey.form.control_pass')}
                disabled={isCertifier || surveyStatus === TEMPORARY}
              />
            )}
          </Field>
        </div>
      </Card>
      {errorMessage && (
        <div className="organisation-checklist__errors">
          <Icon name="invalid" color="danger" size="small" />
          <span className="organisation-checklist__errors-text"> {errorMessage} </span>
        </div>
      )}
    </>
  );
};

LegendWrapper.defaultProps = {
  deviationDetails: {},
  surveyStatus: null,
  surveyId: null,
  isCertifier: false,
  errorMessage: null,
  monthlyVariationComment: '',
  deadlinesMissedComment: '',
  valueOfGoodsComment: '',
};

LegendWrapper.propTypes = {
  deviationDetails: PropTypes.shape(),
  surveyId: PropTypes.node,
  deviationAcknowledgement: PropTypes.bool.isRequired,
  surveyStatus: PropTypes.node,
  isCertifier: PropTypes.bool,
  errorMessage: PropTypes.string,
  monthlyVariationComment: PropTypes.string,
  deadlinesMissedComment: PropTypes.string,
  valueOfGoodsComment: PropTypes.string,
};

export default LegendWrapper;
