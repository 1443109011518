import Card from 'components/base-components/Card';
import Input from 'components/base-components/Input';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import React from 'react';
import { Field, useFormState } from 'react-final-form';

const SharedMilkFields = ({ numberOfAnimals, quantity }) => {
  const { submitErrors } = useFormState();

  return (
    <>
      <Card className="product-cultivation__inside-card">
        <Field name={numberOfAnimals.name} initialValue={numberOfAnimals.initialValue}>
          {({ input }) => (
            <Input
              size="tiny"
              className="product-cultivation__input"
              type="number"
              label={numberOfAnimals.label}
              placeholder={t('plant_cultivation.shared.surface_area_input_placeholder')}
              touched={true}
              error={submitErrors?.productionDataErrors?.numberOfAnimals}
              {...input}
            />
          )}
        </Field>
        <Field name={quantity.name} initialValue={quantity.initialValue}>
          {({ input }) => (
            <Input
              className="product-cultivation__input"
              size="tiny"
              type="number"
              label={quantity.label}
              placeholder={t('plant_cultivation.shared.surface_area_input_placeholder')}
              touched={true}
              error={!input.value && submitErrors?.productionDataErrors?.milkProducedKg}
              {...input}
            />
          )}
        </Field>
      </Card>
    </>
  );
};

SharedMilkFields.propTypes = {
  numberOfAnimals: PropTypes.shape().isRequired,
  quantity: PropTypes.shape().isRequired,
};

export default SharedMilkFields;
