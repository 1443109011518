import React from 'react';
import { Document, Page, Image, Text, View } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import BioLogo from 'assets/bio_logo_large.png';
import { useLanguageContext } from 'contexts/languageContext';
import { STATUS_VK } from 'utils/organization-certificate-status';
import Footer from 'components/pdf/recognition/footer.view';
import { Break, ListItem } from '../../shared/shared-components';
import coverLetterStyles from '../../styles/anerkennung/cover-letter';
import organizationMainAddress from '../helpers/organization-address';

const CoverLetterPDF = ({ organization, generationDate, status }) => {
  const { t } = useLanguageContext();
  return (
    <Document>
      <Page size="A4" style={coverLetterStyles.page}>
        <Image style={coverLetterStyles.headerLogo} src={BioLogo} />
        <View style={coverLetterStyles.body}>
          <Text>
            {organization.name}
            <Break />
            {organizationMainAddress(organization)}
            <Break count={2} />
            {t('pdf.anerkennung.basel')}, {generationDate}/{organization?.bioId}
            /FOF
            <Break count={2} />
          </Text>

          <Text style={coverLetterStyles.subject}>
            {t(`pdf.anerkennung.cover-letter.${status}.subject`)}
          </Text>

          <Text>{t('pdf.anerkennung.cover-letter.reference')}</Text>
          <Break count={2} />

          <Text style={coverLetterStyles.description}>
            {t(`pdf.anerkennung.cover-letter.${status}.description.section_1`)}
            {status === STATUS_VK && <Text style={coverLetterStyles.link}>www.easy-cert.ch</Text>}
            {t(`pdf.anerkennung.cover-letter.${status}.description.section_2`)}
            <Text style={coverLetterStyles.link}>
              {t(`pdf.anerkennung.cover-letter.${status}.link.address_1`)}
            </Text>
            {t(`pdf.anerkennung.cover-letter.${status}.description.section_3`)}
          </Text>

          <View style={coverLetterStyles.table}>
            <View style={coverLetterStyles.tableRow}>
              <View style={coverLetterStyles.tableCol1}>
                <ListItem bulletPoint={true}>
                  {t(`pdf.anerkennung.cover-letter.${status}.table.col_1.item_1`)}
                </ListItem>
              </View>
              <View style={coverLetterStyles.tableCol2}>
                <Text style={coverLetterStyles.cell}>
                  {t(`pdf.anerkennung.cover-letter.${status}.table.col_2.item_1`)}
                </Text>
              </View>
            </View>

            <View style={coverLetterStyles.tableRow}>
              <View style={coverLetterStyles.tableCol1}>
                <ListItem bulletPoint={true}>
                  {t(`pdf.anerkennung.cover-letter.${status}.table.col_1.item_2`)}
                </ListItem>
              </View>
              <View style={coverLetterStyles.tableCol2}>
                <Text style={coverLetterStyles.cell}>
                  {t(`pdf.anerkennung.cover-letter.${status}.table.col_2.item_2`)}
                </Text>
              </View>
            </View>

            <View style={coverLetterStyles.tableRow}>
              <View style={coverLetterStyles.tableCol1}>
                <ListItem bulletPoint={true}>
                  {t(`pdf.anerkennung.cover-letter.${status}.table.col_1.item_3`)}
                </ListItem>
              </View>
              <View style={coverLetterStyles.tableCol2}>
                <Text style={coverLetterStyles.cell}>
                  {t(`pdf.anerkennung.cover-letter.${status}.table.col_2.item_3`)}
                </Text>
              </View>
            </View>

            <View style={coverLetterStyles.tableRow}>
              <View style={coverLetterStyles.tableCol1}>
                <ListItem bulletPoint={true}>
                  {t(`pdf.anerkennung.cover-letter.${status}.table.col_1.item_4`)}
                </ListItem>
              </View>
              <View style={coverLetterStyles.tableCol2}>
                <Text style={coverLetterStyles.cell}>
                  {t(`pdf.anerkennung.cover-letter.${status}.table.col_2.item_4`)}
                  <Text style={coverLetterStyles.link}>
                    {t('pdf.anerkennung.cover-letter.web_1')}
                  </Text>
                </Text>
              </View>
            </View>

            <View style={coverLetterStyles.tableRow}>
              <View style={coverLetterStyles.tableCol1}>
                <ListItem bulletPoint={true}>
                  {t(`pdf.anerkennung.cover-letter.${status}.table.col_1.item_5`)}
                </ListItem>
              </View>
              <View style={coverLetterStyles.tableCol2}>
                <Text style={coverLetterStyles.cell}>
                  {t(`pdf.anerkennung.cover-letter.${status}.table.col_2.item_4`)}
                  <Text style={coverLetterStyles.link}>
                    {t('pdf.anerkennung.cover-letter.web_1')}
                  </Text>
                </Text>
              </View>
            </View>

            <View style={coverLetterStyles.tableRow}>
              <View style={coverLetterStyles.tableCol1}>
                <ListItem bulletPoint={true}>
                  {t(`pdf.anerkennung.cover-letter.${status}.table.col_1.item_6`)}
                </ListItem>
              </View>
              <View style={coverLetterStyles.tableCol2}>
                <Text style={coverLetterStyles.cell}>
                  <Text style={coverLetterStyles.link}>wwww.biomondo.ch</Text>
                  {t(`pdf.anerkennung.cover-letter.${status}.table.col_2.item_6`)}
                </Text>
              </View>
            </View>

            <View style={coverLetterStyles.lastTableRow}>
              <View style={coverLetterStyles.tableCol1}>
                <ListItem bulletPoint={true}>
                  {t(`pdf.anerkennung.cover-letter.${status}.table.col_1.item_7`)}
                </ListItem>
              </View>
              <View style={coverLetterStyles.tableCol2}>
                <Text style={coverLetterStyles.cell}>
                  <Text style={coverLetterStyles.link}>
                    {t('pdf.anerkennung.cover-letter.web_2')}
                  </Text>
                  {t(`pdf.anerkennung.cover-letter.${status}.table.col_2.item_7`)}
                </Text>
              </View>
            </View>
          </View>

          <Text>
            {t(`pdf.anerkennung.cover-letter.${status}.question`)}
            <Text style={coverLetterStyles.link}>
              {t(`pdf.anerkennung.cover-letter.${status}.link.address_2`)}
            </Text>
            <Break />
            {status === STATUS_VK && t(`pdf.anerkennung.cover-letter.${status}.best_wish`)}
            <Break count={2} />
            Freundliche Grüsse
            <Break />
            Bio Suisse
            <Break count={2} />
            Flurina Forsblom
            <Break />
            Data Scientist / IT
            <Break count={2} />
            {t(`pdf.anerkennung.cover-letter.${status}.from`)}
          </Text>
        </View>
        <Footer />
      </Page>
    </Document>
  );
};

CoverLetterPDF.propTypes = {
  organization: PropTypes.shape().isRequired,
  generationDate: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
};

export default CoverLetterPDF;
